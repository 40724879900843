import Http from '@services/httpClient';

const prefix = '/api/v1/documents';

export default {
  upload(body: object) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: prefix,
        data: body,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
