import { withTranslation } from 'react-i18next';
import { constant, util } from '@helper';
import _ from 'lodash';
import { CompanyAPI, JobApplicationAPI } from '@services/api';
import React from 'react';
import {
  Affix,
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Grid,
  IconButton,
  Panel,
  Placeholder,
  Row,
  Stack,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite';
import {
  FaHome,
  FaBuilding,
  FaSuitcase,
  FaFileAlt,
  FaUser,
  FaChevronLeft,
  FaStar,
  FaLink,
  FaClock,
  FaMapMarkerAlt,
  FaUsers,
} from 'react-icons/fa';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import PlaceholderParagraph from 'rsuite/esm/Placeholder/PlaceholderParagraph';
import utils from '@helper/utils';

const { withRouter } = util;

type State = {
  id: string | null;
  details: any;
  similarCompanies: Array<any>;

  loading: boolean;
};

class CandidateCompanyDetailsScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: null,
      details: null,
      loading: true,
      similarCompanies: [],
    };
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const { params } = this.props;
    const id = _.get(params, 'id');

    this.setState({ id }, () => this.getCompanyDetails());
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const prevId = _.get(prevProps, 'params.id');
    const id = _.get(this.props, 'params.id');

    if (prevId !== id) {
      this.setState({ id, loading: true }, () => {
        this.getCompanyDetails();
      });
    }
  }

  private getCompanyDetails = async (): Promise<void> => {
    try {
      const { id } = this.state;
      const res = await CompanyAPI.getCompanyDetails(String(id));
      const data = _.get(res, 'data');

      const industryId = Number(_.get(data, 'industry.id')) || 0;
      const similarCompanies = await CompanyAPI.companyListByIndustry(
        industryId,
        1,
        '',
      );

      this.setState({
        details: data,
        loading: false,
        similarCompanies: _.get(similarCompanies, 'data') || [],
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  private onSaveOrRemoveApplication = async (
    jobId: number,
    isSaved: boolean,
  ): Promise<void> => {
    try {
      let message;

      if (isSaved) {
        await JobApplicationAPI.remove(jobId);
        message = 'Job posting has been removed from your bookmark list!';
      } else {
        await JobApplicationAPI.save(jobId);
        message = 'Job posting has been added to your bookmark list!';
      }

      toaster.push(util.buildMessage(message));

      // Re-fetch details
      this.getCompanyDetails();
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              String(location.pathname).includes('companies')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/my-application',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderMainContent = (): JSX.Element | null => {
    const { details, loading, similarCompanies } = this.state;
    const { t } = this.props;

    const companyName = _.get(details, 'name');
    const companyId = _.get(this.state, 'id');
    const companyLogo = _.get(details, 'logo.url', '-');
    const companyHeader = _.get(details, 'background.url');
    const companyAddress = _.get(details, 'address');
    const workingTypes = _.get(details, 'workingTypes') || [];
    const benefits = _.get(details, 'benefits') || [];
    const cultures = _.get(details, 'cultures') || [];
    const companyIndustry = _.get(details, 'industry.name', '-');
    const companyDesciption = _.get(details, 'description') || '-';
    const jobPostings = _.get(details, 'openingJobs') || [];
    let companySize = `${_.get(details, 'employeeMinSize')} - ${_.get(
      details,
      'employeeMaxSize',
    )}`;
    if (_.get(details, 'employeeMinSize') > 10000) {
      companySize = '>10000';
    }
    let companyWebsite = _.get(details, 'website') || '';
    companyWebsite = utils.toLink(companyWebsite);

    return (
      <Col
        md={24}
        sm={24}
        xs={24}
        lg={18}
        className="company-details-main-content"
      >
        <Row>
          <Col xs={24}>
            <a href="/dashboard/candidate/search-companies">
              <FaChevronLeft />
              <span>Other Company</span>
            </a>
            <Divider style={{ backgroundColor: 'transparent' }} />
          </Col>
          <Col xs={24} sm={17}>
            <Panel bodyFill className="company-details-wrapper-card">
              {companyHeader ? (
                <img src={companyHeader} alt="card-header" width="100%" />
              ) : (
                util.companyBg(companyIndustry)
              )}
              {loading && (
                <Placeholder.Graph className="placeholder-logo" active />
              )}
              {!loading && <img src={companyLogo} alt="company-logo" />}
              <Panel className="company-details-card">
                <br />
                {!loading && (
                  <Stack spacing={8}>
                    <h3
                      className="company-details-title"
                      style={{
                        marginTop: '8px',
                      }}
                    >
                      {companyName}
                    </h3>
                    <img
                      src={`${process.env.PUBLIC_URL}/icon-verified.png`}
                      alt="logo-verified"
                      style={{ display: loading ? 'none' : '' }}
                    />
                  </Stack>
                )}
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderParagraph active />
                  </Stack>
                )}
                <br />
                <Stack spacing={8}>
                  <p className="company-details-title">Location</p>
                </Stack>
                {!loading && (
                  <Stack spacing={8}>
                    <p>{companyAddress}</p>
                  </Stack>
                )}
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}

                <br />
                <Stack spacing={8}>
                  <p className="company-details-title">Industry</p>
                </Stack>
                {!loading && (
                  <Stack spacing={8}>
                    <p>{companyIndustry}</p>
                  </Stack>
                )}
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}

                <br />
                <br />
                <Stack spacing={8}>
                  <p className="company-details-title">About the Company</p>
                </Stack>
                <Divider />
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}
                {!loading && (
                  <Stack spacing={8}>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: companyDesciption,
                      }}
                    />
                  </Stack>
                )}

                <br />
                <br />
                <Stack spacing={8}>
                  <p className="company-details-title">
                    The Culture of Our Company
                  </p>
                </Stack>
                <Divider />
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}
                <Row>
                  {_.map(cultures, (c: any) => (
                    <Col
                      key={c.name}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      className="cultures-icon"
                    >
                      <img
                        src={
                          _.get(c, 'logo.url') ||
                          'https://via.placeholder.com/150'
                        }
                        alt="company-culture-logo"
                      />
                      <b>{c.name}</b>
                      <p className="culture-text">
                        {_.get(c, 'description') || '-'}
                      </p>
                    </Col>
                  ))}
                </Row>
              </Panel>
            </Panel>
          </Col>

          <Col xs={24} sm={7}>
            <Panel className="company-details-side-card">
              <Stack
                spacing={8}
                direction="column"
                style={{ alignItems: 'flex-start' }}
              >
                <Row>
                  <Col xs={3}>
                    <FaUsers />
                  </Col>
                  <Col xs={21}>
                    <p className="company-details-attribute-title">
                      Company Size
                    </p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && <p>{companySize}</p>}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaBuilding />
                  </Col>
                  <Col xs={21}>
                    <p className="company-details-attribute-title">
                      Working Type
                    </p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {workingTypes.length > 1 &&
                          _.reduce(
                            workingTypes,
                            (w: string, val: any) => {
                              if (_.isEmpty(w)) {
                                return `${val.name} `;
                              }

                              return `${w}, ${val.name}`;
                            },
                            '',
                          )}

                        {workingTypes.length === 1 &&
                          _.get(workingTypes, '0.name')}
                        {workingTypes.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaStar />
                  </Col>
                  <Col xs={21}>
                    <p className="company-details-attribute-title">Benefit</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {benefits.length > 1 &&
                          _.reduce(
                            benefits,
                            (b: string, val: any) => {
                              if (_.isEmpty(b)) {
                                return `${val.name} `;
                              }

                              return `${b}, ${val.name}`;
                            },
                            '',
                          )}

                        {benefits.length === 1 && _.get(benefits, '0.name')}
                        {benefits.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaLink />
                  </Col>
                  <Col xs={21}>
                    <p className="company-details-attribute-title">Website</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <a href={companyWebsite} target="_blank" rel="noreferrer">
                        {companyWebsite}
                      </a>
                    )}
                  </Col>
                </Row>
              </Stack>
            </Panel>
          </Col>

          <Col xs={24}>
            <Panel className="company-details-card company-job-opening-card">
              <br />
              {!loading && (
                <Stack spacing={8}>
                  <h3
                    className="company-details-title"
                    style={{
                      marginTop: '8px',
                    }}
                  >
                    Our Job Openings Right Now!
                  </h3>
                </Stack>
              )}
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderParagraph active />
                </Stack>
              )}

              <br />
              <Row className="search-results-row">
                {_.map(jobPostings, (obj: any, idx: number) => {
                  const employmentStatus = _.get(
                    obj,
                    'employmentStatus.0.name',
                  );
                  const wTypes = _.get(obj, 'workingTypes.0.name');
                  const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
                  const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
                  const duration = _.get(obj, 'duration');
                  const jobId = _.get(obj, 'id');
                  const isSaved = _.get(obj, 'isSaved');
                  const isHot = _.get(obj, 'hot');

                  return (
                    <Col
                      key={`col-searchresults-${idx}`}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                    >
                      <Panel
                        className="company-details-search-result-card"
                        id="job-openings"
                      >
                        <Stack
                          direction="column"
                          spacing={20}
                          style={{ alignItems: 'flex-start' }}
                        >
                          {/* Badge */}
                          <Stack spacing={8}>
                            {isHot ? <Badge content="New" /> : <span />}
                            <Badge
                              className={
                                employmentStatus === 'Full Time'
                                  ? 'rs-badge-fulltime'
                                  : ''
                              }
                              content={employmentStatus}
                            />
                            {wTypes && (
                              <Badge
                                key={`workingTypes-${wTypes}`}
                                content={wTypes}
                              />
                            )}
                          </Stack>

                          {/* Company Information */}
                          <Stack spacing={6}>
                            <Row>
                              <Col xs={6}>
                                <Avatar
                                  circle
                                  size="lg"
                                  src={companyLogo}
                                  alt="logo-startup"
                                />
                              </Col>
                              <Col xs={18} className="company-info">
                                <Whisper
                                  placement="topStart"
                                  speaker={
                                    <Tooltip>
                                      <b>{companyName}</b>
                                    </Tooltip>
                                  }
                                >
                                  <p>{companyName}</p>
                                </Whisper>
                                <Whisper
                                  placement="topStart"
                                  speaker={
                                    <Tooltip>
                                      <b>{_.get(obj, 'title')}</b>
                                    </Tooltip>
                                  }
                                >
                                  <b>{_.get(obj, 'title')}</b>
                                </Whisper>
                              </Col>
                            </Row>
                          </Stack>

                          {/* Job Details */}
                          <Stack direction="column" spacing={8}>
                            <Row>
                              <Col xs={3}>
                                <FaMapMarkerAlt />
                              </Col>
                              <Col xs={21}>
                                <p>{_.get(details, 'region')}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3}>
                                <FaClock />
                              </Col>
                              <Col xs={21}>
                                {duration > 0 && <p>{duration} month(s)</p>}
                                {duration === 0 && <p>Full Time</p>}
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={3}>
                                <FaBuilding />
                              </Col>
                              <Col xs={21}>
                                <p>{companyIndustry}</p>
                              </Col>
                            </Row>
                          </Stack>

                          {/* Salary range */}
                          <Stack spacing={6}>
                            <p className="salary-range">
                              {util.formatSalaryRange(salaryLow, salaryHigh)}
                            </p>
                          </Stack>

                          {/* Job Card Buttons */}
                          <Stack spacing={6}>
                            <Row>
                              <Col xs={19}>
                                <Button
                                  className="btn-tg-primary"
                                  block
                                  appearance="primary"
                                  disabled={!obj.active}
                                  onClick={() => {
                                    // eslint-disable-next-line max-len
                                    const url = `/dashboard/candidate/jobs/${jobId}`;
                                    window.open(url);
                                  }}
                                >
                                  {obj.active
                                    ? t(
                                        constant.translation.searchJob
                                          .formApplyButtonKey,
                                      )
                                    : 'Closed'}
                                </Button>
                              </Col>
                              <Col xs={5}>
                                <IconButton
                                  onClick={() =>
                                    this.onSaveOrRemoveApplication(
                                      jobId,
                                      isSaved,
                                    )
                                  }
                                  appearance="default"
                                  size="lg"
                                  disabled={!_.get(obj, 'active')}
                                  icon={
                                    isSaved ? (
                                      <img
                                        // eslint-disable-next-line max-len
                                        src={`${process.env.PUBLIC_URL}/bookmark-logo-filled.png`}
                                        alt="bookmark"
                                        width={18}
                                        height={24}
                                      />
                                    ) : (
                                      <img
                                        // eslint-disable-next-line max-len
                                        src={`${process.env.PUBLIC_URL}/bookmark-logo.png`}
                                        alt="bookmark"
                                        width={18}
                                        height={24}
                                      />
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                          </Stack>
                        </Stack>
                      </Panel>
                    </Col>
                  );
                })}

                {!loading && jobPostings.length === 0 && (
                  <>
                    <br />
                    <p className="no-results-text">No results</p>
                  </>
                )}
              </Row>
            </Panel>
          </Col>

          <Col
            xs={24}
            style={{
              display:
                similarCompanies.length <= 1 &&
                _.isEqual(_.get(similarCompanies, '0.id'), _.get(details, 'id'))
                  ? 'none'
                  : '',
            }}
          >
            <Panel className="company-details-card company-job-opening-card">
              <br />
              {!loading && (
                <Stack spacing={8}>
                  <h3
                    className="company-details-title"
                    style={{
                      marginTop: '8px',
                    }}
                  >
                    Similar Company
                  </h3>
                </Stack>
              )}
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderParagraph active />
                </Stack>
              )}

              <br />
              {similarCompanies.length > 0 && (
                <Row>
                  {_.chain(similarCompanies)
                    .map((obj: any) => {
                      const cName = _.get(obj, 'name');
                      const cHeader = _.get(obj, 'background.url');
                      const industryName = _.get(obj, 'industryName');
                      const openingJobCount = _.get(obj, 'openingJobCount');
                      const cLogo = _.get(obj, 'logo.url');
                      const isShow = String(obj.id) !== companyId;

                      return (
                        isShow && (
                          <Col key={obj.id} xs={24} sm={12} md={12} lg={8}>
                            <Panel
                              bodyFill
                              // eslint-disable-next-line max-len
                              className="company-details-search-result-card similar-company-card"
                              onClick={() => {
                                const { navigate } = this.props;

                                navigate(
                                  `/dashboard/candidate/companies/${_.get(
                                    obj,
                                    'id',
                                  )}`,
                                );
                              }}
                            >
                              {cHeader ? (
                                <img
                                  src={cHeader}
                                  alt="card-header"
                                  width="100%"
                                />
                              ) : (
                                util.companyBg(industryName)
                              )}
                              <img src={cLogo} alt="company-logo" />
                              <Panel>
                                <Stack
                                  direction="column"
                                  spacing={14}
                                  style={{ alignItems: 'flex-start' }}
                                >
                                  {/* Company Information */}
                                  <Stack spacing={6}>
                                    <Row>
                                      <Col xs={24} className="company-info">
                                        <Whisper
                                          placement="topStart"
                                          speaker={
                                            <Tooltip>
                                              <b>{cName}</b>
                                            </Tooltip>
                                          }
                                        >
                                          <b>{cName}</b>
                                        </Whisper>
                                      </Col>
                                      <Col xs={24} className="company-info">
                                        <p>{industryName}</p>
                                      </Col>
                                    </Row>
                                  </Stack>

                                  {/* Job Counts */}
                                  <Stack direction="column" spacing={6}>
                                    <Row>
                                      <Col xs={3}>
                                        <FaSuitcase />
                                      </Col>
                                      <Col xs={21} className="jobs-count">
                                        <b>{openingJobCount} Jobs Opening</b>
                                      </Col>
                                    </Row>
                                  </Stack>
                                </Stack>
                              </Panel>
                            </Panel>
                          </Col>
                        )
                      );
                    })
                    .valueOf()}
                </Row>
              )}
            </Panel>
          </Col>
        </Row>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CandidateCompanyDetailsScreen));
