/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { util } from '@helper';
import React from 'react';
import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Grid,
  IconButton,
  Modal,
  Panel,
  Row,
  Stack,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite';
import {
  FaChevronLeft,
  FaArrowRight,
  FaBook,
  FaBuilding,
  FaInfoCircle,
  FaLightbulb,
  FaStar,
  FaWallet,
} from 'react-icons/fa';
import { CompanyAPI, JobApplicationAPI } from '@services/api';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';

const { withRouter } = util;

type State = {
  id: string | null;
  details: any;

  data: Array<any>;
  loading: boolean;
  loadingCards: boolean;

  myApplicationId: any;

  showModal: boolean;
  showSuccessModal: boolean;

  disabled: boolean;
};

class CandidateJobDetailsScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      id: null,
      details: null,
      data: [],
      myApplicationId: null,

      loading: false,
      loadingCards: false,
      showSuccessModal: false,
      showModal: false,

      disabled: false,
    };
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const { params } = this.props;
    const id = _.get(params, 'id');

    // Get job posting list
    this.getJobRecommendation();

    this.setState({ id, loading: true, loadingCards: true }, () =>
      this.getJobDetails(),
    );
  }

  componentDidUpdate(prevProps: Readonly<any>): void {
    const prevId = _.get(prevProps, 'params.id');
    const id = _.get(this.props, 'params.id');

    if (prevId !== id) {
      this.setState({ id, loading: true, loadingCards: true }, () => {
        this.getJobDetails();
        this.getJobRecommendation();
      });
    }
  }

  private getJobRecommendation = async (): Promise<void> => {
    try {
      const { details } = this.state;
      let id = null;

      if (details) {
        id = _.get(details, 'company.industry.id');
      }
      const res = await CompanyAPI.getJobRecommendation(id, 7);
      const data: Array<any> = _.get(res, 'data') || [];

      this.setState({
        data,
        loadingCards: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  getJobDetails = async (): Promise<void> => {
    try {
      const { id } = this.state;
      const res = await CompanyAPI.getJobDetails(String(id));
      const data = _.get(res, 'data');

      this.setState({
        details: data,
        loading: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  onApplyButtonClicked = async (): Promise<void> => {
    try {
      this.setState({ disabled: true });
      const { id } = this.state;
      const res = await JobApplicationAPI.apply(Number(id));

      this.setState({
        showSuccessModal: true,
        myApplicationId: _.get(res, 'data.id'),
      });
      this.getJobDetails();
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    } finally {
      this.setState({
        showModal: false,
        disabled: false,
      });
    }
  };

  private onSaveOrRemoveApplication = async (): Promise<void> => {
    try {
      const { details, id } = this.state;
      const isSaved = _.get(details, 'isSaved');
      let message = '';

      if (isSaved) {
        await JobApplicationAPI.remove(Number(id));
        message = 'Job posting has been removed from your bookmark list!';

        _.set(details, 'isSaved', false);

        this.setState({ details });
      } else {
        await JobApplicationAPI.save(Number(id));
        message = 'Job posting has been added to your bookmark list!';

        _.set(details, 'isSaved', true);

        this.setState({ details });
      }

      toaster.push(util.buildMessage(message));
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderSideCards = (): JSX.Element => {
    const { loadingCards, data } = this.state;

    return (
      <Col lg={7} mdHidden smHidden xsHidden>
        <Stack direction="column" spacing={24} className="job-list">
          {loadingCards &&
            _.range(0, 6).map((i: any) => (
              <PlaceholderGraph key={`loader-${i}`} active />
            ))}

          {!loadingCards &&
            _.map(data, (obj: any) => {
              const company = _.get(obj, 'company');
              const workingTypes: Array<any> = _.get(obj, 'workingTypes') || [];
              const companyLogo = _.get(obj, 'company.logo.url');
              const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
              const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
              const employmentStatus = _.get(obj, 'employmentStatus.0.name');
              const isHot = _.get(obj, 'isHot');
              const { id } = this.state;

              if (_.isEqual(obj.id, Number(id))) {
                return null;
              }

              return (
                <Panel
                  key={`job-card-${_.get(company, 'logo.name')}`}
                  className="job-card"
                  onClick={() => {
                    const { navigate } = this.props;
                    navigate(`/dashboard/candidate/jobs/${obj.id}`);
                  }}
                >
                  <Stack
                    direction="column"
                    spacing={20}
                    style={{ alignItems: 'flex-start' }}
                  >
                    {/* Company Information */}
                    <Stack spacing={6}>
                      <Row>
                        <Col xs={6} md={7}>
                          <Avatar
                            circle
                            size="lg"
                            src={companyLogo}
                            alt="logo-startup"
                          />
                        </Col>
                        <Col xs={18} md={17} className="company-info">
                          <Whisper
                            placement="topStart"
                            speaker={
                              <Tooltip>
                                <b>{_.get(company, 'name')}</b>
                              </Tooltip>
                            }
                          >
                            <p>{_.get(company, 'name')}</p>
                          </Whisper>
                          <Whisper
                            placement="topStart"
                            speaker={
                              <Tooltip>
                                <b>{_.get(obj, 'title')}</b>
                              </Tooltip>
                            }
                          >
                            <b>{_.get(obj, 'title')}</b>
                          </Whisper>
                        </Col>
                      </Row>
                    </Stack>

                    {/* Badge */}
                    <Stack spacing={8}>
                      {isHot ? <Badge content="New" /> : <span />}
                      <Badge
                        className={
                          employmentStatus === 'Full Time'
                            ? 'rs-badge-fulltime'
                            : ''
                        }
                        content={employmentStatus}
                      />
                      {workingTypes.map((val: any) => (
                        <Badge
                          key={`workingTypes-${val.name}`}
                          content={val.name}
                        />
                      ))}
                    </Stack>

                    {/* Salary range */}
                    <Stack spacing={6}>
                      <p className="salary-range">
                        {util.formatSalaryRange(salaryLow, salaryHigh)}
                      </p>
                    </Stack>
                  </Stack>
                </Panel>
              );
            })}
        </Stack>
      </Col>
    );
  };

  renderMainContent = (): JSX.Element | null => {
    const { details, loading } = this.state;
    const requirements = _.get(details, 'requirements');
    const description = _.get(details, 'description');
    const title = _.get(details, 'title');
    const companyId = _.get(details, 'company.id');
    const companyName = _.get(details, 'company.name');
    const minSalary = _.get(details, 'minSalaryRange');
    const maxSalary = _.get(details, 'maxSalaryRange');
    const benefits = _.get(details, 'company.benefits') || [];
    const cultures = _.get(details, 'company.cultures') || [];
    const workingTypes = _.get(details, 'workingTypes') || [];
    const industryName = _.get(details, 'company.industry.name') || '';
    const bg = _.get(details, 'company.background');

    return (
      <Col md={24} sm={24} xs={24} lg={17} className="job-details-main-content">
        <Row>
          <Col xs={24}>
            <a href="/dashboard/candidate/search-jobs">
              <FaChevronLeft />
              <span>Other Job</span>
            </a>
            <Divider style={{ backgroundColor: 'transparent' }} />
            <Panel bodyFill className="job-details-wrapper-card">
              <div className="card-header-wrapper">
                {bg ? (
                  <img src={bg.url} alt="card-header" width="100%" />
                ) : (
                  util.companyBg(industryName)
                )}
              </div>
              <h2 className="job-company-name">{companyName}</h2>
              <h2 className="job-title">{title}</h2>
              {!loading && (
                <Button
                  className="btn-tg-primary job-details-header-btn"
                  appearance="primary"
                  onClick={() => this.setState({ showModal: true })}
                  disabled={
                    _.get(this.state, 'details.isApplied') ||
                    _.get(this.state, 'details.isInTransferProcess')
                  }
                >
                  {_.get(this.state, 'details.isInTransferProcess')
                    ? 'In Transfer Process'
                    : _.get(this.state, 'details.isApplied')
                    ? 'Applied'
                    : 'Apply Now'}
                </Button>
              )}
              <Panel className="job-details-card">
                <Stack spacing={8}>
                  <FaLightbulb />
                  <p className="job-details-title">What You Are Going To Do?</p>
                </Stack>
                <Divider />
                {!loading && (
                  <Stack spacing={8}>
                    <p
                      className="job-description"
                      dangerouslySetInnerHTML={{
                        __html: description,
                      }}
                    />
                  </Stack>
                )}
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}

                <br />
                <br />
                <Stack spacing={8}>
                  <FaBook />
                  <p className="job-details-title">Requirements</p>
                </Stack>
                <Divider />
                {!loading && (
                  <Stack spacing={8}>
                    <p
                      className="job-requirements"
                      dangerouslySetInnerHTML={{
                        __html: requirements,
                      }}
                    />
                  </Stack>
                )}
                {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}

                <br />
                <br />
                {/* <Stack spacing={8}>
                  <FaFire />
                  <p className="job-details-title">Skill Needed</p>
                </Stack>
                <Divider /> */}
                {/* {loading && (
                  <Stack spacing={8} className="loading-skeleton">
                    <PlaceholderGrid rows={7} columns={1} active />
                  </Stack>
                )}
                <Stack spacing={8} /> */}
              </Panel>
            </Panel>
          </Col>
        </Row>

        <Row>
          <Col xs={24} sm={24} md={14}>
            <Panel className="job-details-company-card">
              <Stack spacing={8}>
                <p className="job-details-company-title">About Company</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={7} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: _.get(details, 'company.description'),
                    }}
                  />
                </Stack>
              )}
              <br />
              <br />
              <Stack spacing={8}>
                <p className="job-details-company-title">Location</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={1} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p>
                    {_.get(details, 'jobLocation.cityName', null)}
                    {_.get(details, 'jobLocation.cityName') ? ', ' : null}
                    {_.get(
                      details,
                      'jobLocation.region',
                      _.get(details, 'company.region'),
                    ) || '-'}
                  </p>
                </Stack>
              )}
              <br />
              <br />
              <Stack spacing={8}>
                <p className="job-details-company-title">Industry</p>
              </Stack>
              <br />
              {loading && (
                <Stack spacing={8} className="loading-skeleton">
                  <PlaceholderGrid rows={1} columns={1} active />
                </Stack>
              )}
              {!loading && (
                <Stack spacing={8}>
                  <p>{_.get(details, 'company.industry.name')}</p>
                </Stack>
              )}
              <br />
              <Stack spacing={8}>
                <Button
                  onClick={() => {
                    window.open(
                      `/dashboard/candidate/companies/${companyId}`,
                      '_self',
                    );
                  }}
                  appearance="link"
                  className="learn-more-btn"
                >
                  Learn More <FaArrowRight />
                </Button>
              </Stack>
            </Panel>
          </Col>
          <Col xs={24} sm={24} md={10}>
            <Panel className="job-details-company-card">
              <Stack
                spacing={8}
                direction="column"
                style={{ alignItems: 'flex-start' }}
              >
                <Row>
                  <Col xs={3}>
                    <FaBuilding />
                  </Col>
                  <Col xs={21}>
                    <p className="job-details-attribute-title">Working Type</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {workingTypes.length > 1 &&
                          _.reduce(
                            workingTypes,
                            (w: string, val: any) => {
                              if (_.isEmpty(w)) {
                                return `${val.name} `;
                              }

                              return `${w}, ${val.name}`;
                            },
                            '',
                          )}

                        {workingTypes.length === 1 &&
                          _.get(workingTypes, '0.name')}
                        {workingTypes.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaStar />
                  </Col>
                  <Col xs={21}>
                    <p className="job-details-attribute-title">Benefit</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {benefits.length > 1 &&
                          _.reduce(
                            benefits,
                            (b: string, val: any) => {
                              if (_.isEmpty(b)) {
                                return `${val.name} `;
                              }

                              return `${b}, ${val.name}`;
                            },
                            '',
                          )}

                        {benefits.length === 1 && _.get(benefits, '0.name')}
                        {benefits.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3}>
                    <FaWallet />
                  </Col>
                  <Col xs={21}>
                    <p className="job-details-attribute-title">Salary</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>{util.formatSalaryRange(minSalary, maxSalary)}</p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={3} style={{ marginBottom: '0px' }}>
                    <FaInfoCircle />
                  </Col>
                  <Col xs={21}>
                    <p className="job-details-attribute-title">Culture</p>
                    {loading && <PlaceholderGrid rows={1} columns={1} active />}
                    {!loading && (
                      <p>
                        {cultures.length > 1 &&
                          _.reduce(
                            cultures,
                            (c: string, val: any) => {
                              if (_.isEmpty(c)) {
                                return `${val.name} `;
                              }

                              return `${c}, ${val.name}`;
                            },
                            '',
                          )}

                        {cultures.length === 1 && _.get(cultures, '0.name')}
                        {cultures.length === 0 && '-'}
                      </p>
                    )}
                  </Col>
                </Row>
              </Stack>
            </Panel>
          </Col>
        </Row>
      </Col>
    );
  };

  renderModal = ({ details } = this.state): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showModal')}
      onClose={() => {
        this.setState({ showModal: false });
      }}
    >
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-image-apply-job.png`}`}
          className="bg-apply-job"
          alt="bg-img"
        />
        <h3>
          Apply as {_.get(details, 'title')} at {_.get(details, 'company.name')}
          ?
        </h3>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            this.setState({ showModal: false });
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={this.onApplyButtonClicked}
          disabled={_.get(this.state, 'disabled')}
          appearance="primary"
        >
          Yes, Apply
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderSuccessfulModal = (
    { details, myApplicationId } = this.state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showSuccessModal')}
      onClose={() => {
        this.setState({ showSuccessModal: false });
      }}
    >
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-image-apply-job.png`}`}
          className="bg-apply-job"
          alt="bg-img"
        />
        <h3 style={{ justifyContent: 'center' }}>Congratulations!</h3>
        <p style={{ marginTop: '24px' }}>
          You&lsquo;ve taken the first step in the application process for a
          <b> {_.get(details, 'title')}</b> position at{' '}
          <b>{_.get(details, 'company.name')}</b>!
          <br /> <br />
          As one that bridges education and the future workforce, Talent
          Growth&rsquo;s advanced process uses practical assessments to prove
          aptitude and validate abilities.
          <br /> <br />
          Let&rsquo;s continue your application by clicking the button below!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            const { navigate } = this.props;
            navigate(`/dashboard/candidate/my-application/${myApplicationId}`);
          }}
          appearance="primary"
        >
          Continue Application
        </Button>
      </Modal.Footer>
    </Modal>
  );

  renderStickyBottomSheet = ({ details } = this.state): JSX.Element => (
    <div id="bottom-action">
      <Grid>
        <Row>
          <Col xs={20}>
            <Button
              className="btn-tg-primary job-details-header-btn"
              appearance="primary"
              block
              onClick={() => this.setState({ showModal: true })}
              disabled={_.get(this.state, 'details.applied')}
            >
              {_.get(this.state, 'details.applied') ? 'Applied' : 'Apply Now'}
            </Button>
          </Col>
          <Col xs={4}>
            <IconButton
              onClick={this.onSaveOrRemoveApplication}
              appearance="default"
              size="lg"
              icon={
                _.get(details, 'isSaved') ? (
                  <img
                    // eslint-disable-next-line max-len
                    src={`${process.env.PUBLIC_URL}/bookmark-logo-filled.png`}
                    alt="bookmark"
                    width={18}
                    height={24}
                  />
                ) : (
                  <img
                    // eslint-disable-next-line max-len
                    src={`${process.env.PUBLIC_URL}/bookmark-logo.png`}
                    alt="bookmark"
                    width={18}
                    height={24}
                  />
                )
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  );

  render(): JSX.Element | null {
    return (
      <>
        {this.renderSuccessfulModal()}
        {this.renderModal()}
        {this.renderStickyBottomSheet()}

        <Grid className="home-static-authorized-container" fluid>
          <Row>
            {this.renderSideCards()}
            {this.renderMainContent()}
          </Row>
        </Grid>
      </>
    );
  }
}

export default withRouter(withTranslation()(CandidateJobDetailsScreen));
