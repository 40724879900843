import { Component, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  render(): ReactNode {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <div>
            <h1>Unexpected Error</h1>
            <p>This is a problem on our side, not yours.</p>
            <p>
              <button type="button" onClick={() => window.location.reload()}>
                Reload
              </button>
            </p>
          </div>
        </div>
      );
    }

    return children;
  }
}
