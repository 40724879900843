import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Col, FlexboxGrid, Form, Panel, toaster } from 'rsuite';
import { constant, util } from '@helper';
import { AuthAPI } from '@services/api';

const { withRouter } = util;

type State = {
  email: string;
};

class HiringPartnerResetPasswordScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
    };
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const { location } = this.props;
    const { state } = location;
    const email = _.get(state, 'email');

    this.setState({ email });
  }

  resendPassResetLink = async (): Promise<void> => {
    const { email } = this.state;
    const { t } = this.props;

    if (_.isEmpty(email)) {
      return;
    }

    try {
      await AuthAPI.forgotPassword({ email });

      toaster.push(
        util.buildMessage(
          t(constant.translation.profile.formSuccessSendResetPassKey),
        ),
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  render({ t } = this.props): JSX.Element | null {
    const { email } = this.state;

    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        id="hiring-partner"
        className="resend-pass-container"
      >
        <FlexboxGrid.Item as={Col} colspan={8} xs={24}>
          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />

          <FlexboxGrid.Item as={Col} xs={22} sm={16} md={14} lg={8}>
            <img src="/bg-check-email.png" alt="bg" />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={8} xs={24} sm={24} md={16} lg={8}>
          <Panel
            className="resend-pass-panel"
            header={
              <div>
                <span>
                  {t(constant.translation.resendPass.pageCandidateTitleKey)}
                </span>
                <small>
                  {t(constant.translation.resendPass.pageCandidateSubTitleKey)}
                  <b>{email || 'example.com'}</b>
                </small>
              </div>
            }
            bordered
          >
            <Form key="resend-pass-form" fluid>
              <br />
              <p className="resend-section">
                <span>Didn&lsquo;t receive the email?</span>
                <Button appearance="link" onClick={this.resendPassResetLink}>
                  Click to resend
                </Button>
              </p>
              <br />
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerResetPasswordScreen));
