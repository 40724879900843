import currency from 'currency.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Message } from 'rsuite';
import AuthStorage from '@services/authStorage';
import _ from 'lodash';

function hasToken(): boolean {
  const storage = AuthStorage.getInstance();
  const token = storage.getAccessToken();

  return token != null;
}

function buildMessage(message: string): JSX.Element {
  return (
    <Message showIcon closable type="success">
      {message}
    </Message>
  );
}

function buildInfoMessage(message: string): JSX.Element {
  return (
    <Message showIcon closable type="info">
      {message}
    </Message>
  );
}

function buildErrorMessage(message: string): JSX.Element {
  return (
    <Message showIcon closable type="error">
      {message}
    </Message>
  );
}

function toLink(s: string): string {
  let website = s;
  if (_.toUpper(website) === 'N/A') {
    website = '';
  }

  if (!website.match(/^[a-zA-Z]+:\/\//) && !_.isEmpty(website)) {
    website = `https://${website}`;
  }

  return website;
}

function blobToBase64(blob: File | any): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const arr = _.split(String(reader.result), ',');
      const result = _.last(arr);
      resolve(String(result));
    };
    reader.readAsDataURL(blob);
  });
}

function formatCurrency(value: number | undefined, symbol = 'Rp '): string {
  return currency(value ? Number(value) : 0, {
    symbol,
    decimal: ',',
    separator: '.',
    precision: 0,
  }).format();
}

function formatSalaryRange(
  min: number | undefined,
  max: number | undefined,
): string {
  return `${formatCurrency(min)} - ${formatCurrency(max, '')}`;
}

function formatTime(second: number): string {
  return `00:${String(second).padStart(2, '0')}`;
}

function withRouter(Component: any): (props: any) => JSX.Element {
  const location = useLocation;
  const params = useParams;
  const navigate = useNavigate;
  // eslint-disable-next-line func-names
  return function (props: any): JSX.Element {
    return (
      <Component
        {...props}
        location={location()}
        navigate={navigate()}
        params={params()}
      />
    );
  };
}

function mapSeekingStatus(s: string): string | null {
  switch (s) {
    case 'ACTIVELY_LOOKING':
      return 'Actively looking for opportunities';
    case 'HIRED_OPEN':
      return 'Hired, but search for opportunities';
    case 'NOT_OPEN':
      return 'Not open for opportunities';
    default:
      return null;
  }
}

function printGender(gender: string): string {
  return gender === 'M' ? 'Male' : 'Female';
}

function mapBgUrl(str: string): string {
  let img = 'others';
  const s = str.toLowerCase();

  if (s.includes('business')) {
    img = 'business';
  } else if (s.includes('communication')) {
    img = 'communication';
  } else if (s.includes('computer science')) {
    img = 'computer-science';
  } else if (s.includes('creative')) {
    img = 'creative-design';
  } else if (s.includes('education')) {
    img = 'education';
  } else if (s.includes('engineering')) {
    img = 'engineering';
  } else if (s.includes('finance')) {
    img = 'finance';
  } else if (s.includes('healthcare')) {
    img = 'healthcare';
  } else if (s.includes('hospitality')) {
    img = 'hospitality';
  } else if (s.includes('legal')) {
    img = 'legal';
  } else if (s.includes('literature')) {
    img = 'literature';
  } else if (s.includes('psycology')) {
    img = 'psycology';
  } else if (s.includes('science')) {
    img = 'science';
  } else if (s.includes('urban living')) {
    img = 'urban-living';
  }

  // eslint-disable-next-line max-len
  return `https://tgpublic-bucket.s3.ap-southeast-1.amazonaws.com/assets/${img}.png`;
}

function companyBg(str: string): JSX.Element {
  const imgUrl = mapBgUrl(str);
  return (
    <img
      // eslint-disable-next-line max-len
      src={imgUrl}
      alt="card-header"
      width="100%"
    />
  );
}

export default {
  hasToken,
  blobToBase64,
  buildMessage,
  buildInfoMessage,
  buildErrorMessage,
  formatCurrency,
  formatSalaryRange,
  formatTime,
  printGender,
  mapSeekingStatus,
  withRouter,
  toLink,
  companyBg,
  mapBgUrl,
};
