import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  FaHome,
  FaBuilding,
  FaSuitcase,
  FaFileAlt,
  FaUser,
  FaSearch,
} from 'react-icons/fa';
import { util } from '@helper';
import {
  Col,
  Affix,
  Stack,
  IconButton,
  Grid,
  Row,
  InputGroup,
  TagPicker,
  Input,
  SelectPicker,
  toaster,
  FlexboxGrid,
  Panel,
  Tooltip,
  Whisper,
} from 'rsuite';
import { CompanyAPI, MiscAPI } from '@services/api';
import { Promise } from 'bluebird';
import InfiniteScroll from 'react-infinite-scroller';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';

type State = {
  hasMorePage: boolean;
  loading: boolean;
  loadingPreference: boolean;
  results: Array<object>;
  resultsByPreference: Array<object>;
  query: string | null;
  companyIndustries: Array<object>;

  filterByIndustries: Array<number>;
  filterCompany: string;
};

const { withRouter } = util;

class CandidateSearchJobScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: [],
      resultsByPreference: [],
      loading: true,
      loadingPreference: true,
      query: null,
      hasMorePage: false,
      companyIndustries: [],
      filterByIndustries: [],
      filterCompany: '',
    };
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.searchCompanies();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompaniesBasedPreference();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('COMPANY_INDUSTRY');
    const data = _.get(response, 'data') || [];
    this.setState({
      companyIndustries: data,
    });
  };

  private getCompaniesBasedPreference = async (): Promise<void> => {
    try {
      const res = await CompanyAPI.companyListByPreference();
      this.setState({
        resultsByPreference: _.get(res, 'data') || [],
        loadingPreference: false,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
      throw e;
    }
  };

  private searchCompanies = async (page = 1): Promise<void> => {
    try {
      const { query, filterCompany } = this.state;
      const res = await CompanyAPI.searchCompanies(query, page, filterCompany);
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loading: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private searchByIndustry = async (page = 1): Promise<void> => {
    try {
      const {
        query,
        filterByIndustries: industryId,
        filterCompany,
      } = this.state;
      const res = await CompanyAPI.companyListByIndustry(
        industryId,
        page,
        filterCompany,
        query,
      );
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const total = Number(_.get(res, 'meta.total') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loading: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage && total > 0,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/search-companies',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/my-application',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderSearchResults = (): React.ReactNode => {
    const { results, hasMorePage, loading } = this.state;
    const width = window.innerWidth;

    let size = 3;
    let threshold = 1 * window.innerHeight;
    if (width <= 425) {
      size = 1;
    } else if (width <= 1280) {
      size = 2;
    } else {
      threshold = 300;
      size = 3;
    }
    const chunked = _.chunk(results, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={threshold}
        loadMore={(page) => {
          const { filterByIndustries } = this.state;

          if (filterByIndustries instanceof Array<number>) {
            if (filterByIndustries.length > 0) {
              this.searchByIndustry(page);
            } else {
              this.searchCompanies(page);
            }
          }
        }}
        hasMore={hasMorePage}
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loading && (
          <Row className="loader-container">
            {_.range(0, 6).map((idx) => (
              <Col key={`loader-${idx}`} xs={24} sm={12} md={12} lg={8}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loading && results.length === 0 && (
          <p className="no-results-text">No results</p>
        )}

        {!loading &&
          _.map(chunked, (data: Array<any>, index: number) => (
            <Row
              className="search-results-row"
              key={`row-searchresults-${index}`}
            >
              {_.map(data, (obj: any, idx: number) => {
                const industry = _.get(obj, 'industry');
                const companyLogo = _.get(obj, 'logo.url');
                const companyId = _.get(obj, 'id');
                const companyHeader = _.get(obj, 'background.url');

                return (
                  <Col
                    key={`col-searchresults-${idx}`}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                  >
                    <Panel
                      bodyFill
                      className="search-result-card"
                      onClick={() => {
                        const { navigate } = this.props;

                        navigate(`/dashboard/candidate/companies/${companyId}`);
                      }}
                    >
                      {companyHeader ? (
                        <img
                          src={companyHeader}
                          alt="card-header"
                          width="100%"
                        />
                      ) : (
                        util.companyBg(_.get(industry, 'name', '-'))
                      )}
                      <img src={companyLogo} alt="company-logo" />
                      <Panel>
                        <Stack
                          direction="column"
                          spacing={14}
                          style={{ alignItems: 'flex-start' }}
                        >
                          {/* Company Information */}
                          <Stack spacing={6}>
                            <Row>
                              <Col xs={24} className="company-info">
                                <Whisper
                                  placement="topStart"
                                  speaker={
                                    <Tooltip>
                                      <b>{_.get(obj, 'name')}</b>
                                    </Tooltip>
                                  }
                                >
                                  <b>{_.get(obj, 'name')}</b>
                                </Whisper>
                              </Col>
                              <Col xs={24} className="company-info">
                                <p>{_.get(industry, 'name', '-')}</p>
                              </Col>
                            </Row>
                          </Stack>

                          {/* Job Counts */}
                          <Stack
                            direction="column"
                            spacing={6}
                            className="opening-jobs-container"
                          >
                            <Row>
                              <Col xs={2}>
                                <FaSuitcase />
                              </Col>
                              <Col xs={22} className="jobs-count">
                                <b>
                                  {_.get(obj, 'openingJobCount')} Jobs Opening
                                </b>
                              </Col>
                            </Row>
                          </Stack>
                        </Stack>
                      </Panel>
                    </Panel>
                  </Col>
                );
              })}
            </Row>
          ))}
      </InfiniteScroll>
    );
  };

  renderPreferenceCompanies = (): React.ReactNode => {
    const { resultsByPreference, loadingPreference: loading } = this.state;
    const width = window.innerWidth;

    let size = 3;
    if (width <= 425) {
      size = 1;
    } else if (width <= 1280) {
      size = 2;
    } else {
      size = 3;
    }
    const chunked = _.chunk(resultsByPreference, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={0}
        loadMore={() => null}
        hasMore={false}
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loading && (
          <Row className="loader-container">
            {_.range(0, 6).map((idx) => (
              <Col key={`loader-${idx}`} xs={24} sm={12} md={12} lg={8}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loading &&
          _.map(chunked, (data: Array<any>, index: number) => (
            <Row
              className="search-results-row"
              key={`row-searchresults-${index}`}
            >
              {_.map(data, (obj: any, idx: number) => {
                const industry = _.get(obj, 'industry');
                const companyLogo = _.get(obj, 'logo.url');
                const companyId = _.get(obj, 'id');
                const companyHeader = _.get(obj, 'background.url');

                return (
                  <Col
                    key={`col-searchresults-${idx}`}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                  >
                    <Panel
                      bodyFill
                      className="search-result-card"
                      onClick={() => {
                        const { navigate } = this.props;

                        navigate(`/dashboard/candidate/companies/${companyId}`);
                      }}
                    >
                      {companyHeader ? (
                        <img
                          src={companyHeader}
                          alt="card-header"
                          width="100%"
                        />
                      ) : (
                        util.companyBg(_.get(industry, 'name', '-'))
                      )}
                      <img src={companyLogo} alt="company-logo" />
                      <Panel>
                        <Stack
                          direction="column"
                          spacing={14}
                          style={{ alignItems: 'flex-start' }}
                        >
                          {/* Company Information */}
                          <Stack spacing={6}>
                            <Row>
                              <Col xs={24} className="company-info">
                                <Whisper
                                  placement="topStart"
                                  speaker={
                                    <Tooltip>
                                      <b>{_.get(obj, 'name')}</b>
                                    </Tooltip>
                                  }
                                >
                                  <b>{_.get(obj, 'name')}</b>
                                </Whisper>
                              </Col>
                              <Col xs={24} className="company-info">
                                <p>{_.get(industry, 'name', '-')}</p>
                              </Col>
                            </Row>
                          </Stack>

                          {/* Job Counts */}
                          <Stack
                            direction="column"
                            spacing={6}
                            className="opening-jobs-container"
                          >
                            <Row>
                              <Col xs={2}>
                                <FaSuitcase />
                              </Col>
                              <Col xs={22} className="jobs-count">
                                <b>
                                  {_.get(obj, 'openingJobCount')} Jobs Opening
                                </b>
                              </Col>
                            </Row>
                          </Stack>
                        </Stack>
                      </Panel>
                    </Panel>
                  </Col>
                );
              })}
            </Row>
          ))}

        {!loading && resultsByPreference.length === 0 && (
          <p className="no-results-text">No results</p>
        )}
      </InfiniteScroll>
    );
  };

  renderMainContent = (): JSX.Element | null => {
    const { companyIndustries } = this.state;

    return (
      <Col
        md={24}
        sm={24}
        xs={24}
        lg={19}
        className="search-company-main-content"
      >
        <Col xs={24}>
          <h1>Amazing Companies For You</h1>
          <p style={{ marginTop: '8px', color: '#595959' }}>
            Curated based on your preferences!
          </p>
        </Col>

        <Col xs={24}>{this.renderPreferenceCompanies()}</Col>

        <Col xs={24}>
          <h1>Explore All Companies</h1>
        </Col>

        <Col xs={24}>
          <Col xs={24} sm={10}>
            <InputGroup inside>
              <InputGroup.Button>
                <FaSearch />
              </InputGroup.Button>
              <Input
                name="search"
                placeholder="Company Name"
                onChange={(query: string) => {
                  this.setState({
                    query,
                    loading: true,
                  });

                  const industries = _.get(this.state, 'filterByIndustries');

                  if (industries.length > 0) {
                    _.debounce(this.searchByIndustry, 1000)();
                  } else {
                    _.debounce(this.searchCompanies, 1000)();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col xs={24} sm={7}>
            <SelectPicker
              data={_.map(
                [
                  {
                    name: 'Most Relevant',
                    value: 'MOST_RELEVANT',
                  },
                  {
                    name: 'Recently Hiring',
                    value: 'RECENTLY_HIRING',
                  },
                ],
                (l) => ({
                  label: _.get(l, 'name'),
                  value: _.get(l, 'value'),
                }),
              )}
              searchable={false}
              onSelect={(v) => {
                this.setState({ filterCompany: v }, () => {
                  const { filterByIndustries } = this.state;
                  this.setState({ loading: true });
                  if (filterByIndustries.length > 0) {
                    this.searchByIndustry(1);
                  } else {
                    this.searchCompanies(1);
                  }
                });
              }}
              onClean={() => {
                this.setState({ filterCompany: '' }, () => {
                  const { filterByIndustries } = this.state;
                  this.setState({ loading: true });
                  if (filterByIndustries.length > 0) {
                    this.searchByIndustry(1);
                  } else {
                    this.searchCompanies(1);
                  }
                });
              }}
              placeholder="Most Relevant"
            />
          </Col>
          <Col xs={24} sm={7}>
            <TagPicker
              data={_.map(companyIndustries, (c: any) => ({
                label: c.name,
                value: c.id,
              }))}
              onChange={(val) => {
                this.setState(
                  {
                    filterByIndustries: val,
                  },
                  () => {
                    this.setState({ loading: true });
                    if (val instanceof Array<number>) {
                      if (val.length > 0) {
                        this.searchByIndustry(1);
                      } else {
                        this.searchCompanies(1);
                      }
                    }

                    if (val === null) {
                      this.searchCompanies(1);
                    }
                  },
                );
              }}
              placeholder="Select Industry"
            />
          </Col>
        </Col>

        <Col xs={24}>{this.renderSearchResults()}</Col>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CandidateSearchJobScreen));
