import Http from '@services/httpClient';
import { Promise } from 'bluebird';
import _ from 'lodash';
import { ValueType } from 'rsuite/esm/Checkbox';

const prefix = '/api/v1/my-applications';

export default {
  allByPostingId(jobPostingId: number, page = 1, limit = 9) {
    return new Promise((resolve, reject) => {
      Http.get({
        // eslint-disable-next-line max-len
        url: `${prefix}?page=${page}&limit=${limit}&jobPostingId=${jobPostingId}`,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  allByPostingIdWithFilter(
    jobPostingId: number,
    filters: ValueType[],
    page = 1,
    limit = 9,
  ) {
    const rawFilters = _.map(filters, (f) => f.toString()).join(',');

    return new Promise((resolve, reject) => {
      Http.get({
        // eslint-disable-next-line max-len
        url: `${prefix}?page=${page}&limit=${limit}&jobPostingId=${jobPostingId}&filters=${rawFilters}`,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  inprogress(page: number, limit = 9, type = 'inprogress') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}?page=${page}&limit=${limit}&type=${type}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  history(page: number, limit = 9, type = 'history') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}?page=${page}&limit=${limit}&type=${type}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  saved(page: number, limit = 9) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/save?page=${page}&limit=${limit}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  details(id: number) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  isAnyNewTransferJob() {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/check-transfer-jobs` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  interviewSteps() {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/interview-steps` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  apply(id: number) {
    return new Promise((resolve, reject) => {
      Http.post({ url: `${prefix}/apply`, data: { jobPostingId: id } })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  save(id: number) {
    return new Promise((resolve, reject) => {
      Http.post({ url: `${prefix}/save`, data: { jobPostingId: id } })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  remove(id: number) {
    return new Promise((resolve, reject) => {
      Http.delete({ url: `${prefix}/save/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  summary() {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/summary` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateStatusToTechnicalTestSubmitted(
    id: string,
    payload: object,
    status = 'TECHNICAL_TEST_SUBMITTED_BY_CANDIDATE',
  ) {
    return new Promise((resolve, reject) => {
      const obj: any = {
        status,
        ...payload,
      };
      const data = Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');

      Http.put({
        url: `/dashboard/job-applications/${id}`,
        data,
        headers: {
          'internal-call': true,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateStatusToOfferAccepted(id: string, status = 'OFFER_ACCEPTED') {
    return new Promise((resolve, reject) => {
      const obj: any = { status };
      const data = Object.keys({ status })
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');

      Http.put({
        url: `/dashboard/job-applications/${id}`,
        data,
        headers: {
          'internal-call': true,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateStatusToInterviewInProgress(
    id: string,
    status = 'INTERVIEW_IN_PROGRESS',
  ) {
    return new Promise((resolve, reject) => {
      const obj: any = { status };
      const data = Object.keys({ status })
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');

      Http.put({
        url: `/dashboard/job-applications/${id}`,
        data,
        headers: {
          'internal-call': true,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateStatusTo(id: number, status = 'TECHNICAL_TEST_PASSED') {
    return new Promise((resolve, reject) => {
      const obj: any = { status };
      const data = Object.keys({ status })
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join('&');

      Http.put({
        url: `/dashboard/job-applications/${id}`,
        data,
        headers: {
          'internal-call': true,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateStatusToWithData(id: number, data: object, status = '') {
    return new Promise((resolve, reject) => {
      const obj = {
        status,
        ...data,
      };
      const body = Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(_.get(obj, key))}`)
        .join('&');

      Http.put({
        url: `/dashboard/job-applications/${id}`,
        data: body,
        headers: {
          'internal-call': true,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
