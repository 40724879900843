import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  InputGroup,
  Panel,
  Row,
  Schema,
  toaster,
} from 'rsuite';
import { constant, util } from '@helper';
import _ from 'lodash';
import { LoginPayload } from '@common/ApiTypes';
import constants from '@helper/constants';
import { AuthAPI } from '@services/api';
import AuthStorage from '@common/services/authStorage';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const { withRouter } = util;
const { StringType } = Schema.Types;

type State = {
  login: LoginPayload;
  rememberMe: boolean;
  showPassword: boolean;
};

class HiringPartnerLoginScreen extends React.Component<any, State> {
  formSignInRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formLoginSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      login: {
        email: '',
        password: '',
      },
      showPassword: false,
      rememberMe: false,
    };

    this.formSignInRef = React.createRef();
    this.formLoginSchemaModel = Schema.Model({
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
      password: StringType().isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');
  }

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onSignInButtonClicked = async (): Promise<void> => {
    const { t, navigate } = this.props;

    if (!_.invoke(this.formSignInRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.login.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { login: payload } = this.state;
      const res = await AuthAPI.login(payload);
      const data: any = _.get(res, 'data');

      toaster.push(
        util.buildMessage(
          t(constant.translation.login.formSuccessValidationMessageKey),
        ),
      );

      // Set access token
      const { user } = data;
      const authStorage = AuthStorage.getInstance();
      authStorage.setUser(user);

      const profile = _.get(user, 'profile');
      const appRole = _.get(user, 'appRole');

      if (appRole === 'USER') {
        if (_.isEmpty(profile)) {
          navigate('/basic-info', { replace: true });
        }
      }

      navigate('/', { replace: true });
      window.location.reload();
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));

      // Erase the password
      this.setState((prevState) => ({
        ...prevState,
        login: {
          ...prevState.login,
          password: '',
        },
      }));
    }
  };

  render(
    { t } = this.props,
    { rememberMe, showPassword } = this.state,
  ): JSX.Element | null {
    return (
      <FlexboxGrid
        id="hiring-partner"
        justify="center"
        align="middle"
        className="login-container"
      >
        <Panel
          className="login-panel"
          header={
            <div>
              <h1>{t(constant.translation.login.pageHPTitleKey)}</h1>
              <small>Welcome back! Please enter your details.</small>
            </div>
          }
          bordered
        >
          <Form
            key="signin-form"
            ref={this.formSignInRef}
            model={this.formLoginSchemaModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'login')}
            formValue={_.get(this.state, 'login')}
            fluid
          >
            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formCompanyEmailKey)}
              </Form.ControlLabel>
              <Form.Control
                name="email"
                placeholder="Enter your company email address"
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password here"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.onSignInButtonClicked();
                    }
                  }}
                />

                <InputGroup.Button
                  onClick={() => this.setState({ showPassword: !showPassword })}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>

            <Row className="remember-me-section">
              <Col xs={12}>
                <span>
                  <Checkbox
                    onChange={() => this.setState({ rememberMe: !rememberMe })}
                  />
                  Remember Me
                </span>
              </Col>

              <Col xs={12}>
                <a
                  href="/forgot-password/hiring-partner"
                  style={{ float: 'right', paddingTop: '5px' }}
                >
                  Forgot Password?
                </a>
              </Col>
            </Row>

            <Button
              className="btn-tg-primary-hp"
              block
              appearance="primary"
              onClick={this.onSignInButtonClicked}
            >
              {t(constant.translation.general.formLoginButtonKey)}
            </Button>

            <br />
            <p className="create-account-section">
              Don&rsquo;t have an account? &nbsp;
              <a href="/sign-up/hiring-partner">Create an Account</a>
            </p>
            <br />
          </Form>
        </Panel>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerLoginScreen));
