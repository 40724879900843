import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  IconButton,
  InputNumber,
  InputPicker,
  Loader,
  Panel,
  RangeSlider,
  Row,
  Schema,
  SelectPicker,
  Steps,
  TagPicker,
  Tooltip,
  Uploader,
  Whisper,
  toaster,
} from 'rsuite';

import { constant, util } from '@helper';
import React from 'react';
import constants from '@helper/constants';
import _ from 'lodash';
import moment from 'moment';
import { DocumentAPI, MiscAPI, UserAPI } from '@services/api';
import { Promise } from 'bluebird';
import AuthStorage from '@common/services/authStorage';
import { FaChevronLeft, FaInfoCircle } from 'react-icons/fa';
import { Icon } from '@rsuite/icons';
import { FileType } from 'rsuite/esm/Uploader';

const { withRouter } = util;
const { StringType, ArrayType, NumberType, ObjectType } = Schema.Types;

type BasicInfoTwo = {
  yoe: number;
  organization: string | null | undefined;
  jobRole: Array<string | number>;
  seekingStatus: string;
  industry: Array<number>;
  salaryRange: Array<number>;
  cv: FileType[];
  additionalFile: FileType[];
};

type State = {
  basicInfo: object;
  basicInfoTwo: BasicInfoTwo;
  phoneCodes: Array<object>;
  companyIndustries: Array<any>;
  universities: Array<any>;
  majors: Array<any>;
  location: Array<object>;
  dreamJobs: Array<any>;
  jobRoleCacheData: Array<any>;
  sources: Array<any>;
  step: number;
};

class CandidateBasicInfoScreen extends React.Component<any, State> {
  formRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formSchemaModel: any;

  formTwoRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formSchemaTwoModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      basicInfo: {
        phoneRegion: '+62',
      },
      basicInfoTwo: {
        yoe: 0,
        organization: '',
        jobRole: [],
        seekingStatus: '',
        industry: [],
        salaryRange: [],
        cv: [],
        additionalFile: [],
      },

      step: 1,

      // misc
      phoneCodes: [],
      location: [],
      universities: [],
      majors: [],
      companyIndustries: [],
      dreamJobs: [],
      sources: [],

      jobRoleCacheData: [],
    };

    this.formRef = React.createRef();
    this.formSchemaModel = Schema.Model({
      university: StringType().isRequired('Please fill the required field'),
      major: StringType().isRequired('Please fill the required field'),
      phoneRegion: StringType().isRequired('Please fill the required field'),
      englishProficiency: StringType().isRequired(
        'Please fill the required field',
      ),
      phone: StringType()
        .addRule((value) => {
          // (^\8)
          const pattern = /^(\d{2,3}-?){2}\d{3,8}$/g;
          return new RegExp(pattern).test(value);
        }, 'Invalid phone number. Ex: 81122334')
        .isRequired(),
      yearStart: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.yearEnd) {
            return Number(value) <= Number(data.yearEnd);
          }

          return true;
        }, 'Year start should be less than year end'),
      yearEnd: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.yearStart) {
            return Number(value) >= Number(data.yearStart);
          }

          return true;
        }, 'Year end should be greater than year start'),
      gender: StringType()
        .isRequired('Please fill the required field')
        .addRule((value) => {
          return _.includes(['M', 'F'], value);
        }),
      gpa: NumberType()
        .isRequired('Please fill the required field')
        .addRule(
          (value) => Number(value) >= 1 && Number(value) <= 4,
          'GPA should be in inclusive range of 1 to 4',
        ),
    });

    this.formTwoRef = React.createRef();
    this.formSchemaTwoModel = Schema.Model({
      yoe: NumberType().isRequired('Please fill the required field'),
      organization: StringType().isRequired('Please fill the required field'),
      seekingStatus: StringType().isRequired('Please fill the required field'),
      jobRole: ArrayType()
        .minLength(1)
        .maxLength(3)
        .isRequired('Please fill the required field'),
      jobLocation: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1),
      industry: ArrayType().isRequired('Please fill the required field'),
      salaryRange: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(2),
      cv: ArrayType()
        .minLength(0)
        .of(
          ObjectType().shape({
            name: StringType().isRequired(),
            blobFile: ObjectType().isRequired(),
          }),
        ),
      additionalFile: ArrayType()
        .minLength(0)
        .of(
          ObjectType().shape({
            name: StringType().isRequired(),
            blobFile: ObjectType().isRequired(),
          }),
        ),
      source: StringType().isRequired('Please fill the required field'),
    });
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getInformationSources();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getPhoneCodes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getLocation();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getDreamJobs();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getUniversities = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getUniversities(query);
      const data = _.get(response, 'data') || [];
      this.setState({ universities: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getMajors = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getMajors(query);
      const data = _.get(response, 'data') || [];
      this.setState({ majors: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getDreamJobs = async (
    query = '',
    cached: Array<any> = [],
  ): Promise<void> => {
    try {
      const response = await MiscAPI.getDreamJobs(query);
      const data = _.get(response, 'data') || [];
      this.setState({
        dreamJobs:
          cached.length > 0 ? _.uniqBy([...data, ...cached], 'title') : data,
      });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('COMPANY_INDUSTRY');
    const data = _.get(response, 'data') || [];
    this.setState({
      companyIndustries: data,
    });
  };

  private getInformationSources = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('INFO_SOURCE');
    const data = _.get(response, 'data') || [];
    this.setState({
      sources: data,
    });
  };

  private getPhoneCodes = async (): Promise<void> => {
    const url = `https://api.github.com/gists/75f6183458db8c453306f93521e93d37`;
    const response = await fetch(url);
    const json = await response.json();
    const data = JSON.parse(_.get(json, 'files["CountryCodes.json"].content'));

    this.setState({
      phoneCodes: _.uniqBy(data, 'dial_code'),
    });
  };

  private getLocation = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      const { location } = this.state;

      this.setState({
        location: _.uniqBy(location.concat(data), 'cityName'),
      });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onNextButtonClicked = (): void => {
    const { step } = this.state;
    const { t } = this.props;

    try {
      if (!_.invoke(this.formRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      this.setState({ step: step + 1 });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private uploadDocuments = async (): Promise<void> => {
    const { basicInfoTwo } = this.state;

    try {
      const cvBlob =
        _.map(_.get(basicInfoTwo, 'cv'), (f) => ({
          blobFile: _.get(f, 'blobFile'),
          name: 'cv.pdf',
        })) || [];
      const additionalBlobs =
        _.map(_.get(basicInfoTwo, 'additionalFile'), (f) => ({
          blobFile: _.get(f, 'blobFile'),
          name: 'additional_file.pdf',
        })) || [];
      const blobs = [...cvBlob, ...additionalBlobs];
      await Promise.map(
        blobs,
        async (blob) => {
          const base64 = await util.blobToBase64(blob.blobFile);
          const user = AuthStorage.getInstance().getUser();
          await DocumentAPI.upload({
            content: base64,
            table: 'App\\Models\\User',
            tableId: _.get(user, 'id'),
            filename: blob.name,
          });
        },
        { concurrency: 5 },
      );
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private onConfirmButtonClicked = async (): Promise<void> => {
    const { t, navigate } = this.props;

    try {
      if (!_.invoke(this.formTwoRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Upload documents
      this.uploadDocuments();

      // Save user preferences information
      const { basicInfo, basicInfoTwo } = this.state;
      const phoneRegion = _.get(basicInfo, 'phoneRegion');

      const payload = {
        ...basicInfo,
        ...basicInfoTwo,
        phoneRegion,
        phone: `${phoneRegion}${_.get(basicInfo, 'phone')}`,
      };

      const res = await UserAPI.updatePreference(payload);
      const user: any = _.get(res, 'data');

      const storage = AuthStorage.getInstance();
      storage.setUser(user);

      // Set toaster and navigate to homepage
      toaster.push(
        util.buildMessage(
          t(constant.translation.basicInfo.formSuccessConfirmActionKey),
        ),
      );

      navigate('/dashboard/candidate/home', { replace: true });
      window.location.reload();
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') ||
        _.get(response, 'message') ||
        _.get(e, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderStepTwo = (
    { t } = this.props,
    {
      step,
      location,
      companyIndustries,
      sources,
      dreamJobs,
      jobRoleCacheData,
    } = this.state,
  ): JSX.Element | null => {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="basic-info-container"
        style={{ display: step < 2 ? 'none' : 'block' }}
      >
        <Panel
          className="basic-info-panel"
          header={
            <div>
              <span>
                {t(
                  constant.translation.basicInfo
                    .pageCandidateWorkPreferenceTitleKey,
                )}
              </span>
              <small>
                {t(
                  constant.translation.basicInfo
                    .pageCandidateWorkPreferenceSubTitleKey,
                )}
              </small>
            </div>
          }
          bordered
        >
          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formOrganizationExpKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="organization"
                  placeholder="Ex: Financial Strategist at Talent Growth"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formYOEKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="yoe"
                  accepter={InputPicker}
                  data={_.range(0, 11).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Select the year"
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formJobSeekingStatusKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="seekingStatus"
                  accepter={InputPicker}
                  data={[
                    {
                      label: 'Actively looking for opportunities',
                      value: 'ACTIVELY_LOOKING',
                    },
                    {
                      label: 'Hired, but search for opportunities',
                      value: 'HIRED_OPEN',
                    },
                    {
                      label: 'Not open for opportunities',
                      value: 'NOT_OPEN',
                    },
                  ]}
                  placeholder="Select Status"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formJobRoleKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="jobRole"
                  accepter={TagPicker}
                  cacheData={jobRoleCacheData}
                  onSelect={(val) => {
                    const { jobRoleCacheData: cache } = this.state;
                    const cached = [...cache, ...val];
                    this.getDreamJobs(
                      '',
                      _.map(cached, (c) => ({ title: c })),
                    );

                    this.setState({
                      jobRoleCacheData: cached,
                    });
                  }}
                  onSearch={(keyword: string) => {
                    if (keyword !== '') {
                      this.getDreamJobs(keyword);
                    }
                  }}
                  data={_.map(dreamJobs, (c) => ({
                    label: c.title,
                    value: c.title,
                  }))}
                  placeholder="Your Dream Job"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formIndustryPrefKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="industry"
                  accepter={TagPicker}
                  data={_.map(companyIndustries, (c) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  placeholder="Choose Industry"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formJobLocationKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="jobLocation"
                  accepter={TagPicker}
                  data={_.map(location, (m) => ({
                    label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                    value: _.get(m, 'cityName'),
                  }))}
                  onSearch={(keyword: string) => {
                    if (keyword !== '') {
                      this.getLocation(keyword);
                    }
                  }}
                  onOpen={() => this.getLocation()}
                  renderMenu={(menu) => {
                    if (location.length === 0) {
                      return (
                        <p
                          style={{
                            padding: 4,
                            color: '#999',
                            textAlign: 'center',
                          }}
                        >
                          <Loader /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                  size="sm"
                  placeholder="Type Your Location"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formSalaryRangeKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="salaryRange"
                  accepter={RangeSlider}
                  min={500000}
                  step={500000}
                  max={50500000}
                  renderTooltip={(value: number | undefined) =>
                    util.formatCurrency(value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCVKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="cv"
                  multiple={false}
                  // eslint-disable-next-line max-len
                  accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  accepter={Uploader}
                  fileList={_.get(this.state, 'basicInfoTwo.cv')}
                  draggable
                  action="#"
                  maxPreviewFileSize={2097152}
                  autoUpload={false}
                >
                  <div
                    style={{
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '12px',
                    }}
                  >
                    <span>
                      Drag & drop your files here or <a href="#">choose file</a>
                      <br />
                      <br />
                      <small>2 MB max file size (*.PDF, *.doc, *.docx)</small>
                    </span>
                  </div>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formAdditionalFilesKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="additionalFile"
                  multiple={false}
                  accepter={Uploader}
                  autoUpload={false}
                  maxPreviewFileSize={2097152}
                  action="#"
                  draggable
                >
                  <div
                    style={{
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '12px',
                    }}
                  >
                    <span>
                      Drag & drop your files here or <a href="#">choose file</a>
                      <br />
                      <br />
                      <small>2 MB max file size (All type of files)</small>
                    </span>
                  </div>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formHowDoYouKnowTGKey)}
                </Form.ControlLabel>
                {/* <Form.Control name="source"\
                  placeholder="Ex: Social Media" /> */}
                <Form.Control
                  name="source"
                  accepter={InputPicker}
                  data={_.map(sources, (c) => ({
                    label: c.name,
                    value: c.name,
                  }))}
                  placeholder="Select Source"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          <br />

          <Button
            className="btn-tg-primary"
            block
            appearance="primary"
            onClick={this.onConfirmButtonClicked}
          >
            {t(constant.translation.general.formConfirmButtonKey)}
          </Button>
        </Panel>
      </FlexboxGrid>
    );
  };

  renderStepOne(
    { t } = this.props,
    { phoneCodes, step, universities, majors } = this.state,
  ): JSX.Element | null {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="basic-info-container"
        style={{ display: step > 1 ? 'none' : 'block' }}
      >
        <Panel
          className="basic-info-panel"
          header={
            <div>
              <span>
                {t(constant.translation.basicInfo.pageCandidateTitleKey)}
              </span>
              <small>
                {t(constant.translation.basicInfo.pageCandidateSubTitleKey)}
              </small>
            </div>
          }
          bordered
        >
          <Row>
            <Col xs={24} md={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formWhatsappNumberKey)}
                </Form.ControlLabel>
                <Row>
                  <Col xs={6} sm={8}>
                    <Form.Control
                      name="phoneRegion"
                      placeholder="+62"
                      defaultValue="+62"
                      size="sm"
                      accepter={SelectPicker}
                      appearance="default"
                      data={_.map(phoneCodes, (ph) => ({
                        label: _.get(ph, 'dial_code'),
                        value: _.get(ph, 'dial_code'),
                      }))}
                      className="phone-prefix"
                    />
                  </Col>
                  <Col xs={18} sm={16}>
                    <Form.Control name="phone" placeholder="812345890001" />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col xs={24} md={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formGenderKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="gender"
                  accepter={InputPicker}
                  data={[
                    {
                      label: 'Male',
                      value: 'M',
                    },
                    {
                      label: 'Female',
                      value: 'F',
                    },
                  ]}
                  placeholder="Select"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Form.Group>
              <Col xs={24}>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCollegePeriodKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="yearStart"
                  accepter={InputPicker}
                  data={_.range(2000, Number(moment().format('Y')) + 1).map(
                    (value) => ({
                      label: String(value),
                      value,
                    }),
                  )}
                  placeholder="Year Start"
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="yearEnd"
                  accepter={InputPicker}
                  data={_.range(
                    _.get(this.state, 'basicInfo.yearStart') !== undefined &&
                      _.get(this.state, 'basicInfo.yearStart') !== null
                      ? Number(_.get(this.state, 'basicInfo.yearStart'))
                      : 2020,
                    Number(moment().format('Y')) + 5,
                  ).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Year End"
                />
              </Col>
            </Form.Group>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formUniversityKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="university"
                  placeholder="University Name"
                  size="sm"
                  accepter={SelectPicker}
                  appearance="default"
                  data={_.map(universities, (uni) => ({
                    label: _.get(uni, 'name'),
                    value: _.get(uni, 'name'),
                  }))}
                  onOpen={() => {
                    if (universities.length === 0) {
                      this.getUniversities();
                    }
                  }}
                  onSearch={(keyword: string) => {
                    if (keyword !== '') {
                      this.getUniversities(keyword);
                    }
                  }}
                  renderMenu={(menu) => {
                    if (universities.length === 0) {
                      return (
                        <p
                          style={{
                            padding: 4,
                            color: '#999',
                            textAlign: 'center',
                          }}
                        >
                          <Loader /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formEnglishProficiencyKey)}
                  <Whisper
                    placement="top"
                    trigger="hover"
                    speaker={
                      <Tooltip style={{ textAlign: 'left' }}>
                        <div>
                          <p>I can&lsquo;t speak English</p>
                          You can&lsquo;t speak or write in English at all.
                        </div>
                        <br />
                        <div>
                          <p>Basic</p>- You can write but with still with a lot
                          of grammatical errors. <br /> - You can speak and
                          understand basic conversational phrases.
                        </div>
                        <br />
                        <div>
                          <p>
                            Intermediate (minimum English proficiency required
                            for international startups)
                          </p>
                          - You can understand main points on familiar matters.
                          <br /> - You can discuss certain topics and express
                          your opinions.
                        </div>
                        <br />
                        <div>
                          <p>
                            Advanced (English proficiency required for
                            international startups)
                          </p>
                          - You can communicate flexibly without any difficulty
                          in English.
                          <br /> - You can understand every business and social
                          context in English and express your opinions
                          accurately.
                        </div>
                      </Tooltip>
                    }
                    followCursor
                  >
                    <IconButton
                      icon={<Icon as={FaInfoCircle} />}
                      style={{
                        color: 'var(--rs-primary-500)',
                        paddingTop: '8px',
                        background: 'transparent',
                      }}
                    />
                  </Whisper>
                </Form.ControlLabel>
                <Form.Control
                  name="englishProficiency"
                  placeholder="Select your level"
                  size="sm"
                  accepter={SelectPicker}
                  appearance="default"
                  data={_.map(
                    [
                      {
                        name: "I can't speak English",
                        value: 'NO_BASIC',
                      },
                      {
                        name: 'Basic',
                        value: 'BASIC',
                      },
                      {
                        name: 'Intermediate',
                        value: 'INTERMEDIATE',
                      },
                      {
                        name: 'Advanced',
                        value: 'ADVANCED',
                      },
                    ],
                    (l) => ({
                      label: _.get(l, 'name'),
                      value: _.get(l, 'value'),
                    }),
                  )}
                  searchable={false}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formMajorKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="major"
                  placeholder="Your major"
                  size="sm"
                  accepter={SelectPicker}
                  appearance="default"
                  data={_.map(majors, (m) => ({
                    label: _.get(m, 'name'),
                    value: _.get(m, 'name'),
                  }))}
                  onOpen={() => {
                    if (majors.length === 0) {
                      this.getMajors();
                    }
                  }}
                  onSearch={(keyword: string) => {
                    if (keyword !== '') {
                      this.getMajors(keyword);
                    }
                  }}
                  renderMenu={(menu) => {
                    if (majors.length === 0) {
                      return (
                        <p
                          style={{
                            padding: 4,
                            color: '#999',
                            textAlign: 'center',
                          }}
                        >
                          <Loader /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formGPAKey)}
                </Form.ControlLabel>
                <Form.Control
                  accepter={InputNumber}
                  max={4}
                  min={1}
                  name="gpa"
                  step={0.1}
                  placeholder="GPA"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          <br />

          <Button
            className="btn-tg-primary"
            block
            appearance="primary"
            onClick={this.onNextButtonClicked}
          >
            {t(constant.translation.general.formNextButtonKey)}
          </Button>
        </Panel>
      </FlexboxGrid>
    );
  }

  render({ step } = this.state): JSX.Element | null {
    return (
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={24} xs={24}>
          {step > 1 && (
            <a
              className="back-btn"
              onClick={() => this.setState({ step: step - 1 })}
              style={{
                position: 'absolute',
                top: '56%',
                left: '6%',
                cursor: 'pointer',
              }}
            >
              <FaChevronLeft />
              <span>Go Back</span>
            </a>
          )}
          <Steps current={step - 1}>
            <Steps.Item title="Basic Information" />
            <Steps.Item title="Work Preference" />
          </Steps>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} xs={24}>
          <Form
            key="basic-info-form"
            ref={this.formRef}
            model={this.formSchemaModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'basicInfo')}
            formValue={_.get(this.state, 'basicInfo')}
            fluid
          >
            {this.renderStepOne()}
          </Form>

          <Form
            key="basic-info-page-two-form"
            ref={this.formTwoRef}
            model={this.formSchemaTwoModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'basicInfoTwo')}
            formValue={_.get(this.state, 'basicInfoTwo')}
            fluid
          >
            {this.renderStepTwo()}
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(CandidateBasicInfoScreen));
