import Http from '@services/httpClient';
import _ from 'lodash';

const prefix = '/api/v1/companies';

export default {
  searchJobs(query: string | null, filter: object, page: number, limit = 9) {
    return new Promise((resolve, reject) => {
      let url = `${prefix}/jobs?page=${page}&limit=${limit}`;
      if (!_.isEmpty(query)) {
        url += `&query=${query}`;
      }

      if (!_.isEmpty(filter)) {
        const jobTypes = _.get(filter, 'jobTypes') || [];
        const salary = _.get(filter, 'salary') || [];
        const locationId = _.get(filter, 'locationId') || -1;
        const workingTypes = _.get(filter, 'workingTypes') || [];
        const expLevels = _.get(filter, 'expLevels') || [];

        if (jobTypes.length > 0) {
          _.each(jobTypes, (jobType) => {
            url += `&job_types[]=${jobType}`;
          });
        }

        if (workingTypes.length > 0) {
          _.each(workingTypes, (workingType) => {
            url += `&working_types[]=${workingType}`;
          });
        }

        if (salary.length > 0) {
          if (salary[0] !== 500000 && salary[1] !== 500000) {
            url += `&salary_min=${salary[0]}`;
            url += `&salary_max=${salary[1]}`;
          }
        }

        if (expLevels.length > 0) {
          let min = 99;
          let max = -99;

          _.each(expLevels, (lvl) => {
            if (lvl === 'noexp') {
              if (min > 0) {
                min = 0;
              }

              if (max > 0) {
                max = 0;
              }
            }

            if (lvl === 'junior') {
              if (min > 1) {
                min = 1;
              }

              if (max < 3) {
                max = 3;
              }
            }

            if (lvl === 'mid') {
              if (min > 4) {
                min = 4;
              }

              if (max < 5) {
                max = 5;
              }
            }

            if (lvl === 'senior') {
              min = 5;
              max = 99;
            }
          });

          url += `&exp_level_min=${min}&exp_level_max=${max}`;
        }

        if (locationId > -1) {
          url += `&location_id=${locationId}`;
        }
      }

      Http.get({ url })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  searchCompanies(
    query: string | null,
    page: number,
    filterCompany: string,
    limit = 9,
  ) {
    return new Promise((resolve, reject) => {
      let url = `${prefix}?page=${page}&limit=${limit}`;
      if (!_.isEmpty(query)) {
        url += `&query=${query}`;
      }

      if (filterCompany.length > 0) {
        url += `&filter=${filterCompany}`;
      }

      Http.get({ url })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  companyListByPreference(page = 1, limit = 9) {
    return new Promise((resolve, reject) => {
      Http.get({
        url: `${prefix}/list-by-preference?page=${page}&limit=${limit}`,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  companyListByIndustry(
    industryId: number | Array<number>,
    page: number,
    filterCompany: string,
    query: string | null = null,
    limit = 9,
  ) {
    return new Promise((resolve, reject) => {
      let url = `${prefix}/list-by-industry?page=${page}&limit=${limit}`;

      if (industryId instanceof Array<number>) {
        for (let $i = 0; $i < industryId.length; $i += 1) {
          const elmt = _.get(industryId, $i);

          url = `${url}&industry_ids[]=${elmt}`;
        }
      } else {
        url = `${url}&industry_ids[]=${industryId}`;
      }

      if (!_.isEmpty(query)) {
        url = `${url}&query=${query}`;
      }

      if (filterCompany.length > 0) {
        url += `&filter=${filterCompany}`;
      }

      Http.get({ url })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  jobListByIndustry(
    industryId: number | Array<number>,
    categoryId: number,
    filter: object,
    page: number,
    query: string | null = null,
    limit = 9,
  ) {
    return new Promise((resolve, reject) => {
      let url = `${prefix}/list-jobs-by-industry?page=${page}&limit=${limit}`;

      if (industryId instanceof Array<number>) {
        for (let $i = 0; $i < industryId.length; $i += 1) {
          const elmt = _.get(industryId, $i);

          url = `${url}&industry_ids[]=${elmt}`;
        }
      } else {
        url = `${url}&industry_ids[]=${industryId}`;
      }

      if (categoryId > 0) {
        url = `${url}&category_id=${categoryId}`;
      }

      if (!_.isEmpty(query)) {
        url = `${url}&query=${query}`;
      }

      if (!_.isEmpty(filter)) {
        const jobTypes = _.get(filter, 'jobTypes') || [];
        const salary = _.get(filter, 'salary') || [];
        const locationId = _.get(filter, 'locationId') || -1;
        const workingTypes = _.get(filter, 'workingTypes') || [];
        const expLevels = _.get(filter, 'expLevels') || [];

        if (jobTypes.length > 0) {
          _.each(jobTypes, (jobType) => {
            url += `&job_types[]=${jobType}`;
          });
        }

        if (locationId > -1) {
          url += `&location_id=${locationId}`;
        }

        if (workingTypes.length > 0) {
          _.each(workingTypes, (workingType) => {
            url += `&working_types[]=${workingType}`;
          });
        }

        if (salary.length > 0) {
          url += `&salary_min=${salary[0]}`;
          url += `&salary_max=${salary[1]}`;
        }

        if (expLevels.length > 0) {
          let min = 99;
          let max = -99;

          _.each(expLevels, (lvl) => {
            if (lvl === 'noexp') {
              if (min > 0) {
                min = 0;
              }

              if (max > 0) {
                max = 0;
              }
            }

            if (lvl === 'junior') {
              if (min > 1) {
                min = 1;
              }

              if (max < 3) {
                max = 3;
              }
            }

            if (lvl === 'mid') {
              if (min > 4) {
                min = 4;
              }

              if (max < 5) {
                max = 5;
              }
            }

            if (lvl === 'senior') {
              min = 5;
              max = 99;
            }
          });

          url += `&exp_level_min=${min}&exp_level_max=${max}`;
        }
      }

      Http.get({ url })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getJobRecommendation(
    industryId: string | number | null,
    limit = 9,
    page = 1,
  ) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line max-len
      const url = `${prefix}/jobs-recommendation?page=${page}&limit=${limit}&industry_id=${
        industryId || ''
      }&random=true`;

      Http.get({ url })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getJobDetails(id: string | number) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/jobs/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getCompanyDetails(id: string | number) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `${prefix}/details/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateInfo(id: string | number, payload: object, attributes: object) {
    return new Promise((resolve, reject) => {
      let data = Object.keys(payload)
        .map((key) => {
          const snaked = _.snakeCase(key);
          return `${snaked}=${encodeURIComponent(_.get(payload, key))}`;
        })
        .join('&');
      const attrbs = [
        ..._.get(attributes, 'benefits', []),
        ..._.get(attributes, 'workingTypes', []),
        ..._.get(attributes, 'cultures', []),
      ];
      _.each(attrbs, (at) => {
        const attributeId = Number(at);
        data += `&attributes[checkbox-${attributeId}]=${attributeId}`;
      });

      Http.put({
        url: `/dashboard/companies/${id}`,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  addPosting(payload: object, attributes: object) {
    return new Promise((resolve, reject) => {
      let data = Object.keys(payload)
        .map((key) => {
          if (key === 'empStatus') {
            return `${key}=${encodeURIComponent(_.get(payload, key) || '')}`;
          }

          const snaked = _.snakeCase(key);
          return `${snaked}=${encodeURIComponent(_.get(payload, key))}`;
        })
        .join('&');

      const attrbs = [_.get(attributes, 'workingType')];
      _.each(attrbs, (at) => {
        const attributeId = Number(at);
        data += `&attributes[checkbox-${attributeId}]=${attributeId}`;
      });

      Http.post({
        url: `/dashboard/jobs`,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  editPosting(id: number | string, payload: object, attributes: object) {
    return new Promise((resolve, reject) => {
      let data = Object.keys(payload)
        .map((key) => {
          if (key === 'empStatus') {
            return `${key}=${encodeURIComponent(_.get(payload, key) || '')}`;
          }

          const snaked = _.snakeCase(key);
          return `${snaked}=${encodeURIComponent(_.get(payload, key))}`;
        })
        .join('&');

      const attrbs = [_.get(attributes, 'workingType')];
      _.each(attrbs, (at) => {
        const attributeId = Number(at);
        data += `&attributes[checkbox-${attributeId}]=${attributeId}`;
      });

      Http.put({
        url: `/dashboard/jobs/${id}`,
        data,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  updateJobPosting(id: number | string, data: object) {
    return new Promise((resolve, reject) => {
      Http.put({
        url: `${prefix}/jobs/${id}`,
        data,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
