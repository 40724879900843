import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  Panel,
  Schema,
  toaster,
} from 'rsuite';
import { constant, util } from '@helper';
import _ from 'lodash';
import { ForgotPasswordPayload } from '@common/ApiTypes';
import constants from '@helper/constants';
import { FaArrowLeft } from 'react-icons/fa';
import { AuthAPI } from '@services/api';

const { withRouter } = util;
const { StringType } = Schema.Types;

type State = {
  forgotPass: ForgotPasswordPayload;
};

class ForgotPasswordScreen extends React.Component<any, State> {
  formForgotPassRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formForgotSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      forgotPass: {
        email: '',
      },
    };

    this.formForgotPassRef = React.createRef();
    this.formForgotSchemaModel = Schema.Model({
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }
  }

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onSendResetPassword = async (): Promise<void> => {
    const { t, navigate } = this.props;

    if (!_.invoke(this.formForgotPassRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.profile.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { forgotPass: payload } = this.state;
      await AuthAPI.forgotPassword(payload);

      toaster.push(
        util.buildMessage(
          t(constant.translation.profile.formSuccessSendResetPassKey),
        ),
      );

      navigate('/resend-password-link', {
        replace: true,
        state: { email: payload.email },
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    } finally {
      this.setState({
        forgotPass: {
          email: '',
        },
      });
    }
  };

  render({ t } = this.props): JSX.Element | null {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="forgot-pass-container"
      >
        <FlexboxGrid.Item as={Col} colspan={8} xs={24}>
          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />

          <FlexboxGrid.Item as={Col} xs={22} sm={16} md={14} lg={8}>
            <img src="bg-forgot-pass.png" alt="bg" />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={8} xs={24} sm={24} md={16} lg={8}>
          <Panel
            className="forgot-pass-panel"
            header={
              <div>
                <span>
                  {t(constant.translation.forgotPass.pageCandidateTitleKey)}
                </span>
                <small>
                  {t(constant.translation.forgotPass.pageCandidateSubTitleKey)}
                </small>
              </div>
            }
            bordered
          >
            <Form
              key="forgot-pass-form"
              ref={this.formForgotPassRef}
              model={this.formForgotSchemaModel}
              onChange={(v: object) => this.onFormValueChanged(v, 'forgotPass')}
              formValue={_.get(this.state, 'forgotPass')}
              fluid
            >
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formEmailKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="email"
                  placeholder="Enter your email address"
                />
              </Form.Group>

              <Button
                className="btn-tg-primary"
                block
                appearance="primary"
                onClick={this.onSendResetPassword}
              >
                {t(constant.translation.general.formForgotPasswordButtonKey)}
              </Button>

              <br />
              <p className="back-to-login-section">
                <a href="/login">
                  <FaArrowLeft /> Back to login
                </a>
              </p>
              <br />
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(ForgotPasswordScreen));
