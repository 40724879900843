import React from 'react';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { constant, util } from '@helper';
import {
  Affix,
  Avatar,
  Badge,
  Button,
  Col,
  Form,
  FormInstance,
  Grid,
  IconButton,
  Input,
  InputPicker,
  Loader,
  Nav,
  Panel,
  Progress,
  Row,
  Schema,
  SelectPicker,
  Stack,
  TagPicker,
  Tooltip,
  Uploader,
  Whisper,
  toaster,
} from 'rsuite';
import {
  FaBuilding,
  FaDownload,
  FaFileAlt,
  FaHome,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaSuitcase,
  FaUser,
} from 'react-icons/fa';
import AuthStorage from '@common/services/authStorage';
import { CompanyAPI, DocumentAPI, MiscAPI, UserAPI } from '@services/api';
import { Promise } from 'bluebird';
import { FileType } from 'rsuite/esm/Uploader';
import { Icon } from '@rsuite/icons';
import moment from 'moment';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';

const { withRouter } = util;

type State = {
  profile: object;
  preference: object;
  myDocuments: object;

  showpreferenceSaveBtn: boolean;
  showprofileSaveBtn: boolean;

  phoneCodes: Array<object>;
  workingTypes: Array<object>;
  companyIndustries: Array<object>;
  jobTypes: Array<object>;
  softSkills: Array<any>;
  dreamJobs: Array<any>;
  universities: Array<any>;
  majors: Array<any>;
  locations: Array<any>;

  softSkillCacheData: Array<any>;
  dreamJobsCacheData: Array<any>;
  userDefaultSoftSkills: Array<any>;

  resultsByPreference: Array<any>;

  loadingSoftSkills: boolean;
  loadingDreamJobs: boolean;
  loadingJobCards: boolean;

  user: object | null;
  steps: Array<any>;

  tabKey: string;
};

const { StringType, ArrayType, NumberType } = Schema.Types;

const Textarea = React.forwardRef((props, ref: any) => (
  <Input {...props} as="textarea" ref={ref} />
));

class CandidateProfileScreen extends React.Component<any, State> {
  formProfileRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formProfileSchemaModel: any;

  formPreferenceRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formPreferenceSchemaModel: any;

  formUploaderCVRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  constructor(props: any) {
    super(props);

    const storage = AuthStorage.getInstance();
    const user = storage.getUser();

    this.state = {
      profile: {
        phone: String(_.get(user, 'phone')).replace(
          String(_.get(user, 'phoneRegion')),
          '',
        ),
        phoneRegion: String(_.get(user, 'phoneRegion')),
      },
      preference: {},
      myDocuments: {},

      user,
      steps: [],
      softSkillCacheData: [],
      dreamJobsCacheData: [],

      resultsByPreference: [],

      loadingSoftSkills: false,
      loadingDreamJobs: false,
      loadingJobCards: true,

      phoneCodes: [],
      workingTypes: [],
      jobTypes: [],
      companyIndustries: [],
      softSkills: [],
      dreamJobs: [],
      universities: [],
      majors: [],
      locations: [],

      userDefaultSoftSkills: String(_.get(user, 'profile.softSkills'))
        .split(',')
        .map((v: string) => v),

      showpreferenceSaveBtn: false,
      showprofileSaveBtn: false,

      tabKey: 'basic',
    };

    this.formProfileRef = React.createRef();
    this.formProfileSchemaModel = Schema.Model({
      // email: StringType()
      //   .isEmail('Invalid email address format')
      //   .isRequired('Please fill the required field'),
      phoneRegion: StringType().isRequired('Please fill the required field'),
      phone: StringType()
        .addRule((value) => {
          const pattern =
            /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{2,8}$/g;
          return new RegExp(pattern).test(value);
        }, 'Your phone number pattern is not correct')
        .isRequiredOrEmpty(),
      university: StringType().isRequired('Please fill the required field'),
      major: StringType().isRequired('Please fill the required field'),
    });

    this.formPreferenceRef = React.createRef();
    this.formPreferenceSchemaModel = Schema.Model({
      jobRole: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1),
      yoe: NumberType().isRequired('Please fill the required field'),
      organization: StringType().isRequired('Please fill the required field'),
      seekingStatus: StringType().isRequired('Please fill the required field'),
      industry: ArrayType().isRequired('Please fill the required field'),
      workingType: ArrayType().isRequired('Please fill the required field'),
      jobLocation: ArrayType()
        .minLength(1)
        .maxLength(3)
        .isRequired('Please fill the required field'),
      jobType: ArrayType().isRequired('Please fill the required field'),
    });

    this.formUploaderCVRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const storage = AuthStorage.getInstance();
    const user = storage.getUser();
    const keyword = _.get(user, 'profile.university');
    const keywordMajor = _.get(user, 'profile.major');

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getJobTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getSoftSkills();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getDreamJobs();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getUniversities(keyword);
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          const defaultLocs =
            _.split(_.get(user, 'preference.jobLocation'), ';') || [];
          Promise.map(defaultLocs, async (l) => {
            await this.getLocation(l);
          });
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getMajors(keywordMajor);
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getInterviewSteps();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getJobRecommendation();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getUserProfile();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
    this.getPhoneCodes();
  }

  private getLocation = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      const { locations } = this.state;

      this.setState({
        locations: _.uniqBy(locations.concat(data), 'cityName'),
      });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getUserProfile = async (): Promise<void> => {
    try {
      const res: object = await UserAPI.getProfile();
      const u: any = _.get(res, 'data');
      const storage = AuthStorage.getInstance();
      storage.setUser(u);

      this.setState({ user: u });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getJobRecommendation = async (page = 1): Promise<void> => {
    try {
      const res = await CompanyAPI.getJobRecommendation(null, 6, page);
      const data: Array<any> = _.get(res, 'data') || [];

      this.setState({
        resultsByPreference: data,
        loadingJobCards: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getSoftSkills = async (
    query = '',
    cached: Array<any> = [],
  ): Promise<void> => {
    this.setState({ loadingSoftSkills: true });
    const response = await MiscAPI.getSoftSkills(query);
    const data = _.get(response, 'data') || [];

    this.setState({
      softSkills:
        cached.length > 0 ? _.uniqBy([...data, ...cached], 'name') : data,
      loadingSoftSkills: false,
    });
  };

  private getDreamJobs = async (
    query = '',
    cached: Array<any> = [],
  ): Promise<void> => {
    this.setState({ loadingDreamJobs: true });
    const response = await MiscAPI.getDreamJobs(query);
    const data = _.get(response, 'data') || [];

    this.setState({
      dreamJobs:
        cached.length > 0 ? _.uniqBy([...data, ...cached], 'title') : data,
      loadingDreamJobs: false,
    });
  };

  private getInterviewSteps = async (): Promise<void> => {
    const response = await MiscAPI.getInterviewSteps();
    const data = _.get(response, 'data') || [];

    this.setState({
      steps: data,
    });
  };

  private getPhoneCodes = async (): Promise<void> => {
    try {
      // eslint-disable-next-line max-len
      const url = `https://api.github.com/gists/75f6183458db8c453306f93521e93d37`;
      const response = await fetch(url);
      const json = await response.json();
      const data = JSON.parse(
        _.get(json, 'files["CountryCodes.json"].content'),
      );

      this.setState({
        phoneCodes: _.uniqBy(data, 'dial_code'),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  private getWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('COMPANY_INDUSTRY');
    const data = _.get(response, 'data') || [];
    this.setState({
      companyIndustries: data,
    });
  };

  private getJobTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      jobTypes: data,
    });
  };

  private getUniversities = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getUniversities(query);
      const data = _.get(response, 'data') || [];
      this.setState({ universities: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getMajors = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getMajors(query);
      const data = _.get(response, 'data') || [];
      this.setState({ majors: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private onTabChanged = (tabKey: string): void => {
    this.setState({ tabKey });
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [`show${key}SaveBtn`]: true,
      [key]: value,
    }));

  private uploadDocuments = async (
    files: FileType[],
    type: string,
  ): Promise<void> => {
    const filtered = _.filter(files, (file) => {
      const o = file.valueOf();
      const status = _.get(o, 'status');
      const name = String(_.get(o, 'name'));
      const isCurrent =
        name.includes('cv_file') && name.includes('additional_file');

      return status === 'inited' && !isCurrent;
    });

    if (filtered.length > 0) {
      const blob = {
        blobFile: _.get(filtered, '0.blobFile'),
        name: `${type}_file.pdf`,
      };

      if (Number(_.get(blob.blobFile, 'size')) > 2097152) {
        toaster.push(
          util.buildErrorMessage(
            'File size is bigger than maximum file size: 2MB!',
          ),
        );

        return;
      }

      const base64 = await util.blobToBase64(blob.blobFile);
      const storage = AuthStorage.getInstance();
      const user = storage.getUser();

      try {
        await DocumentAPI.upload({
          content: base64,
          table: 'App\\Models\\User',
          tableId: _.get(user, 'id'),
          filename: blob.name,
        });

        toaster.push(util.buildMessage('Successfully upload documents!'));

        // Get user profile
        const res: object = await UserAPI.getProfile();
        const u: any = _.get(res, 'data');
        storage.setUser(u);

        this.setState({ user: u });
      } catch (e: any) {
        // Throw pop-up error
        const response = _.get(e, 'response.data');
        const errors: string | Array<any> | any =
          _.get(response, 'errors') || _.get(response, 'message');
        let message;

        if (_.isArray(errors)) {
          message = errors.join('\n');
        } else {
          message = errors;
        }

        toaster.push(util.buildErrorMessage(message));
      }
    }
  };

  private uploadLogo = async (files: FileType[]): Promise<void> => {
    const filtered = _.filter(files, (file) => {
      const o = file.valueOf();
      const status = _.get(o, 'status');

      return status === 'inited';
    });

    if (filtered.length > 0) {
      const blob = {
        blobFile: _.get(filtered, '0.blobFile'),
        name: 'avatar.png',
      };

      const base64 = await util.blobToBase64(blob.blobFile);
      const storage = AuthStorage.getInstance();
      const user = storage.getUser();

      try {
        await DocumentAPI.upload({
          content: base64,
          table: 'App\\Models\\User',
          tableId: _.get(user, 'id'),
          filename: blob.name,
        });

        toaster.push(
          util.buildMessage('Your profile picture has been changed!'),
        );

        // Get user profile
        const res: object = await UserAPI.getProfile();
        const u: any = _.get(res, 'data');
        storage.setUser(u);

        this.setState({ user: u });

        // Reload once again
        window.location.reload();
      } catch (e: any) {
        // Throw pop-up error
        const response = _.get(e, 'response.data');
        const errors: string | Array<any> | any =
          _.get(response, 'errors') || _.get(response, 'message');
        let message;

        if (_.isArray(errors)) {
          message = errors.join('\n');
        } else {
          message = errors;
        }

        toaster.push(util.buildErrorMessage(message));
      }
    }
  };

  private saveProfile = async (): Promise<void> => {
    const { t } = this.props;

    try {
      const profile = _.get(this.state, 'profile');
      const preference = _.get(this.state, 'preference');
      const user = _.get(this.state, 'user');

      const isPhoneFilled =
        _.get(profile, 'phoneRegion') && _.get(profile, 'phone');
      const phone = isPhoneFilled
        ? `${_.get(profile, 'phoneRegion')}${_.get(profile, 'phone')}`
        : _.get(user, 'phone');

      const payload = {
        englishProficiency:
          _.get(profile, 'englishProficiency') ||
          _.get(user, 'profile.englishProficiency') ||
          'BASIC',
        university:
          _.get(profile, 'university') || _.get(user, 'profile.university'),
        major: _.get(profile, 'major') || _.get(user, 'profile.major'),
        phone,
        name: _.get(profile, 'name') || _.get(user, 'name'),
        phoneRegion:
          _.get(profile, 'phoneRegion') || _.get(user, 'phoneRegion'),
        yearStart: _.get(user, 'profile.yearStart'),
        yearEnd: _.get(user, 'profile.yearEnd'),
        gender: _.get(user, 'profile.gender'),
        gpa: _.get(user, 'profile.gpa'),
        userPreferenceId: _.get(user, 'preference.id'),
        userProfileId: _.get(user, 'profile.id'),
        linkedinUrl:
          _.get(profile, 'linkedin') || _.get(user, 'profile.linkedinUrl'),
        yoe: _.get(preference, 'yoe') || _.get(user, 'profile.yoe'),
        organization:
          _.get(preference, 'organization') ||
          _.get(user, 'profile.organization'),
        seekingStatus:
          _.get(preference, 'seekingStatus') ||
          _.get(user, 'preference.seekingStatus'),
        jobLocation: _.get(user, 'preference.jobLocation'),
        salaryRange: [
          _.get(user, 'preference.salaryLowRange'),
          _.get(user, 'preference.salaryHighRange'),
        ],
      };

      if (_.isUndefined(_.get(profile, 'softSkills'))) {
        _.set(
          payload,
          'softSkills',
          String(_.get(user, 'profile.softSkills'))
            .split(',')
            .map((v: string) => v) || [],
        );
      } else {
        _.set(payload, 'softSkills', _.get(profile, 'softSkills') || []);
      }

      if (_.isUndefined(_.get(preference, 'jobRole'))) {
        _.set(
          payload,
          'jobRole',
          String(_.get(user, 'preference.jobRoles'))
            .split(',')
            .map((v: string) => v),
        );
      } else {
        _.set(payload, 'jobRole', _.get(preference, 'jobRole') || []);
      }

      if (_.isUndefined(_.get(preference, 'jobLocation'))) {
        _.set(payload, 'jobLocation', _.get(user, 'preference.jobLocation'));
      } else {
        _.set(payload, 'jobLocation', _.get(preference, 'jobLocation') || '');
      }

      const jobLocation = _.get(payload, 'jobLocation');
      const splitJobLocation = _.split(jobLocation, ';');
      if (splitJobLocation.length > 1) {
        _.set(payload, 'jobLocation', splitJobLocation);
      }

      const defaultPreferences = _.map(
        _.get(user, 'workingPreferences'),
        (p: any) => p.id,
      );
      let industry: any[] = [];

      if (_.isUndefined(_.get(preference, 'industry'))) {
        industry = industry.concat(
          _.filter(_.get(user, 'workingPreferences'), {
            type: 'COMPANY_INDUSTRY',
          }).map((p: any) => p.id),
        );
      } else {
        industry = industry.concat(_.get(preference, 'industry'));
      }

      if (_.isUndefined(_.get(preference, 'workingType'))) {
        industry = industry.concat(
          _.filter(_.get(user, 'workingPreferences'), {
            type: 'WORKING_TYPE',
          }).map((p: any) => p.id),
        );
      } else {
        industry = industry.concat(_.get(preference, 'workingType'));
      }

      if (_.isUndefined(_.get(preference, 'jobType'))) {
        industry = industry.concat(
          _.filter(_.get(user, 'workingPreferences'), {
            type: 'EMPLOYMENT_STATUS',
          }).map((p: any) => p.id),
        );
      } else {
        industry = industry.concat(_.get(preference, 'jobType'));
      }

      const diff = _.difference(defaultPreferences, industry);

      _.set(payload, 'removedIndustry', diff);
      _.set(payload, 'industry', industry);

      const res = await UserAPI.updatePreference(payload);
      const u: any = _.get(res, 'data');

      const storage = AuthStorage.getInstance();
      storage.setUser(u);

      this.setState({
        user: u,
        showprofileSaveBtn: false,
        showpreferenceSaveBtn: false,
      });

      toaster.push(
        util.buildMessage(
          t(constant.translation.profile.message.updateProfileSuccess),
        ),
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={6} md={6} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/search-companies',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/my-application',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderBasicProfile = (): JSX.Element => {
    const {
      phoneCodes,
      showprofileSaveBtn,
      user,
      softSkills,
      softSkillCacheData,
      userDefaultSoftSkills,
      universities,
      majors,
      profile,
    } = this.state;
    const { t } = this.props;

    const name = _.get(user, 'name');

    return (
      <Form
        key="profile-form"
        ref={this.formProfileRef}
        model={this.formProfileSchemaModel}
        onChange={(v: object) => this.onFormValueChanged(v, 'profile')}
        formValue={_.get(this.state, 'profile')}
        fluid
      >
        <Row>
          <Col xs={6} lg={5}>
            <Uploader
              fileListVisible={false}
              listType="picture"
              action="#"
              accept="image/*"
              onChange={this.uploadLogo}
              autoUpload={false}
              className="tg-img-uploader"
            >
              <Avatar
                size="lg"
                circle
                src={`${_.get(
                  user,
                  'avatar.url',
                  // eslint-disable-next-line max-len
                  'https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png',
                )}`}
                alt="user-profile-avatar"
              />
            </Uploader>
            <p className="click-to-edit">Click to Edit</p>
          </Col>
          <Col xs={18} lg={19}>
            <h2 style={{ margin: '0px' }}>{name}</h2>
            <Badge
              color="blue"
              className="badge-seeking-status"
              content={util.mapSeekingStatus(
                String(_.get(user, 'preference.seekingStatus')),
              )}
            />
            <div className="profile-location">
              <FaMapMarkerAlt />
              <p>{_.get(user, 'preference.jobLocation')}</p>
            </div>
          </Col>
        </Row>
        <h3>{t(constant.translation.profile.pageCandidateSubTitleKey)}</h3>
        <hr />
        <br />
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Full Name</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              defaultValue={name}
              name="name"
              placeholder="Enter your fullname"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Email</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              defaultValue={_.get(user, 'email')}
              name="email"
              disabled
              placeholder="Enter your email address"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Whatsapp</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Row>
              <Col xs={10} sm={8}>
                <Form.Control
                  name="phoneRegion"
                  placeholder="+62"
                  size="sm"
                  accepter={SelectPicker}
                  appearance="default"
                  defaultValue={_.get(user, 'phoneRegion')}
                  data={_.map(phoneCodes, (ph) => ({
                    label: _.get(ph, 'dial_code'),
                    value: _.get(ph, 'dial_code'),
                  }))}
                />
              </Col>
              <Col xs={14} sm={16}>
                <Form.Control
                  defaultValue={String(_.get(user, 'phone')).replace(
                    String(_.get(user, 'phoneRegion')),
                    '',
                  )}
                  name="phone"
                  placeholder="812345890001"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>LinkedIn Profile</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="linkedin"
              defaultValue={_.get(user, 'profile.linkedinUrl')}
              placeholder="Enter your LinkedIn profile url"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>University</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="university"
              placeholder="University Name"
              size="sm"
              accepter={SelectPicker}
              appearance="default"
              defaultValue={_.get(user, 'profile.university')}
              data={_.map(universities, (uni) => ({
                label: _.get(uni, 'name'),
                value: _.get(uni, 'name'),
              }))}
              onOpen={() => {
                if (universities.length === 0) {
                  this.getUniversities();
                }
              }}
              onSearch={(keyword: string) => {
                if (keyword !== '') {
                  this.getUniversities(keyword);
                }
              }}
              renderMenu={(menu) => {
                if (universities.length === 0) {
                  return (
                    <p
                      style={{
                        padding: 4,
                        color: '#999',
                        textAlign: 'center',
                      }}
                    >
                      <Loader /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Major</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="major"
              placeholder="Your major"
              size="sm"
              defaultValue={_.get(user, 'profile.major')}
              accepter={SelectPicker}
              appearance="default"
              data={_.map(majors, (m) => ({
                label: _.get(m, 'name'),
                value: _.get(m, 'name'),
              }))}
              onOpen={() => {
                if (majors.length === 0) {
                  this.getMajors();
                }
              }}
              onSearch={(keyword: string) => {
                if (keyword !== '') {
                  this.getMajors(keyword);
                }
              }}
              renderMenu={(menu) => {
                if (majors.length === 0) {
                  return (
                    <p
                      style={{
                        padding: 4,
                        color: '#999',
                        textAlign: 'center',
                      }}
                    >
                      <Loader /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Row>
              <Col xs={14}>
                <Form.ControlLabel>English Proficiency</Form.ControlLabel>
              </Col>
              <Col xs={2}>
                <Whisper
                  placement="top"
                  trigger="hover"
                  speaker={
                    <Tooltip className="tg-tooltip">
                      <div>
                        <p>I can&lsquo;t speak English</p>
                        You can&lsquo;t speak or write in English at all.
                      </div>
                      <br />
                      <div>
                        <p>Basic</p>
                        <ul style={{ paddingLeft: '18px' }}>
                          <li>
                            You can write but with still with a lot of
                            grammatical errors.
                          </li>
                          <li>
                            You can speak and understand basic conversational
                            phrases.
                          </li>
                        </ul>
                      </div>
                      <br />
                      <div>
                        <p>
                          Intermediate (minimum English proficiency required for
                          international startups)
                        </p>
                        <ul style={{ paddingLeft: '18px' }}>
                          <li>
                            You can understand main points on familiar matters.
                          </li>
                          <li>
                            You can discuss certain topics and express your
                            opinions.
                          </li>
                        </ul>
                      </div>
                      <br />
                      <div>
                        <p>
                          Advanced (English proficiency required for
                          international startups)
                        </p>
                        <ul style={{ paddingLeft: '18px' }}>
                          <li>
                            You can communicate flexibly without any difficulty
                            in English.
                          </li>
                          <li>
                            You can understand every business and social context
                            in English and express your opinions accurately.
                          </li>
                        </ul>
                      </div>
                    </Tooltip>
                  }
                  followCursor
                >
                  <IconButton
                    icon={<Icon as={FaInfoCircle} />}
                    style={{
                      color: 'var(--rs-primary-500)',
                      paddingTop: '8px',
                      background: 'transparent',
                    }}
                  />
                </Whisper>
              </Col>
            </Row>
          </Col>
          <Col xs={16}>
            <Form.Control
              accepter={SelectPicker}
              defaultValue={_.get(user, 'profile.englishProficiency')}
              name="englishProficiency"
              placeholder="Enter your english proficiency"
              data={_.map(
                [
                  {
                    name: "I can't speak English",
                    value: 'NO_BASIC',
                  },
                  {
                    name: 'Basic',
                    value: 'BASIC',
                  },
                  {
                    name: 'Intermediate',
                    value: 'INTERMEDIATE',
                  },
                  {
                    name: 'Advanced',
                    value: 'ADVANCED',
                  },
                ],
                (l) => ({
                  label: _.get(l, 'name'),
                  value: _.get(l, 'value'),
                }),
              )}
              searchable={false}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Soft Skills</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="softSkills"
              accepter={TagPicker}
              onSearch={(keyword: string) => {
                this.getSoftSkills(keyword);
              }}
              cacheData={softSkillCacheData}
              onSelect={(val) => {
                const { softSkillCacheData: cache } = this.state;
                const cached = [...cache, ...val];
                this.getSoftSkills(
                  '',
                  _.map(cached, (c) => ({ name: c })),
                );

                this.setState({
                  softSkillCacheData: cached,
                });
              }}
              data={
                softSkills.map((v: any) => ({
                  label: v.name,
                  value: v.name,
                })) || []
              }
              defaultValue={userDefaultSoftSkills}
              value={_.get(profile, 'softSkills')}
              placeholder="Enter your soft skills"
            />
          </Col>
        </Row>

        {showprofileSaveBtn && (
          <Row>
            <Col xs={8} xsPush={8}>
              <Button
                className="btn-tg-tertiary"
                block
                appearance="subtle"
                onClick={() => this.setState({ showprofileSaveBtn: false })}
              >
                {t(constant.translation.general.formCancelButtonKey)}
              </Button>
            </Col>
            <Col xs={8} xsPush={8}>
              <Button
                className="btn-tg-primary"
                block
                appearance="primary"
                onClick={this.saveProfile}
              >
                {t(constant.translation.general.formSaveButtonKey)}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  };

  renderPreference = (): JSX.Element => {
    const { t } = this.props;
    const {
      workingTypes,
      jobTypes,
      companyIndustries,
      showpreferenceSaveBtn,
      dreamJobsCacheData,
      dreamJobs,
      user,
      locations,
    } = this.state;

    const userDefaultRoles = _.get(user, 'preference.jobRoles');
    const industryPreferences = _.get(user, 'workingPreferences') || [];

    return (
      <Form
        key="preference-form"
        ref={this.formPreferenceRef}
        model={this.formPreferenceSchemaModel}
        onChange={(v: object) => this.onFormValueChanged(v, 'preference')}
        formValue={_.get(this.state, 'preference')}
        fluid
      >
        <h3>
          {t(constant.translation.profile.pageCandidatePreferenceSubTitleKey)}
        </h3>
        <br />
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Recent Experience</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="organization"
              defaultValue={_.get(user, 'profile.organization')}
              accepter={Textarea}
              placeholder="Enter your previous experience"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Year of Experience</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="yoe"
              defaultValue={_.get(user, 'profile.yoe')}
              accepter={InputPicker}
              data={_.range(0, 11).map((value) => ({
                label: String(value),
                value,
              }))}
              placeholder="Select the year"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Job Seeking Status</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="seekingStatus"
              defaultValue={_.get(user, 'preference.seekingStatus')}
              accepter={InputPicker}
              data={[
                {
                  label: 'Actively looking for opportunities',
                  value: 'ACTIVELY_LOOKING',
                },
                {
                  label: 'Hired, but search for opportunities',
                  value: 'HIRED_OPEN',
                },
                {
                  label: 'Not open for opportunities',
                  value: 'NOT_OPEN',
                },
              ]}
              placeholder="Select Status"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Job Type</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="jobType"
              accepter={TagPicker}
              data={_.map(jobTypes, (val: any) => ({
                label: val.name,
                value: val.id,
              }))}
              defaultValue={_.filter(industryPreferences, {
                type: 'EMPLOYMENT_STATUS',
              }).map((v: any) => _.get(v, 'id'))}
              placeholder="Enter your preference job type"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Working Type</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="workingType"
              accepter={TagPicker}
              data={_.map(workingTypes, (val: any) => ({
                label: val.name,
                value: val.id,
              }))}
              defaultValue={_.filter(industryPreferences, {
                type: 'WORKING_TYPE',
              }).map((v: any) => _.get(v, 'id'))}
              placeholder="Enter your preference working type"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Dream Job</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="jobRole"
              accepter={TagPicker}
              onSearch={(keyword: string) => {
                this.getDreamJobs(keyword);
              }}
              cacheData={dreamJobsCacheData}
              onSelect={(val) => {
                const { softSkillCacheData: cache } = this.state;
                const cached = [...cache, ...val];
                this.getDreamJobs(
                  '',
                  _.map(cached, (c) => ({ title: c })),
                );

                this.setState({
                  dreamJobsCacheData: cached,
                });
              }}
              data={
                dreamJobs.map((v: any) => ({
                  label: v.title,
                  value: v.title,
                })) || []
              }
              defaultValue={String(userDefaultRoles)
                .split(',')
                .map((v: string) => v)}
              placeholder="Enter your dream job role"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Industry Preference</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="industry"
              accepter={TagPicker}
              data={_.map(companyIndustries, (val: any) => ({
                label: val.name,
                value: val.id,
              }))}
              defaultValue={_.filter(industryPreferences, {
                type: 'COMPANY_INDUSTRY',
              }).map((v: any) => _.get(v, 'id'))}
              placeholder="Enter your preference industry"
            />
          </Col>
        </Row>
        <Row>
          <Col xs={8}>
            <Form.ControlLabel>Job Location</Form.ControlLabel>
          </Col>
          <Col xs={16}>
            <Form.Control
              name="jobLocation"
              accepter={TagPicker}
              data={_.map(locations, (m) => ({
                label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                value: _.get(m, 'cityName'),
              }))}
              defaultValue={_.split(_.get(user, 'preference.jobLocation'), ';')}
              onSearch={(keyword: string) => {
                if (keyword !== '') {
                  this.getLocation(keyword);
                }
              }}
              onOpen={() => {
                this.getLocation();
              }}
              renderMenu={(menu) => {
                if (locations.length === 0) {
                  return (
                    <p
                      style={{
                        padding: 4,
                        color: '#999',
                        textAlign: 'center',
                      }}
                    >
                      <Loader /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
              size="sm"
              placeholder="Type Your Location"
            />
          </Col>
        </Row>

        {showpreferenceSaveBtn && (
          <Row>
            <Col xs={8} xsPush={8}>
              <Button
                className="btn-tg-tertiary"
                block
                appearance="subtle"
                onClick={() => this.setState({ showpreferenceSaveBtn: false })}
              >
                {t(constant.translation.general.formCancelButtonKey)}
              </Button>
            </Col>
            <Col xs={8} xsPush={8}>
              <Button
                className="btn-tg-primary"
                block
                appearance="primary"
                onClick={this.saveProfile}
              >
                {t(constant.translation.general.formSaveButtonKey)}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    );
  };

  renderMyDocuments = (): JSX.Element => {
    const { t } = this.props;
    const { user } = this.state;

    return (
      <Form
        key="mydocuments-form"
        onChange={(v: object) => this.onFormValueChanged(v, 'myDocuments')}
        formValue={_.get(this.state, 'myDocuments')}
        fluid
      >
        <h3>
          {t(constant.translation.profile.pageCandidateMyDocumentSubtitleKey)}
        </h3>
        <br />
        <Form.Group>
          <Form.ControlLabel>
            {t(constant.translation.general.formCVKey)}
          </Form.ControlLabel>
          <Form.Control
            name="cv"
            multiple={false}
            // eslint-disable-next-line max-len
            accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            accepter={Uploader}
            draggable
            action="#"
            maxPreviewFileSize={2097152}
            fileList={
              _.get(user, 'cv')
                ? _.map([_.get(user, 'cv')], (c: any) => ({
                    name: c.filename,
                    fileKey: c.filename,
                    url: c.url,
                  }))
                : []
            }
            removable={false}
            ref={this.formUploaderCVRef}
            onChange={(files: FileType[]) => this.uploadDocuments(files, 'cv')}
            renderFileInfo={(file) => {
              return (
                <>
                  <span>{file.name}</span>
                  {file.url && (
                    <IconButton
                      onClick={() => window.open(file.url)}
                      appearance="subtle"
                      icon={<FaDownload />}
                    />
                  )}
                </>
              );
            }}
            autoUpload={false}
          >
            <div
              style={{
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '12px',
              }}
            >
              <span>
                Drag & drop your files here or <a href="#">choose file</a>
                <br />
                <br />
                <small>2 MB max file size</small>
              </span>
            </div>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            {t(constant.translation.general.formAdditionalFilesKey)}
          </Form.ControlLabel>
          <Form.Control
            name="additionalFile"
            multiple={false}
            // eslint-disable-next-line max-len
            accepter={Uploader}
            draggable
            action="#"
            maxPreviewFileSize={2097152}
            onChange={(files: FileType[]) =>
              this.uploadDocuments(files, 'additional')
            }
            removable={false}
            autoUpload={false}
            fileList={
              _.get(user, 'additionalFile')
                ? _.map([_.get(user, 'additionalFile')], (c: any) => ({
                    name: c.filename,
                    fileKey: c.filename,
                    url: c.url,
                  }))
                : []
            }
            renderFileInfo={(file) => {
              return (
                <>
                  <span>{file.name}</span>
                  {!_.isEmpty(file.url) && (
                    <IconButton
                      onClick={() => window.open(file.url)}
                      appearance="subtle"
                      icon={<FaDownload />}
                    />
                  )}
                </>
              );
            }}
          >
            <div
              style={{
                height: 200,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '12px',
              }}
            >
              <span>
                Drag & drop your files here or <a href="#">choose file</a>
                <br />
                <br />
                <small>2 MB max file size</small>
              </span>
            </div>
          </Form.Control>
        </Form.Group>
      </Form>
    );
  };

  renderTab = (key: string): JSX.Element | null => {
    switch (key) {
      case 'basic':
        return this.renderBasicProfile();
      case 'preference':
        return this.renderPreference();
      case 'my-document':
        return this.renderMyDocuments();
      default:
        return null;
    }
  };

  renderMainContent = (): JSX.Element | null => {
    const { user } = this.state;
    const name = _.get(user, 'name');
    const generalTestScore = _.get(user, 'generalTestScore');
    const generalTrustScore = _.get(user, 'generalTestTrustScore');
    let generalExpiryDate: string =
      _.get(user, 'generalTestScoreExpiryDate') || '';
    const isExpired = _.isEmpty(generalExpiryDate)
      ? true
      : moment().isAfter(moment(generalExpiryDate, 'YYYY-MM-DD HH:mm:ss'));
    const { t } = this.props;
    const { tabKey, steps, resultsByPreference, loadingJobCards } = this.state;

    const expireAmount = _.get(steps, '0.scoreExpiryDateAmount');
    const expireUnit = _.get(steps, '0.scoreExpiryDateUnit');
    const generalExpiryAt = moment(generalExpiryDate, 'YYYY-MM-DD HH:mm:ss')
      .add(0, 'days')
      .format('dddd, DD MMMM YYYY');
    generalExpiryDate = moment(generalExpiryDate, 'YYYY-MM-DD HH:mm:ss')
      .subtract(expireAmount, expireUnit)
      .format('dddd, DD MMMM YYYY');
    const isGeneralTestShowed = _.isNumber(generalTestScore) && !isExpired;
    const isPassed =
      _.toNumber(generalTestScore) >= Number(_.get(steps, '0.minimumScore')) &&
      _.toNumber(generalTrustScore) >=
        Number(_.get(steps, '0.minimumTrustScore'));

    return (
      <Col md={24} sm={24} xs={24} lg={18}>
        <Col xs={24}>
          <Panel className="dashboard-panel-blue">
            <Col xs={24} sm={8} xsPull={0} smPush={16}>
              <img src="/bg-profile-data.png" alt="bg-profile" />
            </Col>
            <Col xs={24} sm={16} smPull={8}>
              <h1>Hello, {name}!</h1>
              <p>{t(constant.translation.profile.message.greeting)}</p>
            </Col>
          </Panel>
        </Col>

        <Col xs={24}>
          <Col xs={24} sm={24} md={16}>
            <div className="profile-container">
              <h2>{t(constant.translation.profile.pageCandidateTitleKey)}</h2>

              <Nav
                activeKey={tabKey}
                onSelect={this.onTabChanged}
                style={{ marginBottom: 50 }}
              >
                <Nav.Item eventKey="basic">Basic Data</Nav.Item>
                <Nav.Item eventKey="preference">Job Preference</Nav.Item>
                <Nav.Item eventKey="my-document">My Documents</Nav.Item>
              </Nav>

              <Panel>{this.renderTab(tabKey)}</Panel>

              {tabKey === 'basic' &&
                isGeneralTestShowed &&
                generalTestScore > 0 &&
                steps.length > 0 && (
                  <>
                    <br />
                    <br />
                    <Panel className="basicinfo-general-test">
                      <h3>
                        {t(
                          constant.translation.profile
                            .pageCandidateGeneralTestTitleKey,
                        )}
                      </h3>
                      <br />
                      <p>
                        The result of this test will be used to determine
                        whether or not to proceed to the next phase. If you do
                        not pass, you will not be able to progress to the next
                        phase.{' '}
                        <b>If you pass, you only need to take this test once</b>
                        , and you do not need to take it again if you want to
                        apply for future positions.
                      </p>
                      <br />
                      <Panel className="basicinfo-general-test-score-panel">
                        <Row>
                          <Col xs={8} lg={5}>
                            <Avatar
                              size="lg"
                              circle
                              // eslint-disable-next-line max-len
                              src={
                                isPassed
                                  ? // eslint-disable-next-line max-len
                                    `${process.env.PUBLIC_URL}/logo-general-test-passed.png`
                                  : // eslint-disable-next-line max-len
                                    `${process.env.PUBLIC_URL}/logo-general-test-failed.png`
                              }
                              alt="general-test"
                              style={{ background: 'transparent' }}
                            />
                          </Col>
                          <Col xs={16} lg={19}>
                            <h3
                              style={{
                                margin: '0px',
                                color: isPassed ? '#05BE17' : '#F43939',
                              }}
                            >
                              {isPassed ? 'Passed' : 'Failed'}
                            </h3>
                            <p className="score-text">
                              Score: {generalTestScore || 0}
                            </p>
                            <p className="date-taken-text">
                              Taken on {generalExpiryDate}
                            </p>
                            <p className="date-taken-text">
                              Expiration date {generalExpiryAt}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24}>
                            <Progress.Line
                              percent={generalTestScore}
                              showInfo={false}
                              strokeColor="#004AAD"
                              trailColor="#C2D4EB"
                            />
                          </Col>
                        </Row>
                        {generalTestScore <
                          Number(_.get(steps, '0.minimumScore')) && (
                          <Row>
                            <Col xs={2} style={{ textAlign: 'center' }}>
                              <FaInfoCircle />
                            </Col>
                            <Col xs={22}>
                              <p style={{ color: '#595959' }}>
                                You must get a score of more than at least{' '}
                                {_.get(steps, '0.minimumScore')} of 100 to pass
                                the test
                              </p>
                            </Col>
                          </Row>
                        )}
                      </Panel>
                    </Panel>
                  </>
                )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Panel className="curatedjobs-panel">
              <h3>
                {t(constant.translation.profile.pageCandidateCuratedJobs)}
              </h3>
              {resultsByPreference.map((r) => (
                <Panel
                  key={r.id}
                  className="curatedjobs-card"
                  onClick={() => {
                    const { navigate } = this.props;
                    navigate(`/dashboard/candidate/jobs/${r.id}`);
                  }}
                >
                  <Row>
                    <Col xs={7} lg={9}>
                      <Avatar
                        size="lg"
                        circle
                        src={_.get(r, 'company.logo.url')}
                        alt="logo"
                      />
                    </Col>
                    <Col xs={17} lg={15}>
                      <Whisper
                        placement="topStart"
                        speaker={
                          <Tooltip>
                            <b>{_.get(r, 'title')}</b>
                          </Tooltip>
                        }
                      >
                        <h3>{_.get(r, 'title')}</h3>
                      </Whisper>
                      <p>{_.get(r, 'company.name')}</p>
                    </Col>
                  </Row>
                </Panel>
              ))}

              {loadingJobCards && (
                <>
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                  <PlaceholderGraph active />
                </>
              )}

              {resultsByPreference.length > 0 && (
                <Button
                  onClick={() => {
                    window.open(`/dashboard/candidate/search-jobs`, '_self');
                  }}
                  appearance="link"
                  block
                  style={{ textAlign: 'center', marginTop: '12px' }}
                >
                  View All
                </Button>
              )}
            </Panel>
          </Col>
        </Col>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CandidateProfileScreen));
