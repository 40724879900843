import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  InputGroup,
  Panel,
  Row,
  Schema,
  toaster,
} from 'rsuite';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { constant, util } from '@helper';
import _ from 'lodash';

import { AuthAPI, UserAPI } from '@services/api';
import AuthStorage from '@common/services/authStorage';

import { LoginPayload } from '@common/ApiTypes';
import constants from '@helper/constants';

import linkedinLogo from '@assets/logo-linkedin-white.png';
import googleLogo from '@assets/logo-google.png';

const { withRouter } = util;
const { StringType } = Schema.Types;

type State = {
  login: LoginPayload;

  showPassword: boolean;
  rememberMe: boolean;
  verifyEmail: string;
};

class CandidateLoginScreen extends React.Component<any, State> {
  formSignInRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formLoginSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      login: {
        email: '',
        password: '',
      },
      verifyEmail: '',
      showPassword: false,
      rememberMe: false,
    };

    this.formSignInRef = React.createRef();
    this.formLoginSchemaModel = Schema.Model({
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
      password: StringType().isRequired('Please fill the required field'),
    });
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }

    const { location, navigate } = this.props;
    const search = _.get(location, 'search');
    const query = new URLSearchParams(search);
    const bearerToken = query.get('bearer_token');
    if (bearerToken) {
      const storage = AuthStorage.getInstance();
      storage.setIsAuthenticated(true);
      storage.setAccessToken(`Bearer ${bearerToken}`);
      const res: object = await UserAPI.getProfile();
      const u: any = _.get(res, 'data');
      storage.setUser(u);

      navigate('/dashboard/candidate/home', { replace: true });
      window.location.reload();
    }
  }

  async componentDidUpdate(): Promise<void> {
    const { location } = this.props;
    const search = _.get(location, 'search');
    const query = new URLSearchParams(search);

    const token = query.get('token');
    const email = query.get('email');

    const { verifyEmail: currEmail } = this.state;

    if (email && token) {
      if (email !== currEmail) {
        this.verifyEmail(email, token);
      }
    }
  }

  private verifyEmail = async (email: string, token: string): Promise<void> => {
    const { t } = this.props;

    try {
      await AuthAPI.verifyEmail(email, token);

      toaster.push(
        util.buildMessage(
          t(constant.translation.profile.formSuccessVerifyEmailKey),
        ),
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    } finally {
      this.setState({ verifyEmail: email });
    }
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onGoogleSignInButtonClicked = (): void => {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL}/api/v1/auth/google`,
        '_self',
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onLinkedInSignInButtonClicked = (): void => {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL}/api/v1/auth/linkedin`,
        '_self',
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onSignInButtonClicked = async (): Promise<void> => {
    const { t, navigate } = this.props;

    if (!_.invoke(this.formSignInRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.login.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { login: payload } = this.state;
      const res = await AuthAPI.login(payload);
      const data: any = _.get(res, 'data');

      toaster.push(
        util.buildMessage(
          t(constant.translation.login.formSuccessValidationMessageKey),
        ),
      );

      // Set access token
      const { user } = data;
      const authStorage = AuthStorage.getInstance();
      authStorage.setUser(user);

      const profile = _.get(user, 'profile');
      const appRole = _.get(user, 'appRole');

      if (appRole === 'USER') {
        if (_.isEmpty(profile)) {
          navigate('/basic-info', { replace: true });
        }
      }

      navigate('/', { replace: true });
      window.location.reload();
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));

      // Erase the password
      this.setState((prevState) => ({
        ...prevState,
        login: {
          ...prevState.login,
          password: '',
        },
      }));
    }
  };

  render({ t } = this.props): JSX.Element | null {
    const { showPassword, rememberMe } = this.state;

    return (
      <FlexboxGrid justify="center" align="middle" className="login-container">
        <Panel
          className="login-panel"
          header={
            <div>
              <p>{t(constant.translation.login.pageCandidateTitleKey)}</p>
              <small>
                {t(constant.translation.login.pageCandidateSubTitleKey)}
              </small>
            </div>
          }
          bordered
        >
          <Form
            key="signin-form"
            ref={this.formSignInRef}
            model={this.formLoginSchemaModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'login')}
            formValue={_.get(this.state, 'login')}
            fluid
          >
            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formEmailKey)}
              </Form.ControlLabel>
              <Form.Control
                name="email"
                placeholder="Enter your email address"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.onSignInButtonClicked();
                  }
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password here"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      this.onSignInButtonClicked();
                    }
                  }}
                />

                <InputGroup.Button
                  onClick={() => this.setState({ showPassword: !showPassword })}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>

            <Row className="remember-me-section">
              <Col xs={12}>
                <span>
                  <Checkbox
                    onChange={() => this.setState({ rememberMe: !rememberMe })}
                  />
                  Remember Me
                </span>
              </Col>

              <Col xs={12}>
                <a
                  href="/forgot-password"
                  style={{ float: 'right', paddingTop: '5px' }}
                >
                  Forgot Password?
                </a>
              </Col>
            </Row>

            <Button
              className="btn-tg-primary"
              block
              appearance="primary"
              onClick={this.onSignInButtonClicked}
            >
              {t(constant.translation.general.formLoginButtonKey)}
            </Button>

            <br />
            <p className="create-account-section">
              Don&rsquo;t have an account? &nbsp;
              <a href="/sign-up">Create an Account</a>
            </p>
            <br />
            <p
              style={{
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              or
            </p>
            <br />

            <Button
              className="btn-tg-google"
              block
              appearance="primary"
              onClick={this.onGoogleSignInButtonClicked}
            >
              <img alt="logo" src={googleLogo} />
              <div>
                {t(
                  constant.translation.login.pageCandidateContinueWithLabelKey,
                )}
                <b>Google</b>
              </div>
            </Button>

            <br />

            <Button
              className="btn-tg-linkedin"
              block
              appearance="primary"
              onClick={this.onLinkedInSignInButtonClicked}
            >
              <img alt="logo" src={linkedinLogo} />
              <div>
                {t(
                  constant.translation.login.pageCandidateContinueWithLabelKey,
                )}
                <b>LinkedIn</b>
              </div>
            </Button>
          </Form>
        </Panel>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(CandidateLoginScreen));
