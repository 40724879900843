import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  InputGroup,
  Panel,
  Row,
  Schema,
  toaster,
} from 'rsuite';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { constant, util } from '@helper';
import _ from 'lodash';
import { SignUpPayload } from '@common/ApiTypes';
import constants from '@helper/constants';
import { AuthAPI } from '@services/api';

import linkedinLogo from '@assets/logo-linkedin-white.png';
import googleLogo from '@assets/logo-google.png';

const { withRouter } = util;
const { StringType } = Schema.Types;

type State = {
  signup: SignUpPayload;

  showPassword: boolean;
  showConfirmPassword: boolean;
  agreementChecked: boolean;
};

class CandidateSignUpScreen extends React.Component<any, State> {
  formSignUpRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formSignUpSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      signup: {
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
      },
      showConfirmPassword: false,
      showPassword: false,
      agreementChecked: false,
    };

    this.formSignUpRef = React.createRef();
    this.formSignUpSchemaModel = Schema.Model({
      name: StringType().isRequired('Please fill the required field'),
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
      password: StringType().isRequired('Please fill the required field'),
      confirmPassword: StringType()
        .addRule((value, data) => {
          if (!_.isEqual(value, data.password)) {
            return false;
          }

          return true;
        }, 'The confirm password is not matched.')
        .isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }
  }

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onSignUpButtonClicked = async (): Promise<void> => {
    const { t, navigate } = this.props;

    if (!_.invoke(this.formSignUpRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.signup.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { signup: payload } = this.state;
      await AuthAPI.signUp(payload);

      // Show pop-up message
      toaster.push(
        util.buildMessage(
          t(constant.translation.signup.formAfterSignUpMessageKey),
        ),
      );

      navigate('/email-verification', {
        state: { email: payload.email },
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));

      // Erase the password
      this.setState((prevState) => ({
        ...prevState,
        signup: {
          ...prevState.signup,
          password: '',
          confirmPassword: '',
        },
      }));
    }
  };

  private onGoogleSignUpButtonClicked = (): void => {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL}/api/v1/auth/google`,
        '_self',
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onLinkedInSignInButtonClicked = (): void => {
    try {
      window.open(
        `${process.env.REACT_APP_BASE_URL}/api/v1/auth/linkedin`,
        '_self',
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  render({ t } = this.props): JSX.Element | null {
    const { showPassword, showConfirmPassword, agreementChecked } = this.state;

    return (
      <FlexboxGrid justify="center" align="middle" className="signup-container">
        <Panel
          className="signup-panel"
          header={
            <div>
              <p>{t(constant.translation.signup.pageCandidateTitleKey)}</p>
              <small>
                {t(constant.translation.signup.pageCandidateSubTitleKey)}
              </small>
            </div>
          }
          bordered
        >
          <Form
            key="signup-form"
            ref={this.formSignUpRef}
            model={this.formSignUpSchemaModel}
            onChange={(v: object) => this.onFormValueChanged(v, 'signup')}
            formValue={_.get(this.state, 'signup')}
            fluid
          >
            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formFullNameKey)}
              </Form.ControlLabel>
              <Form.Control name="name" placeholder="Enter your fullname" />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formEmailKey)}
              </Form.ControlLabel>
              <Form.Control
                name="email"
                placeholder="Enter your email address"
              />
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter your password here"
                />

                <InputGroup.Button
                  onClick={() => this.setState({ showPassword: !showPassword })}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.ControlLabel>
                {t(constants.translation.general.formConfirmPasswordKey)}
              </Form.ControlLabel>
              <InputGroup inside>
                <Form.Control
                  name="confirmPassword"
                  type={showConfirmPassword ? 'text' : 'password'}
                  placeholder="Enter your confirm password here"
                />

                <InputGroup.Button
                  onClick={() =>
                    this.setState({ showConfirmPassword: !showConfirmPassword })
                  }
                >
                  {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Button>
              </InputGroup>
            </Form.Group>

            <Row className="agreement-section">
              <Col xs={24} style={{ marginLeft: '-2%' }}>
                <span>
                  <Checkbox
                    onChange={() =>
                      this.setState({ agreementChecked: !agreementChecked })
                    }
                  />
                  By creating an account, I agree to our{' '}
                  <a target="_blank" href="/terms-condition">
                    Terms & Conditions
                  </a>
                </span>
              </Col>
            </Row>

            <Button
              className="btn-tg-primary"
              block
              appearance="primary"
              disabled={!agreementChecked}
              onClick={this.onSignUpButtonClicked}
            >
              {t(constant.translation.general.formSignUpButtonKey)}
            </Button>

            <br />
            <p className="create-account-section">
              Already have an account?&nbsp;
              <a href="/login">Log In</a>
            </p>
            <br />
            <p
              style={{
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              or
            </p>
            <br />

            <Button
              className="btn-tg-google"
              block
              appearance="primary"
              onClick={this.onGoogleSignUpButtonClicked}
            >
              <img alt="logo" src={googleLogo} />
              <div>
                {t(
                  constant.translation.login.pageCandidateContinueWithLabelKey,
                )}
                <b>Google</b>
              </div>
            </Button>

            <br />

            <Button
              className="btn-tg-linkedin"
              block
              appearance="primary"
              onClick={this.onLinkedInSignInButtonClicked}
            >
              <img alt="logo" src={linkedinLogo} />
              <div>
                {t(
                  constant.translation.login.pageCandidateContinueWithLabelKey,
                )}
                <b>LinkedIn</b>
              </div>
            </Button>
          </Form>
        </Panel>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(CandidateSignUpScreen));
