import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Row, Col, Button, ButtonGroup } from 'rsuite';

function TermsConditionScreen(): JSX.Element {
  const [lang, setLang] = useState('en');

  useEffect(() => {
    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'white');
    }
  }, []);

  const setENLang = (): void => setLang('en');
  const setIDLang = (): void => setLang('id');

  const renderENContent = (): JSX.Element => (
    <>
      <div className="paragraph-section">
        <h5>1. GENERAL PROVISIONS</h5>
        <p>
          a. Platform is www.talentgrowth.id website that is administered by Us.
          <br />
          b. Internet connection is required to use Our service. All costs
          incurred from the connection of your electronic device with the
          internet network shall fully be your responsibility. <br />
          c. The platform facilitates talents to connect with Talent Growth who
          provide recruitment services via Platform (“Services”). <br />
          d. You understand and agree that Your conversation with Our customer
          service will be automatically stored and filed for the legal purpose
          and improvement of the quality of Our Service. The confidentiality of
          Your conversation is assured and no information will be disclosed.
          <br />
          e. We shall have the right not to process the registration, limit, or
          terminate Your use of Platform if We have reasonable reasons to
          suspect that You have violated these Terms of Use or applicable laws.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>2. GENERAL TERMS OF USE OF PLATFORM</h5>
        <p>
          a. You represent that You are 18 (eighteen) years old at minimum or
          married and not placed under guardianship or custody (“Legal Age”).
          <br />
          b. If you are below the Legal Age, You represent and warrant that You
          have obtained consent from your parent(s) or legal guardian(s)
          (“Parents”). By consenting, Parents are considered to have read,
          understood, agreed to, and are bound to this Terms of Use. Parents are
          fully responsible for (i) all of your actions on the Platform; (ii)
          any fees or charges associated with the Services; and (iii) Your
          compliance with this Terms of Use. <br />
          c. In the event You are using Platform in the name of a legal entity,
          You represent that You are authorized to act for and on behalf of such
          legal entity and to make the legal entity to enter into and be bound
          to this Terms of Use. <br />
          d. Your use of Platform is subject to Our approval, which We may grant
          or deny in Our sole discretion; <br />
          e. You may use the Platform by registering Yourself to the Platform by
          providing all required information as requested on the Platform
          registration page (“Personal Data”). Your Personal Data will only be
          used by us to render the Services in the Platform and for other
          purposes upon Your agreement per applicable laws. <br />
          f. You promise not to assign, transfer, or authorize other persons to
          use Your identity or use Your account. You shall keep the
          confidentiality of the password of Your account and each
          identification supplied to You for the account or Your Personal Data.
          Unauthorized use of Your account caused by the disclosure of the
          password in any manner will not make any transaction or Service order
          made through Your account to be deemed as an unauthorized transaction
          or order. <br />
          g. You must report to Us if You, for any cause whatsoever, do not have
          control of Your account. You are responsible for the use and misuse of
          Your account in the Platform by other persons. <br />
          h. You are not permitted to endanger, misuse, alter or modify Platform
          in any manner whatsoever, including but not limited to distributing
          any virus, worm, spyware or any other computer code, file,
          technologies or program that may or is intended to damage or hijack
          the operation of any hardware, software or telecommunications
          equipment, or any other aspect of the Platform or the interest or
          property of the Users. <br />
          i. You understand and agree that Your use of Platform is also subject
          to Our Privacy Policy (attached as updated from time to time) as an
          inseparable part of these Terms of Use. <br />
          j. You promise that You will only use Platform to involve Yourself to
          the Services. You shall not misuse or use Platform for illegal
          activities, including but not limited to money laundering, theft,
          fraud, terrorism, or other deceitful acts. You also promise that You
          will not cause inconvenience to other persons or make false orders
          through the Platform. <br />
          k. You acknowledge and agree that each information in any form
          whatsoever, including however not limited to video, audio, picture, or
          text in the Platform have its intellectual property rights (including
          however not limited to trademark and copyright). You shall not use,
          modify, disclose, distribute, and/or disassemble such information
          without permission from its owner. l. When accessing and using the
          Platform, You are not permitted to: <br />
          (i) violate any laws, third party rights, or this Terms of Use; <br />
          (ii) submit or post any information or content in any form that
          violates, infringes, or misappropriate any intellectual property right
          of a third party.
          <br />
          (iii) take or collect Personal Data from other users, including but
          not limited to email addresses, without consent from such users.
          <br />
          (iv) use the Platform to distribute advertising or other content.
          <br />
          m.You acknowledge and agree that We can send You message(s) and
          notification(s) about information that are related to Your activities
          on Our Platform, including but not limited to message(s) via SMS or
          email <br />
          n. You acknowledge and agree that the Service rate stated in the
          Platform may be subject to change at any time.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>3. FEES AND SERVICES</h5>
        <p>
          Joining the Platform is free. Changes to that schedule are effective
          after We provide You with at least fourteen days’ notice by posting
          the changes on the Platform. Unless otherwise stated, all fees are
          quoted in Rupiah.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>4. LIMITATION OF LIABILITIES</h5>
        <p>
          a. You agree to indemnify and hold Us and Our employees,
          representatives, agents, and affiliates, harmless against any claims,
          suits, actions, or other proceedings brought against them based on or
          arising from any claim resulting from Your breach of these Terms of
          Use. You will pay any costs, damages, and expenses, including, but not
          limited to, reasonable attorneys’ fees and costs awarded against or
          otherwise incurred by Us in connection with or arising from any such
          claim, suit, action, or proceeding.
          <br />
          b. We are not responsible or liable for any default or loss, direct or
          indirect, material or immaterial caused by the interaction between the
          trainer and talent at the time of Services, including but not limited
          to any interaction through Platform and classroom. We are not
          responsible for the errors or actions whatsoever made by the trainer
          during the course of Service.
          <br />
          c. We are not responsible for disputes, claims, losses, injuries, or
          damage of any kind that might arise out of or relate to conduct of
          trainer or talents, including, but not limited to, any talent&lsquo;s
          reliance upon any information provided by a trainer.
          <br />
          d. We do not control submitted content posted on the Services and, as
          such, do not guarantee in any manner the reliability, validity,
          accuracy or truthfulness of such submitted content. You also
          understand that by using the Services, We may expose You to submitted
          content that You consider offensive, indecent, or objectionable. We
          shall not be held responsible to keep such content from You.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>5. INTELLECTUAL PROPERTY RIGHTS</h5>
        <p>
          a. We are the sole owner of the name, icon, and logo of Talent Growth.
          Using, modifying, or placing name, icon, logo, or mark without Our
          written permission is strictly prohibited.
          <br />
          b. All intellectual property rights in the Platform under the laws of
          the Republic of Indonesia, including the ownership of intellectual
          property rights of all source code of the Platform and intellectual
          property rights related to the Platform. You are prohibited from
          committing infringing the intellectual property right in the Platform,
          including making modification, derivative works, adaptation,
          duplicates, copying, selling, reproducing, hacking, and/or exploiting
          the Platform including use of the Platform from unauthorized access,
          automatic launch of program or script, or any program whatsoever which
          may hinder the operations and/or performance of the Platform, or in
          any manner whatsoever reproduce or avoid the navigation structure or
          presentation from the Platform or its content.
          <br />
          c. We have the full right to take any legal actions against violation
          by You relating to intellectual property rights in the Platform.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>6. NOTICE</h5>
        <p>
          a. All notices or requests for information to Us will be further
          processed if addressed through email to admin@talentgrowth.id , by
          sending a written document to our address at Regency Melati Mas Blok
          F19 no 12B, Jelupang, Kec. Serpong Utara, Kota Tangerang Selatan,
          Banten 15323
          <br />
          b. Notice to You will be deemed properly received if We can show that
          such notice, either in physical or electronic form, has been sent to
          You.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>7. PLATFORM FAIRNESS AND SAFETY</h5>
        <p>
          We will at all times exert Our reasonable efforts to keep the Platform
          functioning and running smoothly. However, We are not responsible and
          will not be held liable for the unavailability of Platform and/or
          feature service for any reason, including however not limited to
          maintenance or technical issues. However, You acknowledge and agree
          that the transmission or internet access cannot continuously create
          safe and private payment transactions and therefore each message or
          information sent or used by You in the Platform may be hijacked or
          taken by unauthorized third parties.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>8. NO WARRANTY</h5>
        <p>
          This Platform is made available on an “as is” basis in the sense that
          You acknowledge and agree that all risks arising from the use of
          Platform features by You will fully rest on You and You have no right
          to request compensation whatsoever from Us. We do not make or give the
          following representation or warranty:
          <br /> a. that the Platform is free from error and/or defect or when
          being operated in combination with hardware, software, system, or
          other data including that errors and/or defect in the Platform will be
          repaired.
          <br /> b. availability and reliability of the Platform or servers
          supporting the Platform, free from virus and other malicious
          components.
          <br /> c. security, timeliness, quality, compatibility, availability,
          accuracy, and completeness of the Platform features.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>9. CLOSING</h5>
        <p>
          a. Our relationship with You is an independent relationship and no
          agency, partnership, joint venture, employee-employer, or owner of
          franchisee-franchisor will be created from these Terms of Use.
          <br />
          b. The headings inserted in these Terms of Use shall be made for
          reference only and shall in no way establish, limit, explain, or
          describe the content of the articles. <br />
          c. We reserve the right to investigate and claim our rights against
          each violation of these Terms of Use to the extent permitted by law.
          You hereby acknowledge that We have the right to monitor the access to
          sites and Platform to ensure compliance with these Terms of Use, or to
          comply with the regulations or court order, administrative body or
          other governmental bodies. <br />
          d. User shall not engage in money laundering and/or use funds from
          illicit sources. We shall have the right to report transactions
          alleged or suspected as money laundering to the relevant authority.
          <br />
          e. We shall have the right, without prior notice, to take any actions
          required in the case of manipulation/fraud, such as to close cancel
          the account, withdraw the cashback, cancel the transaction, retain the
          fund, including in the case of alleged fraud, money laundering or
          violation made by You to the Terms of Use and the laws of the Republic
          of Indonesia. <br />
          f. These terms of use shall be governed by and construed under the
          laws of the Republic of Indonesia and You strictly agree that the
          provisions of article 1266 Civil Code and other statutory rules
          requiring approval or authorization of the court to terminate the
          Terms of Use shall not apply to these Terms of Use. <br />
          g. All disputes relating to these Terms of Use shall be settled
          amicably or be referred to the Indonesian National Board of
          Arbitration (BANI), under the current rules or procedures at BANI. If
          both parties fail to settle the disputes through BANI, such disputes
          will be submitted to the jurisdiction of the North Jakarta District
          Court. <br />
          h. This Terms of Use was made in Bahasa Indonesia and in English, if
          there are any differences between the two versions, the English
          version shall prevail.
        </p>
      </div>
      {/* <div className="paragraph-section">
        <h5>TRANSITIONAL PROVISION</h5>
        <p className="transition-provision-text">
          Other provision(s) will be stated in an Agreement between Us and
          Teachers. If the Agreement has not been signed by both parties, then
          the previous Terms and Condition shall apply.
        </p>
      </div> */}
    </>
  );

  const renderIDContent = (): JSX.Element => (
    <>
      <div className="paragraph-section">
        <h5>1. KETENTUAN UMUM</h5>
        <p>
          a. Platform adalah situs www.talentgrowth.id yang dikelola oleh Kami.
          <br />
          b. Koneksi internet diperlukan untuk dapat menggunakan Layanan. Segala
          biaya yang timbul atas koneksi perangkat elektronik Anda dengan
          jaringan internet merupakan tanggung jawab Anda sepenuhnya. <br />
          c. Platform memfasilitasi peserta untuk tersambung dengan lowongan
          pekerjaan melalui Platform (“Layanan”) dan melayani mitra perusahaan
          untuk merekrut kandidat yang mendaftar dan melewati tahapan screening
          <br />
          d. Anda memahami dan sepakat bahwa pembicaraan Anda dengan layanan
          pelanggan Kami akan secara otomatis tersimpan untuk keperluan hukum
          dan perbaikan kualitas Layanan Kami. Kerahasiaan pembicaraan Anda
          terjamin dan tidak ada informasi yang akan dibuka.
          <br />
          e. Kami berhak untuk tidak memproses pendaftaran Anda, membatasi, atau
          membatalkan penggunaan Platform oleh Anda jika Kami memiliki alasan
          yang wajar untuk curiga bahwa Anda telah melanggar Ketentuan
          Penggunaan atau hukum yang berlaku.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>2. GENERAL TERMS OF USE OF PLATFORM</h5>
        <p>
          a. Anda menyatakan bahwa Anda minimum berusia 18 (delapan belas) tahun
          atau sudah pernah menikah dan tidak sedang dibawah pengampuan (“Usia
          Legal”). <br /> b. Jika Anda berusia di bawah Usia Legal, Anda
          menyatakan dan menjamin bahwa Anda telah mendapatkan persetujuan dari
          orang tua atau wali Anda (“Wali”). Dengan menyetujui, Wali dianggap
          telah membaca, mengerti, dan setuju dengan, dan tunduk kepada
          Ketentuan Penggunaan ini. Wali bertanggung jawab sepenuhnya terhadap
          (i) seluruh perbuatan Anda di dalam Platform; (ii) seluruh biaya atau
          tagihan terkait Layanan; dan (iii) kepatuhan Anda terhadap Ketentuan
          Penggunaan ini.
          <br />
          c. Dalam hal Anda menggunakan Platform mewakili Badan Hukum, Anda
          menyatakan bahwa Anda berwenang untuk bertindak untuk dan atas nama
          Badan Hukum tersebut dan untuk membuat Badan Hukum tersebut mengikuti
          dan terikat pada Ketentuan Penggunaan ini. <br />
          d. Penggunaan Platform oleh Anda tergantung kepada persetujuan Kami,
          dimana dapat Kami kabulkan atau tolak atas diskresi Kami. <br />
          e. Anda dapat menggunakan Platform dengan mendaftarkan Diri Anda ke
          Platform dengan memberikan seluruh informasi yang dibutuhkan yang
          dinyatakan pada laman pendaftaran Platform (“Data Pribadi”). Data
          Pribadi Anda hanya akan digunakan oleh Kami dan/atau Pengajar untuk
          menjalankan Layanan pada Platform dan untuk tujuan lain atas
          persetujuan Anda menurut hukum yang berlaku. <br />
          f. Anda berjanji untuk tidak menyerahkan, mengalihkan, atau
          mengotorisasi orang lain untuk menggunakan identitas atau akun Anda.
          Anda wajib menjaga kerahasiaan kata sandi (password) akun Anda dan
          setiap tanda pengenal yang diserahkan kepada Anda atau Data Pribadi
          Anda. Penggunaan ilegal akun Anda yang diakibatkan adanya terbukanya
          kata sandi (password) dalam bentuk apapun tidak membuat transaksi atau
          pesanan terhadap Layanan yang dilakukan melalui akun Anda dianggap
          sebagai pesanan atau transaksi ilegal. <br />
          g. Anda wajib melaporkan kepada Kami jika Anda, atas sebab apapun,
          kehilangan kendali atas akun Anda. Anda bertanggungjawab terhadap
          penggunaan atau penyalahgunaan yang dilakukan oleh akun Anda pada
          Platform oleh orang lain. <br />
          h. Anda dilarang untuk membahayakan, menyalahgunakan, mengubah, atau
          memodifikasi Platform dengan data apapun, termasuk namun tidak
          terbatas pada menyebarkan virus, worm, spyware, maupun kode komputer
          lainnya, file, teknologi, atau program yang ditujukan untuk merusak
          maupun mengambil alih pengoperasian hardware dan/atau perlengkapan
          telekomunikasi, atau aspek lainnya pada Platform maupun kepentingan
          dan/atau properti Pengguna. <br />
          i. Anda mengerti dan setuju bahwa penggunaan Platform oleh Anda juga
          tunduk pada Kebijakan Privasi Kami (terlampir sebagaimana diperbarui
          dari waktu ke waktu) sebagai bagian yang tidak terpisahkan dari
          Ketentuan Penggunaan ini. <br />
          j. Anda berjanji bahwa Anda hanya akan menggunakan Platform untuk
          melibatkan diri Anda kepada Layanan. Anda dilarang menyalahgunakan
          atau menggunakan Platform untuk aktivitas ilegal, termasuk namun tidak
          terbatas kepada pencucian uang, pencurian, penipuan, terorisme, atau
          tindakan curang lainnya. Anda juga berjanji untuk tidak menimbulkan
          ketidaknyamanan kepada orang lain atau membuat pesanan palsu melalui
          Platform. <br />
          k. Anda mengetahui dan setuju bahwa setiap informasi dalam bentuk
          apapun, termasuk namun tidak terbatas pada video, audio, gambar, atau
          teks pada Platform memiliki hak atas kekayaan intelektual (termasuk
          namun tidak terbatas pada merek dan hak cipta). Anda dilarang
          menggunakan, memodifikasi, mengungkap, mendistribusikan, dan/atau
          membongkar informasi tersebut tanpa izin dari pemiliknya. <br />
          l. Saat mengakses dan menggunakan Platform, Anda dilarang untuk:{' '}
          <br />
          (i) melanggar hukum apapun, hak pihak ketiga, atau Ketentuan
          Penggunaan ini; (ii) mengunggah atau menayangkan informasi atau konten
          dalam bentuk apapun yang melanggar, mengganggu, atau menyalahgunakan
          hak kekayaan intelektual apapun milik pihak ketiga. <br />
          (iii) mengambil atau mengumpulkan Data Pribadi dari pengguna lain,
          termasuk namun tidak terbatas pada alamat email, tanpa persetujuan
          Pengguna terkait. <br />
          (iv) menggunakan Platform untuk mendistribusikan iklan atau konten
          lain. <br />
          m. Anda mengetahui dan setuju bahwa Kami dapat mengirimkan pesan
          maupun notifikasi kepada Anda mengenai hal yang berhubungan dengan
          aktivitas Anda di Platform, termasuk namun tidak terbatas pada pesan
          melalui SMS ataupun e-mail <br />
          n. Anda memahami dan setuju bahwa biaya Layanan pada Platform dapat
          sewaktu-waktu berubah.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>3. BIAYA DAN LAYANAN</h5>
        <p>
          Kami tidak mengenakan biaya untuk kandidat yang mendaftarkan diri ke
          lowongan yang tersedia. Perubahan skema akan berlaku efektif setelah
          Kami memberikan Anda setidaknya pemberitahuan empat belas hari
          sebelumnya dengan memposting perubahan pada Platform. Kecuali
          dinyatakan sebaliknya, seluruh biaya adalah dalam Rupiah.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>4. PEMBATASAN TANGGUNG JAWAB</h5>
        <p>
          a. Anda sepakat untuk membebaskan dan menjamin bahwa Kami, karyawan
          Kami, perwakilan, agen, dan afiliasi, terbebas dari klaim, tuntutan,
          tindakan, atau proses lainnya yang ditujukan kepadanya atau timbul
          dari klaim yang timbul dari pelanggaran Anda terhadap Ketentuan
          Penggunaan ini. Anda akan membayar segala biaya, kerugian, dan
          pengeluaran termasuk namun tidak terbatas pada biaya kuasa hukum yang
          wajar dan biaya yang diberikan kepada atau ditimbulkan oleh Kami yang
          terkait atau timbul dari klaim, tuntutan, tindakan, atau proses
          tersebut.
          <br />
          b. Kami tidak bertanggung jawab terhadap wanprestasi atau kerugian,
          langsung atau tidak langsung, material atau immaterial yang disebabkan
          interaksi antara Pengajar dengan Peserta pada saat berlangsungnya
          Layanan, termasuk namun tidak terbatas pada interaksi apapun lewat
          Platform dan kelas. Kami tidak bertanggung jawab atas kesalahan atau
          tindakan apapun yang dilakukan oleh Pengajar pada saat Layanan
          berlangsung.
          {/* <br />
          c. We are not responsible for disputes, claims, losses, injuries, or
          damage of any kind that might arise out of or relate to conduct of
          trainer or talents, including, but not limited to, any talent&lsquo;s
          reliance upon any information provided by a trainer.
          <br />
          d. We do not control submitted content posted on the Services and, as
          such, do not guarantee in any manner the reliability, validity,
          accuracy or truthfulness of such submitted content. You also
          understand that by using the Services, We may expose You to submitted
          content that You consider offensive, indecent, or objectionable. We
          shall not be held responsible to keep such content from You. */}
        </p>
      </div>
      <div className="paragraph-section">
        <h5>5. HAK KEKAYAAN INTELEKTUAL</h5>
        <p>
          a. Kami adalah pemilik tunggal atas nama, ikon, dan logo Talent
          Growth. Dilarang keras menggunakan, memodifikasi, atau memasang nama,
          ikon, logo, atau merek tersebut tanpa persetujuan tertulis dari Kami.
          <br />
          b. Seluruh hak atas kekayaan intelektual yang terdapat dalam Platform
          berdasarkan hukum Negara Republik Indonesia, termasuk dalam hal ini
          adalah kepemilikan hak kekayaan intelektual atas seluruh source code
          Platform dan hak kekayaan intelektual terkait Platform. Untuk itu,
          Anda dilarang untuk melakukan pelanggaran atas hak kekayaan
          intelektual yang terdapat pada Platform ini, termasuk melakukan
          modifikasi, karya turunan, mengadaptasi, menduplikasi, menyalin,
          menjual, membuat ulang, meretas, dan/atau mengeksploitasi Platform
          termasuk penggunaan Platform untuk akses yang tidak sah, meluncurkan
          program otomatis atau script, atau segala program apapun yang mungkin
          menghambat operasi dan/atau kinerja Platform, atau dengan cara apapun
          memperbanyak atau menghindari struktur navigasi atau presentasi dari
          Platform atau isinya.
          <br />
          c. Kami memiliki hak penuh untuk mengambil langkah hukum apapun
          terhadap pelanggaran oleh Anda terkait dengan hak kekayaan intelektual
          pada Platform.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>6. PEMBERITAHUAN</h5>
        <p>
          a. Seluruh pemberitahuan atau permohonan informasi kepada Kami akan
          diproses lebih lanjut jika disampaikan melalui mengirimkan email ke
          admin@talentgrowth.id atau dengan dokumen tertulis yang dikirimkan ke
          alamat Kami di Regency Melati Mas Blok F19 no 12B, Jelupang, Kec.
          Serpong Utara, Kota Tangerang Selatan, Banten 15323
          <br />
          b. Pemberitahuan kepada Anda akan dianggap telah diterima jika Kami
          dapat menunjukkan bahwa pemberitahuan tersebut, baik dengan bentuk
          fisik atau elektronik, telah dikirimkan kepada Anda.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>7. KELAYAKAN DAN KEAMANAN PLATFORM</h5>
        <p>
          Kami senantiasa melakukan upaya yang wajar untuk menjaga Platform ini
          berfungsi dan berjalan lancar. Bagaimanapun juga, kami tidak
          bertanggung jawab dan tidak akan bertanggung jawab atas
          ketidaktersediaan Platform dan/atau fitur Layanan yang disebabkan oleh
          berbagai alasan, termasuk namun tidak terbatas pada keperluan
          pemeliharaan atau masalah teknis. Namun demikian, Anda mengetahui dan
          setuju bahwa transmisi atau akses internet tidak selamanya menciptakan
          suatu transaksi pembayaran yang aman dan pribadi, dan karenanya setiap
          pesan atau informasi yang Anda kirimkan atau gunakan dalam Platform
          mungkin dapat dibajak atau diambil oleh pihak ketiga yang tidak
          bertanggung jawab.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>8. TIDAK ADA JAMINAN</h5>
        <p>
          Platform ini disediakan “sebagaimana adanya” dalam arti Anda mengakui
          dan menyetujui bahwa seluruh risiko yang ditimbulkan dari penggunaan
          Platform oleh Anda tetap sepenuhnya ada pada Anda dan Anda tidak
          memiliki hak untuk meminta ganti rugi apapun dari Kami.
          <br /> Kami tidak memberikan pernyataan ataupun jaminan terkait
          dengan:
          <br /> a. bahwa Platform ini akan bebas dari kesalahan dan/atau
          kecacatan maupun saat beroperasi dengan kombinasi dengan perangkat
          keras, perangkat lunak, sistem atau data lainnya serta bahwa kesalahan
          dan/atau kecacatan dalam Platform akan diperbaiki.
          <br /> b. ketersediaan dan keandalan Platform maupun server-server
          pendukung yang menyediakan Platform, termasuk terbebas dari virus dan
          komponen berbahaya lain.
          <br /> c. keamanan, ketepatan waktu, kualitas, kesesuaian,
          ketersediaan, akurasi dan kelengkapan dari Platform.
        </p>
      </div>
      <div className="paragraph-section">
        <h5>9. PENUTUP</h5>
        <p>
          a. Hubungan kami dengan Anda adalah hubungan independen dan tidak ada
          hubungan keagenan, kemitraan, joint venture, majikan-pekerja, atau
          pemilik dari penerima franchise dan pemberi franchise yang akan dibuat
          berdasarkan Ketentuan Penggunaan. <br /> b. Judul yang dituliskan pada
          Ketentuan Penggunaan ini hanya dibuat sebagai referensi dan tidak
          mengakibatkan, membatasi, menjelaskan, atau mendeskripsikan konten
          dari pasal tersebut.
          <br />
          c. Kami berhak untuk menyelidiki dan mengklaim hak kami terhadap
          setiap pelanggaran terhadap Ketentuan Penggunaan ini sejauh diizinkan
          oleh hukum. Anda dengan ini mengakui bahwa Kami memiliki hak untuk
          memantau akses ke situs dan Platform untuk memastikan kepatuhan dengan
          Ketentuan Penggunaan ini, atau untuk mematuhi peraturan atau perintah
          pengadilan, badan administratif atau badan pemerintah lainnya. <br />
          d. Pengguna dilarang terlibat di tindak pencucian uang dan/atau
          menggunakan dana dari sumber ilegal. Kami berhak untuk melaporkan
          transaksi yang diduga sebagai tindak pencucian uang kepada pihak yang
          berwenang. <br />
          e. Kami berhak, tanpa pemberitahuan sebelumnya, mengambil tindakan
          apapun yang dibutuhkan jika terjadi manipulasi/penipuan, seperti
          menutup atau membatalkan akun, menarik kembali cashback, membatalkan
          transaksi, menahan dana, termasuk pada kasus dugaan penipuan,
          pencucian uang, atau pelanggaran yang Anda buat terhadap Ketentuan
          Penggunaan dan hukum Negara Republik Indonesia. <br />
          f. Ketentuan Penggunaan ini diatur dan ditafsirkan serta dilaksanakan
          berdasarkan hukum yang berlaku di Negara Republik Indonesia dan Anda
          dengan tegas menyetujui bahwa bahwa ketentuan Pasal 1266 Kitab
          Undang-Undang Hukum Perdata dan ketentuan lainnya yang mewajibkan
          adanya pengesahan atau persetujuan pengadilan untuk dapat mengakhiri
          Ketentuan Penggunaan tidak berlaku terhadap Ketentuan Penggunaan ini.
          <br />
          g. Segala sengketa yang berkaitan dengan Ketentuan Penggunaan ini,
          diselesaikan secara musyawarah untuk mufakat atau melalui Badan
          Arbitrase Nasional Indonesia (BANI), sesuai dengan prosedur yang
          berlaku di BANI. Apabila kedua belah pihak tidak sepakat untuk
          menyelesaikannya sengketa di BANI, maka sengketa akan diselesaikan
          melalui Pengadilan Negeri Jakarta Utara. <br />
          h. Ketentuan Penggunaan ini dibuat dengan Bahasa Indonesia dan Bahasa
          Inggris, jika terdapat perbedaan antara kedua versi tersebut, versi
          Bahasa Inggrislah yang berlaku.
        </p>
      </div>
      {/* <div className="paragraph-section">
        <h5>KETENTUAN TRANSISI</h5>
        <p className="transition-provision-text">
          Ketentuan lain akan dinyatakan dalam Perjanjian antara Kami dan Guru.
          Jika Perjanjian belum ditandatangani oleh kedua belah pihak, maka
          Syarat dan Ketentuan sebelumnya akan berlaku.
        </p>
      </div> */}
    </>
  );

  return (
    <Row>
      <Col xs={24}>
        <div className="bg-title">
          <h1>
            Talent Growth Terms & <br />
            Condition
          </h1>
        </div>

        <div className="paragraph-section">
          <ButtonGroup>
            <Button
              className={lang === 'en' ? 'active' : ''}
              onClick={setENLang}
            >
              English Ver.
            </Button>
            <Button
              className={lang === 'id' ? 'active' : ''}
              onClick={setIDLang}
            >
              Bahasa Indonesia Ver.
            </Button>
          </ButtonGroup>

          <h3>
            {lang === 'id' && 'Syarat Penggunaan'}
            {lang === 'en' && 'Terms of Use'}
          </h3>
          {lang === 'en' && (
            <p>
              Last Updated Date: 1st January 2024
              <br /> <br />
              Welcome to www.talentgrowth.id!
              <br /> <br />
              As a user or visitor (“You”) who access Our site
              (www.talentgrowth.id) or any other digital service(s) provided and
              managed by Us (“Platform”), You must read these terms and
              conditions of use (“Terms of Use”) and Privacy Policy carefully
              and thoroughly before using the features and/or services available
              in the Platform, as it will be applied to You. <br />
              <br />
              By using Platform (as defined below), You hereby expressly
              acknowledge and agree that You have read, understood,
              acknowledged, agreed, accepted, and are subject to all terms and
              conditions herein. These Terms of Use, Privacy Policy, and Partner
              Terms and Conditions constitute valid agreement as to the
              procedure and requirements for the Platform usage between You and
              the Platform administrator, Talent Growth (“We”).
              <br />
              <br />
              We shall have the right to change, modify, supplement, amend,
              and/or delete this Terms of Use without any prior notice to You.
              You should visit this page regularly to ensure that You are aware
              of the latest version of the Terms of Use, as any newer version of
              the Terms of Use shall supersede all of the previous versions. If
              you disagree to fall within this Terms of Use, then You shall not
              use Platform as Your continued use of Platform after any such
              changes, whether or not reviewed by You, shall constitute your
              consent and acceptance of the revised Terms of Use.
              <br />
            </p>
          )}

          {lang === 'id' && (
            <p>
              Tanggal Pembaruan Terakhir: 1 Januari 2024
              <br /> <br />
              Selamat datang di www.talentgrowth.id!
              <br /> <br />
              Sebagai pengguna atau pengunjung (“Anda”) yang mengakses situs
              Kami (www.talentgrowth.id) maupun layanan digital lain yang
              disediakan dan dikelola oleh Kami (“Platform”). Anda harus membaca
              syarat dan ketentuan penggunaan (“Ketentuan Penggunaan”) dan
              Kebijakan Privasi berikut ini dengan seksama sebelum menggunakan
              fitur dan/atau layanan yang tersedia pada Platform, karena
              ketentuan tersebut akan diterapkan pada Anda.
              <br />
              <br />
              Dengan menggunakan Platform (sebagaimana didefinisikan di bawah
              ini), Anda dengan ini menyatakan mengetahui dan menyetujui bahwa
              Anda telah membaca, mengerti, mengetahui, sepakat, menerima, dan
              terikat oleh seluruh syarat dan ketentuan pada Ketentuan
              Penggunaan ini. Ketentuan Penggunaan, Kebijakan Privasi, serta
              Syarat dan Ketentuan Pengajar ini merupakan perjanjian yang sah
              untuk prosedur dan persyaratan penggunaan Platform antara Anda dan
              pengelola Platform, Talent Growth (“Kami”).
              <br />
              <br />
              Kami berhak untuk merubah, memodifikasi, menambahkan, dan/atau
              menghapus Ketentuan Penggunaan ini tanpa ada pemberitahuan
              sebelumnya kepada Anda. Anda harus secara rutin mengunjungi laman
              ini untuk memastikan bahwa Anda menyadari versi terakhir Ketentuan
              Penggunaan ini, karena setiap versi yang lebih baru dari Ketentuan
              Penggunaan ini akan menggantikan semua versi sebelumnya. Jika Anda
              tidak setuju untuk tunduk terhadap Ketentuan Penggunaan ini, Anda
              dilarang untuk menggunakan Platform karena Penggunaan berlanjut
              dari Anda setelah adanya perubahan,baik telah ditinjau atau tidak
              oleh Anda, akan dianggap sebagai persetujuan dan penerimaan Anda
              terhadap Ketentuan Penggunaan yang telah direvisi.
              <br />
            </p>
          )}
        </div>

        {lang === 'en' && renderENContent()}
        {lang === 'id' && renderIDContent()}
      </Col>
    </Row>
  );
}

export default TermsConditionScreen;
