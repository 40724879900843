import { Close } from '@rsuite/icons';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { Avatar, Button, Col, IconButton, Row } from 'rsuite';

import AuthStorage from '@services/authStorage';
import logo from '@assets/logo.png';
import logoInitial from '@assets/logo-initial.png';

import { AuthAPI } from '@services/api';

// Import menu items
import candidateMenus from './menus.json';
import hpMenus from './hp_menus.json';

import './styles.scss';

function Sidebar(): JSX.Element {
  const navigate = useNavigate();
  // const [isAuthenticated, setAuthenticated] = useState(false);

  // useEffect(() => {
  //   const check = async (): Promise<void> => {
  //     try {
  //       const res = {
  //         // await AuthAPI.check();
  //         data: {
  //           isAuthenticated: false,
  //           user: {},
  //         },
  //       };
  //       const c = _.get(res, 'data.isAuthenticated');
  //       const storage = AuthStorage.getInstance();
  //       setAuthenticated(c);

  //       if (c) {
  //         const user = _.get(res, 'data.user');
  //         const anyUserStored = storage.getUser();
  //         if (_.isEmpty(anyUserStored)) {
  //           storage.setUser(user);
  //         }
  //       } else {
  //         storage.clearAll();
  //       }

  //       storage.setIsAuthenticated(c);
  //     } catch (e) {
  //       //
  //     }
  //   };

  //   check();
  // }, []);

  const logout = async (): Promise<void> => {
    try {
      const instance = AuthStorage.getInstance();
      const user = instance.getUser();
      const role = _.get(user, 'appRole');

      await AuthAPI.logout();
      AuthStorage.getInstance().clearAll();

      if (role === 'HIRING_PARTNER') {
        navigate('/login/hiring-partner');
      } else {
        navigate('/');
      }
      window.location.reload();
    } catch (e) {
      //
    }
  };

  const onSidebarMenuClicked = (url: string): void => {
    if (url.includes('logout')) {
      logout();
    }
  };

  const storage = AuthStorage.getInstance();
  const isAuthenticated = storage.isAuthenticated();
  const user = storage.getUser();
  const location = useLocation();
  const pathname = _.get(location, 'pathname');

  let menus = [];
  if (_.get(user, 'appRole') === 'USER') {
    menus = candidateMenus;
  } else {
    menus = hpMenus;
  }

  return (
    <div
      id="sidebar"
      className="sidebar animate-top bar-block"
      style={{ display: 'none' }}
    >
      <IconButton
        circle
        appearance="link"
        icon={<Close />}
        className="sidebar-close-btn"
        onClick={() => {
          const sidebar = document.getElementById('sidebar');
          let app = document.getElementsByClassName('app');

          if (app.length === 0) {
            app = document.getElementsByClassName('app-hp');
          }

          if (sidebar != null) {
            sidebar.classList.remove('animate-top');
            sidebar.classList.add('animate-top-hide');

            app[0].classList.add('bg-white-hide');
            app[0].classList.remove('bg-white-active');

            app[0].classList.remove('bg-white-hide');

            // setTimeout(() => {
            //   _.set(sidebar, 'style.display', 'none');
            // }, 600);
          }
        }}
      />

      <a href="/">
        <img
          src={window.innerWidth <= 425 ? logoInitial : logo}
          alt="app-logo"
          className="sidebar-logo"
        />
      </a>

      <div className="menu-container">
        {menus.map((menu: any) => {
          const { isPrivateRoute, isPublicRoute, isBoth } = menu;

          if (
            (isAuthenticated && isPrivateRoute) ||
            (!isAuthenticated && isPublicRoute) ||
            isBoth
          ) {
            if (_.isArray(menu.role)) {
              const role = _.get(user, 'role');
              if (!menu.role.includes(role)) {
                return null;
              }
            }

            return (
              <a
                style={{
                  backgroundColor:
                    pathname.includes(menu.url) ||
                    pathname.includes(menu.detailUrl)
                      ? '#E4F0FF'
                      : 'transparent',
                  fontWeight:
                    pathname.includes(menu.url) ||
                    pathname.includes(menu.detailUrl)
                      ? '700'
                      : '200',
                  paddingLeft: menu.icon ? '2.5%' : '6.5%',
                  paddingRight: menu.icon ? '2.5%' : '6.5%',
                }}
                href={!menu.url.includes('logout') ? menu.url : '#'}
                key={menu.text}
                className="bar-item"
                onClick={() => onSidebarMenuClicked(menu.url)}
              >
                {menu.icon && (
                  <Avatar
                    size="md"
                    circle
                    src={`${process.env.PUBLIC_URL}/${menu.icon}`}
                    alt="icon-sidebar"
                    style={{
                      backgroundColor:
                        pathname.includes(menu.url) ||
                        pathname.includes(menu.detailUrl)
                          ? '#E4F0FF'
                          : 'white',
                    }}
                  />
                )}
                <p
                  className="menu-container__p"
                  style={{ marginLeft: menu.icon ? '8px' : '0px' }}
                >
                  {menu.text}
                </p>
              </a>
            );
          }

          return null;
        })}

        <Row
          style={{
            position: 'absolute',
            bottom: '10vh',
            width: '100%',
            display: isAuthenticated ? 'none' : '',
          }}
        >
          <Col xs={24}>
            <Button
              id="sb-signup-btn"
              appearance="default"
              className="sidebar-btn"
              onClick={() =>
                window.open(`${process.env.PUBLIC_URL}/sign-up`, '_self')
              }
            >
              Sign Up
            </Button>
          </Col>
          <Col xs={24}>
            <Button
              id="sb-login-btn"
              appearance="default"
              className="sidebar-btn"
              onClick={() =>
                window.open(`${process.env.PUBLIC_URL}/login`, '_self')
              }
            >
              Log In
            </Button>
          </Col>

          {/* <Col xs={24}>
            <Button
              id="sb-login-btn"
              appearance="default"
              className="sidebar-btn"
              onClick={() => window.open('https://bit.ly/VI-Company', '_blank')}
              style={{
                display: pathname === '/hiring-partner' ? 'block' : 'none',
              }}
            >
              Contact Us
            </Button>
          </Col> */}
        </Row>

        <Row
          style={{
            position: 'absolute',
            bottom: '2.5vh',
            width: '100%',
            display: !isAuthenticated ? 'none' : '',
          }}
        >
          <Col xs={24}>
            <Button
              id="sb-signup-btn"
              appearance="default"
              className="sidebar-btn"
              onClick={logout}
            >
              Log out
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Sidebar;
