import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Container, Content } from 'rsuite';

import {
  AppAnalytic,
  AppErrorBoundary,
  AppFooter,
  AppNavbar,
  AppSidebar,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from '@common/components';
import {
  CandidateHomeScreen,
  CandidateLoginScreen,
  CandidateProfileScreen,
  CandidateBasicInfoScreen,
  CandidateSignUpScreen,
  CandidateDashboardScreen,
  CandidateJobCategoriesScreen,
  ForgotPasswordScreen,
  ResendPasswordScreen,
  HiringPartnerHomeScreen,
  NotFoundScreen,
  PrivacyPolicyScreen,
  TermsConditionScreen,
  ChangePasswordScreen,
  CandidateSearchJobsScreen,
  CandidateSearchCompaniesScreen,
  CandidateJobDetailsScreen,
  CandidateCompanyDetailsScreen,
  CandidateMyApplicationScreen,
  CandidateMyApplicationDetailScreen,
  EmailVerifyScreen,
  HiringPartnerLoginScreen,
  HiringPartnerForgotPasswordScreen,
  HiringPartnerResendPasswordScreen,
  HiringPartnerChangePasswordScreen,
  HiringPartnerSignUpScreen,
  HiringPartnerDashboardScreen,
  HiringPartnerMyCompanyScreen,
  HiringPartnerApplicationDetailScreen,
  HiringPartnerJobDetails,
  GeneralTestScreen,
  GeneralTestResultScreen,
  GeneralTestApplyScreen,
} from '@screens';
import ReactGA from 'react-ga4';

// css
import 'rsuite/dist/rsuite.min.css';
import './App.css';
import './main.css';

ReactGA.initialize('G-GD6VNJB2YD', {
  testMode: process.env.NODE_ENV === 'development',
});

function App(): JSX.Element {
  const { pathname } = window.location;
  const isHP =
    pathname !== '/hiring-partner' && pathname.includes('/hiring-partner');

  return (
    <AppAnalytic>
      <AppErrorBoundary>
        <BrowserRouter>
          <ScrollToTop />
          <AppSidebar />
          <Container className={`app${isHP ? '-hp' : ''}`}>
            <AppNavbar />

            <Content>
              <Routes>
                <Route element={<PublicRoute />}>
                  {/* Auth */}
                  <Route
                    path="/login/hiring-partner"
                    element={<HiringPartnerLoginScreen />}
                  />
                  <Route path="/login" element={<CandidateLoginScreen />} />
                  <Route
                    path="/forgot-password/hiring-partner"
                    element={<HiringPartnerForgotPasswordScreen />}
                  />
                  <Route
                    path="/forgot-password"
                    element={<ForgotPasswordScreen />}
                  />
                  <Route
                    path="/email-verification"
                    element={<EmailVerifyScreen />}
                  />
                  <Route
                    path="/resend-password-link/hiring-partner"
                    element={<HiringPartnerResendPasswordScreen />}
                  />
                  <Route
                    path="/resend-password-link"
                    element={<ResendPasswordScreen />}
                  />
                  <Route
                    path="/change-password/hiring-partner"
                    element={<HiringPartnerChangePasswordScreen />}
                  />
                  <Route
                    path="/change-password"
                    element={<ChangePasswordScreen />}
                  />
                  <Route
                    path="/sign-up/hiring-partner"
                    element={<HiringPartnerSignUpScreen />}
                  />
                  <Route path="/sign-up" element={<CandidateSignUpScreen />} />
                </Route>

                {/* Misc */}
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicyScreen />}
                />
                <Route
                  path="/terms-condition"
                  element={<TermsConditionScreen />}
                />

                {/* * * * *
                 * Private Routes
                 * * * * *
                 */}
                <Route element={<PrivateRoute />}>
                  <Route
                    path="/basic-info"
                    element={<CandidateBasicInfoScreen />}
                  />

                  {/* Dashboard (Hiring-Partner) */}
                  <Route
                    path="/dashboard/hiring-partner/home"
                    element={<HiringPartnerDashboardScreen />}
                  />
                  <Route
                    path="/dashboard/hiring-partner/application/:id"
                    element={<HiringPartnerApplicationDetailScreen />}
                  />
                  <Route
                    path="/dashboard/hiring-partner/mycompany/job/:id"
                    element={<HiringPartnerJobDetails />}
                  />
                  <Route
                    path="/dashboard/hiring-partner/mycompany"
                    element={<HiringPartnerMyCompanyScreen />}
                  />

                  {/* Dashboard (Candidate) */}
                  <Route
                    path="/dashboard/candidate/home"
                    element={<CandidateDashboardScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/profile"
                    element={<CandidateProfileScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/search-jobs"
                    element={<CandidateSearchJobsScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/jobs/:id"
                    element={<CandidateJobDetailsScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/search-companies"
                    element={<CandidateSearchCompaniesScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/companies/:id"
                    element={<CandidateCompanyDetailsScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/my-application"
                    element={<CandidateMyApplicationScreen />}
                  />
                  <Route
                    path="/dashboard/candidate/my-application/:id"
                    element={<CandidateMyApplicationDetailScreen />}
                  />
                  <Route
                    path="/dashboard/general-test/:token"
                    element={<GeneralTestScreen />}
                  />
                  <Route
                    path="/dashboard/general-test/:token/result"
                    element={<GeneralTestResultScreen />}
                  />
                  <Route
                    path="/dashboard/general-test/:token/apply"
                    element={<GeneralTestApplyScreen />}
                  />
                </Route>

                {/* * * * *
                 * Public Routes
                 * * * * *
                 */}
                <Route
                  index
                  path="/"
                  element={<Navigate replace to="/candidate" />}
                />
                <Route path="/candidate" element={<CandidateHomeScreen />} />
                <Route
                  path="/job-categories"
                  element={<CandidateJobCategoriesScreen />}
                />

                {/* Hiring Partner routes */}
                <Route
                  path="/hiring-partner"
                  element={<HiringPartnerHomeScreen />}
                />

                <Route path="*" element={<NotFoundScreen />} />
              </Routes>
            </Content>

            <AppFooter />
          </Container>
        </BrowserRouter>
      </AppErrorBoundary>
    </AppAnalytic>
  );
}

export default App;
