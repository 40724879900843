import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  InputPicker,
  Loader,
  Modal,
  Panel,
  Row,
  Schema,
  SelectPicker,
  Steps,
  Uploader,
  toaster,
} from 'rsuite';
import moment from 'moment';
import { constant, util } from '@helper';
import _ from 'lodash';
import { HiringPartnerSignUpPayload } from '@common/ApiTypes';
import constants from '@helper/constants';
import { AuthAPI, MiscAPI, UserAPI } from '@services/api';
import { Promise } from 'bluebird';
import { FaChevronLeft } from 'react-icons/fa';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const { withRouter } = util;
const { StringType, ArrayType, ObjectType, NumberType } = Schema.Types;

type State = {
  signup: HiringPartnerSignUpPayload;
  stepOnePayload: object;
  stepTwoPayload: object;

  phoneCodes: Array<object>;
  step: number;

  showModal: boolean;

  documentLink: string;

  isAgreementsChecked: boolean;
  companyIndustries: Array<any>;
  locations: Array<any>;
  benefits: Array<any>;
  cultures: Array<any>;
  workingTypes: Array<any>;
  agreementsChecked: Array<boolean>;
};

class HiringPartnerSignUpScreen extends React.Component<any, State> {
  formSignUpRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  quillRef: any;

  formSignUpSchemaModel: any;

  formStepOneRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formStepTwoRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formStepOneSchemaModel: any;

  formStepTwoSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      signup: {
        email: '',
        name: '',
        jobTitle: '',
        phone: '',
        phoneRegion: '+62',
        companyName: '',
        companyDescription: '',
        companyRegion: '',
        companyAddress: '',
        companyWebsite: '',
        companySize: [],
        companyIndustry: -1,
        companyWorkingTypes: [],
        companyCultures: [],
      },
      showModal: false,
      stepTwoPayload: {},
      stepOnePayload: {},
      step: 0,
      phoneCodes: [],
      companyIndustries: [],
      locations: [],
      workingTypes: [],
      benefits: [],
      cultures: [],
      documentLink: '',
      agreementsChecked: [false, false],
      isAgreementsChecked: false,
    };

    this.formSignUpRef = React.createRef();
    this.formStepOneRef = React.createRef();
    this.formStepTwoRef = React.createRef();
    this.quillRef = React.createRef();

    this.formSignUpSchemaModel = Schema.Model({
      name: StringType().isRequired('Please fill the required field'),
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
      phoneRegion: StringType().isRequired('Please fill the required field'),
      phone: StringType()
        .addRule((value) => {
          // (^\8)
          const pattern = /^(\d{2,3}-?){2}\d{3,8}$/g;
          return new RegExp(pattern).test(value);
        }, 'Invalid phone number. Ex: 81122334')
        .isRequiredOrEmpty(),
      jobTitle: StringType().isRequired('Please fill the required field'),
      companyAgreement: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1, 'Please upload your company agreement (signed) here')
        .of(
          ObjectType().shape({
            name: StringType().isRequired(),
            blobFile: ObjectType().isRequired(),
          }),
        ),
    });
    this.formStepOneSchemaModel = Schema.Model({
      companyLogo: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1, 'Please upload your company logo here')
        .of(
          ObjectType().shape({
            name: StringType().isRequired(),
            blobFile: ObjectType().isRequired(),
          }),
        ),
      companyName: StringType().isRequired('Please fill the required field'),
      companyRegion: StringType().isRequired('Please fill the required field'),
      companyWebsite: StringType()
        .isURL()
        .isRequired('Please fill the required field'),
      companyAddress: StringType().isRequired('Please fill the required field'),
      companyIndustry: NumberType()
        .min(1)
        .isRequired('Please fill the required field'),
      companySize: StringType().isRequired('Please fill the required field'),
    });
    this.formStepTwoSchemaModel = Schema.Model({
      companyWorkingTypes: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1),
      companyCultures: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1),
      companyBenefits: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(1),
    });
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getPhoneCodes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getDocumentContract();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyBenefits();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyCultures();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getLocation();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getDocumentContract = async (): Promise<void> => {
    const year = moment().format('YY');
    const response = await MiscAPI.getTemplates(`FY${year}_COMPANY_AGREEMENT`);
    const link = _.get(response, 'data.0.url');
    this.setState({
      documentLink: link || '',
    });
  };

  renderModal = (
    { showModal } = this.state,
    { navigate } = this.props,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={showModal}
      onClose={() => {
        this.setState({ showModal: false });
      }}
    >
      <Modal.Header
        closeButton
        onClick={() => navigate('/login/hiring-partner')}
      />
      <Modal.Body>
        <img
          src={`${`${process.env.PUBLIC_URL}/bg-hp-signup.png`}`}
          className="bg-hp-signup"
          alt="bg-img"
        />
        <h3>Verification Process</h3>
        <p>
          Please wait for 1 day for the verification process by us. <br />{' '}
          Kindly check your email periodically for further messages from us.
        </p>
      </Modal.Body>
    </Modal>
  );

  private getCompanyBenefits = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('BENEFIT');
    const data = _.get(response, 'data') || [];
    this.setState({
      benefits: data,
    });
  };

  private getCompanyCultures = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('CULTURE');
    const data = _.get(response, 'data') || [];
    this.setState({
      cultures: data,
    });
  };

  private getCompanyWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getChildAttributes('COMPANY_INDUSTRY');
    const response2 = await MiscAPI.getAttributes('COMPANY_INDUSTRY');

    const data = _.get(response, 'data') || [];
    const data2 = _.filter(
      _.get(response2, 'data') || [],
      (o: any) => o.numberOfChildren === 0,
    );

    this.setState({
      companyIndustries: _.sortBy([...data, ...data2], 'name'),
    });
  };

  private getLocation = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ locations: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getPhoneCodes = async (): Promise<void> => {
    const url = `https://api.github.com/gists/75f6183458db8c453306f93521e93d37`;
    const response = await fetch(url);
    const json = await response.json();
    const data = JSON.parse(_.get(json, 'files["CountryCodes.json"].content'));

    this.setState({
      phoneCodes: _.uniqBy(data, 'dial_code'),
    });
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onSignUpButtonClicked = async (): Promise<void> => {
    const { t } = this.props;

    try {
      if (!_.invoke(this.formStepTwoRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      const { signup, stepOnePayload, stepTwoPayload } = this.state;
      const payload = {
        ...signup,
        ...stepOnePayload,
        ...stepTwoPayload,
      };
      const docs = await Promise.map(
        [
          {
            blobFile: _.get(payload, 'companyAgreement.0.blobFile'),
            name: 'company_agreement.pdf',
          },
          {
            blobFile: _.get(payload, 'companyLogo.0.blobFile'),
            name: 'company_logo.png',
          },
        ],
        async (blob) => {
          const base64 = await util.blobToBase64(blob.blobFile);
          return {
            content: base64,
            table: 'App\\Models\\Company',
            filename: blob.name,
          };
        },
        { concurrency: 5 },
      );
      _.set(payload, 'documents', docs);
      _.unset(payload, 'companyAgreement');
      _.unset(payload, 'companyLogo');

      const size = payload.companySize.split('-');
      _.set(payload, 'companySize', [_.toNumber(size[0]), _.toNumber(size[1])]);

      const region = payload.companyRegion.split('_');
      _.set(payload, 'companyRegion', _.get(region, '1'));

      // Signup hiring partner - Store data
      await AuthAPI.hiringPartnerSignUp(payload);

      // Show pop-up message
      toaster.push(
        util.buildMessage(
          t(constant.translation.signup.formAfterSignUpMessageKey),
        ),
      );

      this.setState({ showModal: true });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') ||
        _.get(response, 'message') ||
        _.get(e, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onNextButtonClicked = async (): Promise<void> => {
    const { step, signup } = this.state;
    const { t } = this.props;

    try {
      let ref = null;
      if (step === 0) {
        ref = this.formSignUpRef;
      } else if (step === 1) {
        ref = this.formStepOneRef;
      } else {
        ref = this.formStepTwoRef;
      }

      if (!_.invoke(ref, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      if (step === 0) {
        // Check if phone number is unique or not
        await UserAPI.checkPhone(signup.phone);
      }

      if (step === 1) {
        if (this.quillRef.getLength() <= 1) {
          throw new Error('Please fill the company description field');
        }
      }

      if (step < 2) {
        this.setState({ step: step + 1 });
      }
    } catch (e: any) {
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  onCheckAgreement = (pos: number): void => {
    const { agreementsChecked } = this.state;
    agreementsChecked[pos] = !agreementsChecked[pos];

    this.setState({ agreementsChecked }, () => {
      this.setState({ isAgreementsChecked: this.checkAllAgreements() });
    });
  };

  checkAllAgreements = (pos = 0): boolean => {
    const { agreementsChecked } = this.state;

    if (pos >= agreementsChecked.length) {
      return true;
    }

    return agreementsChecked[pos] && this.checkAllAgreements(pos + 1);
  };

  renderStepOne(
    { t } = this.props,
    { step, companyIndustries, locations } = this.state,
  ): JSX.Element | null {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="signup-container signup-container-steps"
        id="hiring-partner"
        style={{ display: step === 1 ? 'block' : 'none' }}
      >
        <Panel
          className="signup-panel signup-panel-steps"
          header={
            <div>
              <span>{t(constant.translation.signup.pageHPTitleKey)}</span>
              <small>{t(constant.translation.signup.pageHPSubtitleKey)}</small>
            </div>
          }
          bordered
        >
          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyNameKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companyName"
                  placeholder="Enter your company name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyDescKey)}
                </Form.ControlLabel>
                <ReactQuill
                  ref={(e) => {
                    this.quillRef = e?.getEditor();
                  }}
                  theme="snow"
                  value={_.get(this.state, 'signup.companyDescription')}
                  onChange={(value) => {
                    this.setState({
                      signup: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.signup,
                        companyDescription: value,
                      },
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formCompanyLocationKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  name="companyRegion"
                  accepter={SelectPicker}
                  appearance="default"
                  data={_.map(locations, (m, idxx) => ({
                    label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                    value: `${idxx}_${_.get(m, 'region')}`,
                  }))}
                  onOpen={() => {
                    if (locations.length === 0) {
                      this.getLocation();
                    }
                  }}
                  onSearch={(keyword: string) => {
                    if (keyword !== '') {
                      this.getLocation(keyword);
                    }
                  }}
                  renderMenu={(menu) => {
                    if (locations.length === 0) {
                      return (
                        <p
                          style={{
                            padding: 4,
                            color: '#999',
                            textAlign: 'center',
                          }}
                        >
                          <Loader /> Loading...
                        </p>
                      );
                    }
                    return menu;
                  }}
                  size="sm"
                  placeholder="Choose your location"
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formCompanyIndustryKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  name="companyIndustry"
                  accepter={InputPicker}
                  data={_.map(companyIndustries, (c: any) => ({
                    label: c.name,
                    value: c.id,
                  }))}
                  placeholder="Select the Industry"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyAddressKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companyAddress"
                  placeholder="Enter your company address"
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanySizeKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companySize"
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  accepter={InputPicker}
                  data={[
                    {
                      label: '1-10',
                      value: '1-10',
                    },
                    {
                      label: '11-50',
                      value: '11-50',
                    },
                    {
                      label: '51-100',
                      value: '51-100',
                    },
                    {
                      label: '101-200',
                      value: '101-200',
                    },
                    {
                      label: '201-500',
                      value: '201-500',
                    },
                    {
                      label: '501-1000',
                      value: '501-1000',
                    },
                    {
                      label: '1001-5000',
                      value: '1001-5000',
                    },
                    {
                      label: '5001-10000',
                      value: '5001-10000',
                    },
                    {
                      label: '>10000',
                      value: '10001-50000',
                    },
                  ]}
                  placeholder="51-100"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyWebKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companyWebsite"
                  placeholder="Enter your company website"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyLogoKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companyLogo"
                  multiple={false}
                  // eslint-disable-next-line max-len
                  accept="image/png"
                  accepter={Uploader}
                  fileList={_.get(this.state, 'signup.companyLogo')}
                  draggable
                  action="#"
                  maxPreviewFileSize={2097152}
                  autoUpload={false}
                >
                  <div
                    style={{
                      height: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '12px',
                    }}
                  >
                    <span>
                      Drag & drop your files here or <a href="#">choose file</a>
                      <br />
                      <br />
                      <small>2 MB max file size (*.PNG)</small>
                    </span>
                  </div>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br />
          <br />

          <Button
            className="btn-tg-primary-hp"
            block
            appearance="primary"
            onClick={this.onNextButtonClicked}
          >
            {t(constant.translation.general.formContinueButtonKey)}
          </Button>
        </Panel>
      </FlexboxGrid>
    );
  }

  renderStepTwo(
    { t } = this.props,
    { step, benefits, workingTypes, cultures } = this.state,
  ): JSX.Element | null {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="signup-container signup-container-steps"
        id="hiring-partner"
        style={{ display: step === 2 ? 'block' : 'none' }}
      >
        <Panel className="signup-panel signup-panel-steps" bordered>
          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyBenefitsKey)}
                </Form.ControlLabel>
                <Form.Control name="companyBenefits" accepter={CheckboxGroup}>
                  {benefits.map((b) => (
                    <Checkbox value={b.id}>{b.name}</Checkbox>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyWorkingTypesKey)}
                </Form.ControlLabel>
                <Form.Control
                  name="companyWorkingTypes"
                  accepter={CheckboxGroup}
                >
                  {workingTypes.map((w) => (
                    <Checkbox value={w.id}>{w.name}</Checkbox>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24}>
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formCompanyCulturesKey)}
                </Form.ControlLabel>
                <br />
                <Form.Control
                  name="companyCultures"
                  accepter={CheckboxGroup}
                  inline
                  className="tg-checkbox-group-btn"
                >
                  {cultures.map((c) => (
                    <Checkbox className="tg-checkbox-btn" value={c.id}>
                      {c.name}
                    </Checkbox>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br />
          <br />

          <Button
            className="btn-tg-primary-hp"
            style={{ float: 'right' }}
            appearance="primary"
            onClick={this.onSignUpButtonClicked}
          >
            {t(constant.translation.general.formContinueButtonKey)}
          </Button>

          <Button
            className="btn-tg-primary-hp"
            style={{
              float: 'right',
              marginRight: '8px',
              background: 'transparent',
            }}
            appearance="primary"
            onClick={() => this.setState({ step: step - 1 })}
          >
            {t(constant.translation.general.formBackButtonKey)}
          </Button>
        </Panel>
      </FlexboxGrid>
    );
  }

  renderSteps = ({ step } = this.state): JSX.Element | null => {
    return (
      <FlexboxGrid justify="center">
        <FlexboxGrid.Item as={Col} colspan={24} xs={24}>
          {step >= 1 && (
            <a
              id="hp"
              className="back-btn"
              onClick={() => this.setState({ step: step - 1 })}
              style={{
                position: 'absolute',
                top: '56%',
                left: '6%',
                cursor: 'pointer',
              }}
            >
              <FaChevronLeft />
              <span>Go Back</span>
            </a>
          )}
          <Steps current={step - 1} id="hp">
            <Steps.Item title="COMPANY INFORMATION" />
            <Steps.Item title="COMPANY INSIGHTS" />
          </Steps>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} colspan={24} xs={24}>
          <Form
            key="signup-1-form"
            ref={this.formStepOneRef}
            model={this.formStepOneSchemaModel}
            onChange={(v: object) =>
              this.onFormValueChanged(v, 'stepOnePayload')
            }
            formValue={_.get(this.state, 'stepOnePayload')}
            fluid
          >
            {this.renderStepOne()}
          </Form>

          <Form
            key="signup-2-form"
            ref={this.formStepTwoRef}
            model={this.formStepTwoSchemaModel}
            onChange={(v: object) =>
              this.onFormValueChanged(v, 'stepTwoPayload')
            }
            formValue={_.get(this.state, 'stepTwoPayload')}
            fluid
          >
            {this.renderStepTwo()}
          </Form>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  };

  renderSignUpSection = (
    { t } = this.props,
    { isAgreementsChecked, phoneCodes, documentLink } = this.state,
  ): JSX.Element => (
    <Panel
      className="signup-panel"
      header={
        <div>
          <p>{t(constant.translation.signup.pageHiringPartnerTitleKey)}</p>
          <small>
            {t(constant.translation.signup.pageHiringPartnerSubTitleKey)}
          </small>
        </div>
      }
      bordered
    >
      <Form
        key="signup-form"
        ref={this.formSignUpRef}
        model={this.formSignUpSchemaModel}
        onChange={(v: object) => this.onFormValueChanged(v, 'signup')}
        formValue={_.get(this.state, 'signup')}
        fluid
      >
        <Form.Group>
          <Form.ControlLabel>
            {t(constants.translation.general.formFullNameKey)}
          </Form.ControlLabel>
          <Form.Control name="name" placeholder="Enter your fullname" />
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            {t(constants.translation.general.formCompanyEmailKey)}
          </Form.ControlLabel>
          <Form.Control name="email" placeholder="john.doe@company.com" />
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            {t(constants.translation.general.formPhoneNumberKey)}
          </Form.ControlLabel>
          <Row>
            <Col xs={6}>
              <Form.Control
                name="phoneRegion"
                placeholder="+62"
                defaultValue="+62"
                size="sm"
                accepter={SelectPicker}
                appearance="default"
                data={_.map(phoneCodes, (ph) => ({
                  label: _.get(ph, 'dial_code'),
                  value: _.get(ph, 'dial_code'),
                }))}
                className="phone-prefix"
              />
            </Col>
            <Col xs={18}>
              <Form.Control name="phone" placeholder="812345890001" />
            </Col>
          </Row>
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            {t(constants.translation.general.formRoleKey)}
          </Form.ControlLabel>
          <Form.Control name="jobTitle" placeholder="Enter your role" />
        </Form.Group>

        <Row>
          <Col xs={24}>
            <Form.Group style={{ marginTop: '0px' }}>
              <Form.ControlLabel>
                {t(constants.translation.general.formCompanyAgreementKey)}
              </Form.ControlLabel>
              <p className="hp-signup-instruction">
                Please download, read carefully, and upload the signed{' '}
                <a target="_blank" href={documentLink} rel="noreferrer">
                  document
                </a>
              </p>
              <Form.Control
                name="companyAgreement"
                multiple={false}
                // eslint-disable-next-line max-len
                accept="application/msword, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                accepter={Uploader}
                fileList={_.get(this.state, 'signup.companyAgreement')}
                draggable
                action="#"
                maxPreviewFileSize={2097152}
                autoUpload={false}
              >
                <div
                  style={{
                    height: 200,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '12px',
                  }}
                >
                  <span>
                    Drag & drop your files here or <a href="#">choose file</a>
                    <br />
                    <br />
                    <small>2 MB max file size (*.PDF, *.doc, *.docx)</small>
                  </span>
                </div>
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>

        <div>
          <Row className="agreement-section">
            <Col xs={24} style={{ marginLeft: '-2%' }}>
              <span>
                <Row>
                  <Col xs={3} sm={2}>
                    <Checkbox
                      checked={_.get(this.state, 'agreementsChecked.0')}
                      onChange={() => this.onCheckAgreement(0)}
                    />
                  </Col>
                  <Col xs={21} sm={22} style={{ paddingTop: '8px' }}>
                    I confirm the company is registered business
                  </Col>
                </Row>
              </span>
            </Col>
          </Row>

          <Row className="agreement-section">
            <Col xs={24} style={{ marginLeft: '-2%' }}>
              <span>
                <Row>
                  <Col xs={3} sm={2}>
                    <Checkbox
                      checked={_.get(this.state, 'agreementsChecked.1')}
                      onChange={() => this.onCheckAgreement(1)}
                    />
                  </Col>
                  <Col xs={21} sm={22} style={{ paddingTop: '8px' }}>
                    I confirm and accept the terms of the agreement
                  </Col>
                </Row>
              </span>
            </Col>
          </Row>
        </div>

        <Button
          className="btn-tg-primary-hp"
          block
          appearance="primary"
          disabled={!isAgreementsChecked}
          onClick={this.onNextButtonClicked}
        >
          {t(constant.translation.general.formSignUpButtonKey)}
        </Button>

        <br />
        <p className="create-account-section">
          Already have an account?&nbsp;
          <a href="/login/hiring-partner">Log In</a>
        </p>
        <br />
      </Form>
    </Panel>
  );

  render(): JSX.Element | null {
    const { step } = this.state;

    return step === 0 ? (
      <FlexboxGrid
        justify="center"
        align="middle"
        id="hiring-partner"
        className="signup-container"
      >
        {this.renderSignUpSection()}
      </FlexboxGrid>
    ) : (
      <>
        {this.renderModal()} {this.renderSteps()}
      </>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerSignUpScreen));
