import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  FaBook,
  FaBuilding,
  FaChevronLeft,
  FaChevronRight,
  FaHome,
  FaInfoCircle,
  FaLightbulb,
  FaStar,
  FaUser,
  FaWallet,
} from 'react-icons/fa';
import {
  Affix,
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  Grid,
  IconButton,
  InputPicker,
  Loader,
  Modal,
  Panel,
  Radio,
  RadioGroup,
  RangeSlider,
  Row,
  Schema,
  SelectPicker,
  Stack,
  toaster,
} from 'rsuite';
import utils from '@helper/utils';
import PlaceholderGrid from 'rsuite/esm/Placeholder/PlaceholderGrid';
import { CompanyAPI, MiscAPI } from '@services/api';
import { constant } from '@helper';
import ReactQuill from 'react-quill';
import { Promise } from 'bluebird';
import AuthStorage from '@common/services/authStorage';

const { withRouter } = utils;
const { StringType, NumberType, ArrayType } = Schema.Types;

type State = {
  loading: boolean;
  jobId: any;
  details: any;

  showModal: boolean;
  payload: object | any;

  // misc
  locations: Array<any>;
  roleCategories: Array<any>;
  workingTypes: Array<any>;
  employmentStatuses: Array<any>;
};

class HiringPartnerJobDetails extends React.Component<any, State> {
  responsibilityRef: any;

  requirementRef: any;

  formEditPostingSchemaModel: any;

  formEditPostingRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: true,
      details: {},
      jobId: 0,

      showModal: false,
      payload: {},

      // misc
      locations: [],
      workingTypes: [],
      roleCategories: [],
      employmentStatuses: [],
    };

    this.requirementRef = React.createRef();
    this.responsibilityRef = React.createRef();
    this.formEditPostingRef = React.createRef();
    this.formEditPostingSchemaModel = Schema.Model({
      title: StringType().isRequired('Please fill the required field'),
      duration: NumberType()
        .min(0)
        .isRequired('Please fill the required field'),
      roleCategoryId: NumberType().isRequired('Please fill the required field'),
      technicalTest: StringType().isURL(),
      locationId: NumberType().isRequired('Please fill the required field'),
      empStatus: NumberType().isRequired('Please fill the required field'),
      workingType: NumberType().isRequired('Please fill the required field'),
      minYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.maxYoe) {
            return Number(value) < Number(data.maxYoe);
          }

          return true;
        }, 'Year start should be less than year end'),
      maxYoe: NumberType()
        .isRequired('Please fill the required field')
        .addRule((value, data) => {
          if (data.minYoe) {
            return Number(value) > Number(data.minYoe);
          }

          return true;
        }, 'Year end should be greater than year start'),
      salaryRange: ArrayType()
        .isRequired('Please fill the required field')
        .minLength(2),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    const { params } = this.props;
    const id = _.get(params, 'id');

    this.setState({
      jobId: id,
    });

    this.getJobPostingDetails(id);
  }

  closeModal = ({ details } = this.state): void => {
    this.setState({
      showModal: false,
      payload: {
        title: _.get(details, 'title'),
        locationId: _.get(details, 'locationId'),
        roleCategoryId: _.get(details, 'roleCategoryId'),
        workingType: _.get(details, 'workingTypes.0.id'),
        empStatus: _.get(details, 'employmentStatus.0.id'),
        requirements: _.get(details, 'requirements'),
        description: _.get(details, 'description'),
        minYoe: _.get(details, 'minYoe'),
        maxYoe: _.get(details, 'maxYoe'),
        duration: _.get(details, 'duration'),
        salaryRange: [
          _.get(details, 'minSalaryRange', 0),
          _.get(details, 'maxSalaryRange', 0),
        ],
      },
    });
  };

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private getJobPostingDetails = async (id: any): Promise<void> => {
    try {
      this.setState({ loading: true });
      const response = await CompanyAPI.getJobDetails(id);
      const details = _.get(response, 'data');

      const payload = {
        title: _.get(details, 'title'),
        locationId: _.get(details, 'locationId'),
        roleCategoryId: _.get(details, 'roleCategoryId'),
        workingType: _.get(details, 'workingTypes.0.id'),
        empStatus: _.get(details, 'employmentStatus.0.id'),
        requirements: _.get(details, 'requirements'),
        description: _.get(details, 'description'),
        minYoe: _.get(details, 'minYoe'),
        maxYoe: _.get(details, 'maxYoe'),
        duration: _.get(details, 'duration'),
        salaryRange: [
          _.get(details, 'minSalaryRange', 0),
          _.get(details, 'maxSalaryRange', 0),
        ],
        isShowSalary: _.get(details, 'isShowSalary'),
        technicalTest: _.get(details, 'technicalTest.url'),
      };

      const loc: any = _.get(details, 'jobLocation');
      if (loc) {
        this.getLocations(loc.cityName);
      }

      this.setState({
        details,
        payload,
        loading: false,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(utils.buildErrorMessage(message));
    }
  };

  private getRoleCategories = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('ROLE_CATEGORY');
    const data = _.get(response, 'data') || [];
    this.setState({
      roleCategories: data,
    });
  };

  private getLocations = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ locations: data });
    } catch (e: any) {
      toaster.push(utils.buildErrorMessage(e.message));
    }
  };

  private getCompanyWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private getEmploymentStatus = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      employmentStatuses: data,
    });
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  private openEditJobPostingModal = async (): Promise<void> => {
    this.setState({ showModal: true });

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getRoleCategories();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getEmploymentStatus();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  };

  private updateJobStatus = async (active: boolean): Promise<void> => {
    try {
      const { payload, jobId } = this.state;
      await CompanyAPI.updateJobPosting(jobId, {
        ...payload,
        id: jobId,
        active,
      });

      toaster.push(
        utils.buildMessage('Job posting status has been changed successfully'),
      );

      this.getJobPostingDetails(jobId);
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(utils.buildErrorMessage(message));
    }
  };

  private submitEditJobPosting = async (): Promise<void> => {
    const { t } = this.props;

    try {
      if (!_.invoke(this.formEditPostingRef, 'current.check')) {
        throw new Error(
          t(constant.translation.basicInfo.formErrorValidationMessageKey),
        );
      }

      // Check description field, is it empty?
      if (this.responsibilityRef.getLength() <= 1) {
        throw new Error('Please fill the job description field!');
      }

      // Check requirement field, is it empty?
      if (this.requirementRef.getLength() <= 1) {
        throw new Error('Please fill the job requirements field!');
      }

      const storage = AuthStorage.getInstance();
      const user = storage.getUser();
      const { payload, jobId } = this.state;
      const { workingType, salaryRange } = payload;
      const technicalTestUrl = _.get(payload, 'technicalTest');

      let technicalTestId = null;

      if (technicalTestUrl) {
        const companyName = _.get(user, 'company.name');
        const jobTitle = _.get(payload, 'title');
        const name = `${companyName} | ${jobTitle}`;
        const resp = await MiscAPI.addDataTemplate({
          url: technicalTestUrl,
          name,
        });
        technicalTestId = _.get(resp, 'data.id');
      }

      const obj = {
        ...payload,
        technicalTestId,
        companyId: _.get(user, 'companyId'),
        description: _.get(
          this.responsibilityRef,
          'container.firstChild.innerHTML',
        ),
        requirements: _.get(
          this.requirementRef,
          'container.firstChild.innerHTML',
        ),
        minSalaryRange: _.get(salaryRange, '0'),
        maxSalaryRange: _.get(salaryRange, '1'),
        hot: 0,
        active: 1,
      };

      _.unset(obj, 'workingType');
      _.unset(obj, 'technicalTest');
      _.unset(obj, 'salaryRange');

      await CompanyAPI.editPosting(jobId, obj, { workingType });

      this.setState({
        showModal: false,
      });

      this.getJobPostingDetails(jobId);

      toaster.push(
        utils.buildMessage('Successfully update job posting information!'),
      );
    } catch (err: any) {
      toaster.push(utils.buildErrorMessage(err.message));
    }
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/hiring-partner/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/hiring-partner/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/hiring-partner/mycompany')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/hiring-partner/mycompany',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            My Company
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderAttributesPanel = ({ loading, details } = this.state): JSX.Element => (
    <Panel className="hp-job-details-side-card">
      <Stack
        spacing={8}
        direction="column"
        style={{ alignItems: 'flex-start' }}
      >
        <Row>
          <Col xs={3} md={4}>
            <FaBuilding />
          </Col>
          <Col xs={21} md={20}>
            <p className="hp-job-details-attribute-title">Working Type</p>
            {loading && <PlaceholderGrid rows={1} columns={1} active />}
            {!loading && (
              <p className="hp-job-details-attribute-content">
                {_.get(details, 'workingTypes.0.name') || '-'}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={4}>
            <FaStar />
          </Col>
          <Col xs={21} md={20}>
            <p className="hp-job-details-attribute-title">Benefit</p>
            {loading && <PlaceholderGrid rows={1} columns={1} active />}
            {!loading && (
              <p style={{ marginBottom: '8px' }}>
                {_.get(details, 'company.benefits').length > 1 &&
                  _.reduce(
                    _.get(details, 'company.benefits') || [],
                    (b: string, val: any) => {
                      if (_.isEmpty(b)) {
                        return `${val.name} `;
                      }

                      return `${b}, ${val.name}`;
                    },
                    '',
                  )}

                {_.get(details, 'company.benefits').length === 1 &&
                  _.get(details, 'company.benefits.0.name')}
                {_.get(details, 'company.benefits').length === 0 && '-'}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={4}>
            <FaWallet />
          </Col>
          <Col xs={21} md={20}>
            <p className="hp-job-details-attribute-title">Salary</p>
            {loading && <PlaceholderGrid rows={1} columns={1} active />}
            {!loading && (
              <p className="hp-job-details-attribute-content">
                {utils.formatSalaryRange(
                  _.get(details, 'minSalaryRange'),
                  _.get(details, 'maxSalaryRange'),
                )}
              </p>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={3} md={4}>
            <FaInfoCircle />
          </Col>
          <Col xs={21} md={20}>
            <p className="hp-job-details-attribute-title">Culture</p>
            {loading && <PlaceholderGrid rows={1} columns={1} active />}
            {!loading && (
              <p style={{ marginBottom: '8px' }}>
                {_.get(details, 'company.cultures').length > 1 &&
                  _.reduce(
                    _.get(details, 'company.cultures') || [],
                    (c: string, val: any) => {
                      if (_.isEmpty(c)) {
                        return `${val.name} `;
                      }

                      return `${c}, ${val.name}`;
                    },
                    '',
                  )}

                {_.get(details, 'company.cultures').length === 1 &&
                  _.get(details, 'company.cultures.0.name')}
                {_.get(details, 'company.cultures').length === 0 && '-'}
              </p>
            )}
          </Col>
        </Row>
      </Stack>
    </Panel>
  );

  renderMainContent = (
    { details, loading } = this.state,
  ): JSX.Element | null => (
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={19}
      className="hp-job-hiring-partner-main-content"
    >
      <Col xs={24}>
        <a className="back-btn" href="/dashboard/hiring-partner/mycompany">
          <FaChevronLeft />
          <span>Back to Other Job Opening</span>
        </a>
      </Col>
      <Col xs={24} md={24} lg={24}>
        <Panel bodyFill className="hp-job-details-wrapper-card">
          <div className="card-header-wrapper">
            {_.get(details, 'company.background.url') ? (
              <img
                src={_.get(details, 'company.background.url')}
                alt="card-header"
                width="100%"
              />
            ) : (
              utils.companyBg(_.get(details, 'company.industry.name') || '')
            )}
          </div>
          {!loading && (
            <div className="header-company-details">
              <h5>{_.get(details, 'title')}</h5>
              <h3>{_.get(details, 'company.name')}</h3>
            </div>
          )}
          <Panel className="hp-job-details-card" style={{ paddingTop: '10px' }}>
            <br />
            <Stack spacing={8}>
              <p className="hp-job-details-title">Edit Job Status</p>
            </Stack>
            <SelectPicker
              data={[
                {
                  label: 'Active',
                  value: 1,
                },
                {
                  label: 'Closed this job opening',
                  value: 0,
                },
              ]}
              value={_.get(details, 'active')}
              onChange={(v: any) => {
                this.updateJobStatus(v);
              }}
              placeholder="Active"
              searchable={false}
              style={{ width: '25rem' }}
            />
            <br />
            <Button
              className="edit-job-btnlink"
              appearance="link"
              disabled={!_.get(details, 'active')}
              onClick={this.openEditJobPostingModal}
            >
              Edit Job Information
              <img src="/icon-edit.png" alt="edit-logo" />
            </Button>
            <br />
            <br />
            <br />
            <Stack spacing={8}>
              <FaLightbulb />
              <p
                className="hp-job-details-title"
                style={{ marginBottom: '-2px', marginLeft: '8px' }}
              >
                What You Are Going To Do?
              </p>
            </Stack>
            <Divider />
            {_.get(this.state, 'loading') && (
              <Stack spacing={8} className="loading-skeleton">
                <PlaceholderGrid rows={3} columns={1} active />
              </Stack>
            )}
            {!_.get(this.state, 'loading') && (
              <Stack spacing={8}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: _.get(details, 'description') || '-',
                  }}
                />
              </Stack>
            )}

            <br />
            <br />
            <Stack spacing={8}>
              <FaBook />
              <p
                className="hp-job-details-title"
                style={{ marginBottom: '-2px', marginLeft: '8px' }}
              >
                Requirements
              </p>
            </Stack>
            <Divider />
            {_.get(this.state, 'loading') && (
              <Stack spacing={8} className="loading-skeleton">
                <PlaceholderGrid rows={1} columns={1} active />
              </Stack>
            )}
            {!_.get(this.state, 'loading') && (
              <Stack spacing={8}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: _.get(details, 'requirements') || '-',
                  }}
                />
              </Stack>
            )}

            <br />
          </Panel>
        </Panel>
      </Col>

      <Col xs={24} md={24} lg={16}>
        <Panel className="hp-job-details-card">
          <br />
          <Stack spacing={8}>
            <p className="hp-job-details-title">About the Company</p>
          </Stack>
          {_.get(this.state, 'loading') && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={3} columns={1} active />
            </Stack>
          )}
          {!_.get(this.state, 'loading') && (
            <Stack spacing={8}>
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(details, 'company.description') || '-',
                }}
              />
            </Stack>
          )}

          <br />
          <br />
          <Stack spacing={8}>
            <p className="hp-job-details-title">Location</p>
          </Stack>
          {_.get(this.state, 'loading') && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={1} columns={1} active />
            </Stack>
          )}
          {!_.get(this.state, 'loading') && (
            <Stack spacing={8}>
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(details, 'jobLocation.cityName') || '-',
                }}
              />
            </Stack>
          )}

          <br />
          <br />
          <Stack spacing={8}>
            <p className="hp-job-details-title">Industry</p>
          </Stack>
          {_.get(this.state, 'loading') && (
            <Stack spacing={8} className="loading-skeleton">
              <PlaceholderGrid rows={1} columns={1} active />
            </Stack>
          )}
          {!_.get(this.state, 'loading') && (
            <Stack spacing={8}>
              <p
                dangerouslySetInnerHTML={{
                  __html: _.get(details, 'company.industry.name') || '-',
                }}
              />
            </Stack>
          )}

          <br />
          <br />
          <Stack spacing={8}>
            <Button
              onClick={() => {
                window.open('/dashboard/hiring-partner/mycompany', '_self');
              }}
              appearance="link"
              className="learn-more-btn"
            >
              Learn More <FaChevronRight />
            </Button>
          </Stack>

          <br />
        </Panel>
      </Col>

      <Col xs={24} md={24} lg={8}>
        {this.renderAttributesPanel()}
      </Col>
    </Col>
  );

  renderEditPostingModal = (
    { t } = this.props,
    { workingTypes, employmentStatuses, locations, roleCategories } = this
      .state,
  ): JSX.Element | null => (
    <Modal
      backdrop="static"
      open={_.get(this.state, 'showModal')}
      onClose={() => this.closeModal()}
      className="hp-modal"
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <Form
          key="edit-job-posting-form"
          ref={this.formEditPostingRef}
          model={this.formEditPostingSchemaModel}
          onChange={(v: object) => this.onFormValueChanged(v, 'payload')}
          formValue={_.get(this.state, 'payload')}
          fluid
        >
          {/* Job Title */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobTitleKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                autoComplete="off"
                name="title"
                value={_.get(this.state, 'payload.title')}
                placeholder="Enter the job title"
              />
            </Col>
          </Row>

          <br />

          {/* Role Category */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formRoleCategoryKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="roleCategoryId"
                accepter={InputPicker}
                data={_.map(roleCategories, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'payload.roleCategoryId')}
                placeholder="Select the role category"
              />
            </Col>
          </Row>

          <br />

          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobLocationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                name="locationId"
                accepter={SelectPicker}
                appearance="default"
                data={_.map(locations, (m) => ({
                  label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                  value: _.get(m, 'id'),
                }))}
                onOpen={() => {
                  if (locations.length === 0) {
                    this.getLocations();
                  }
                }}
                onSearch={(keyword: string) => {
                  if (keyword !== '') {
                    this.getLocations(keyword);
                  }
                }}
                renderMenu={(menu) => {
                  if (locations.length === 0) {
                    return (
                      <p
                        style={{
                          padding: 4,
                          color: '#999',
                          textAlign: 'center',
                        }}
                      >
                        <Loader /> Loading...
                      </p>
                    );
                  }
                  return menu;
                }}
                size="sm"
                style={{ width: '100%' }}
                placeholder="Choose Location"
              />
            </Col>
          </Row>

          <br />

          {/* Working type */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyWorkingTypesKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                style={{ color: 'black', fontWeight: 400, width: '100%' }}
                name="workingType"
                accepter={InputPicker}
                data={_.map(workingTypes, (c: any) => ({
                  label: c.name,
                  value: c.id,
                }))}
                value={_.get(this.state, 'payload.workingType')}
                placeholder="Select the working type"
              />
            </Col>
          </Row>

          <br />

          {/* Employment status */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formCompanyEmployStatusKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'payload.empStatus')}
                name="empStatus"
                accepter={RadioGroup}
              >
                {employmentStatuses.map((e: any) => (
                  <Radio value={e.id}>{e.name}</Radio>
                ))}
              </Form.Control>
            </Col>
          </Row>

          <br />

          {/* Duration */}
          <Row>
            <Col xs={24} style={{ marginBottom: '8px' }}>
              <Form.ControlLabel>
                {t(constant.translation.general.formJobDurationKey)}
              </Form.ControlLabel>
            </Col>
            <Col xs={24}>
              <Form.Control
                value={_.get(this.state, 'payload.duration')}
                name="duration"
                accepter={SelectPicker}
                style={{ width: '100%' }}
                data={_.range(0, 13).map((v) => ({
                  label: v === 0 ? 'N/A (Permanent)' : `${v} Month(s)`,
                  value: v,
                }))}
              />
            </Col>
          </Row>

          <br />

          {/* Year of experience */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formYOEKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="minYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Min"
                />
              </Col>
              <Col xs={12}>
                <Form.Control
                  name="maxYoe"
                  accepter={InputPicker}
                  data={_.range(0, 21).map((value) => ({
                    label: String(value),
                    value,
                  }))}
                  placeholder="Max"
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Salary range */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryRangeKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  name="salaryRange"
                  accepter={RangeSlider}
                  min={500000}
                  step={50000}
                  max={20000000}
                  renderTooltip={(value: number | undefined) =>
                    utils.formatCurrency(value)
                  }
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Show/Hide salary */}
          <Row>
            <Col xs={24} style={{ marginBottom: '12px' }}>
              <Form.Group>
                <Col xs={24}>
                  <Form.ControlLabel>
                    {t(constant.translation.general.formSalaryDiscloseKey)}
                  </Form.ControlLabel>
                </Col>
                <Form.Control
                  style={{ color: 'black', fontWeight: 400, width: '100%' }}
                  name="isShowSalary"
                  accepter={InputPicker}
                  data={[
                    {
                      label: 'Yes',
                      value: 1,
                    },
                    {
                      label: 'No',
                      value: 0,
                    },
                  ]}
                  defaultValue={1}
                  value={_.get(this.state, 'payload.isShowSalary')}
                  placeholder="Show Salary?"
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          {/* Custom Technical Test problem */}
          <Row>
            <Form.Group>
              <Form.ControlLabel style={{ marginBottom: '8px' }}>
                {t(constant.translation.general.formCustomTechnicalTest)}
              </Form.ControlLabel>
              <Form.Control
                name="technicalTest"
                placeholder="Enter your custom technical test (URL)"
              />
            </Form.Group>
          </Row>

          <br />

          {/* Responsibility */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobResponsibilityKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.responsibilityRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'payload.description')}
                  onChange={(value: any) => {
                    this.setState({
                      payload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.payload,
                        description: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>

          <br />
          {/* Job Requirement */}
          <Row>
            <Form.Group>
              <Col xs={24} style={{ marginBottom: '8px' }}>
                <Form.ControlLabel>
                  {t(constant.translation.general.formJobRequirementKey)}
                </Form.ControlLabel>
              </Col>
              <Col xs={24}>
                <ReactQuill
                  ref={(e) => {
                    this.requirementRef = e?.getEditor();
                  }}
                  style={{ border: '1px solid #b8b8b8' }}
                  theme="snow"
                  value={_.get(this.state, 'payload.requirements')}
                  onChange={(value: any) => {
                    this.setState({
                      payload: {
                        // eslint-disable-next-line max-len
                        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
                        ...this.state.payload,
                        requirements: value,
                      },
                    });
                  }}
                />
              </Col>
            </Form.Group>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => this.closeModal()} appearance="subtle">
          Cancel
        </Button>
        <Button onClick={this.submitEditJobPosting} appearance="primary">
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderEditPostingModal()}
          {this.renderSideTabs()}
          {this.renderMainContent()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerJobDetails));
