import { Component, ReactNode } from 'react';
import { analytics } from '@helper';

type Props = {
  children: ReactNode;
};

type State = {
  //
};

class Analytic extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {
    analytics.pageViewTracking();
  }

  render(): ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default Analytic;
