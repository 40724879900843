import moment from 'moment';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import TRANSLATION_EN from '../../translations/en/translation.json';
import TRANSLATION_ID from '../../translations/id/translation.json';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    debug: process.env.NODE_ENV === 'development',
    resources: {
      en: {
        translation: TRANSLATION_EN,
      },
      id: {
        translation: TRANSLATION_ID,
      },
    },
    fallbackLng: 'id',
    lng: 'en',
    interpolation: {
      format(value, format) {
        if (value instanceof Date) return moment(value).format(format);
        if (typeof value === 'number')
          return new Intl.NumberFormat().format(value);
        return value;
      },
    },
  });

export default i18n;
