import _ from 'lodash';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  Panel,
  Schema,
  toaster,
} from 'rsuite';
import { constant, util } from '@helper';
import { AuthAPI } from '@services/api';
import constants from '@helper/constants';

const { withRouter } = util;

type State = {
  email: string;
  resendEmailVerify: object;
};

const { StringType } = Schema.Types;

class EmailVerifyScreen extends React.Component<any, State> {
  formEmailVerifyRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formEmailVerifySchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      email: '',
      resendEmailVerify: {},
    };

    this.formEmailVerifyRef = React.createRef();
    this.formEmailVerifySchemaModel = Schema.Model({
      email: StringType()
        .isEmail('Invalid email address format')
        .isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }

    const { location, navigate } = this.props;
    const { state } = location;
    const email = _.get(state, 'email');

    if (email) {
      this.setState({ email, resendEmailVerify: { email } });
    } else {
      navigate('/login');
    }
  }

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  resendEmailVerification = async (): Promise<void> => {
    const { resendEmailVerify: payload } = this.state;
    const { t } = this.props;

    if (!_.invoke(this.formEmailVerifyRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.profile.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      await AuthAPI.resendEmailVerification(payload);

      toaster.push(
        util.buildMessage(
          t(constant.translation.login.formSuccessSendEmailVerifyKey),
        ),
      );
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  render({ t } = this.props): JSX.Element | null {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="email-verify-container"
      >
        <FlexboxGrid.Item as={Col} colspan={8} xs={24}>
          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />

          <FlexboxGrid.Item as={Col} xs={22} sm={16} md={14} lg={8}>
            <img src="bg-email-verify.png" alt="bg" />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={8} xs={24} sm={24} md={16} lg={8}>
          <Panel
            className="email-verify-panel"
            header={
              <div>
                <span>Check Your Email for An Verification Link</span>
                <small>
                  Didn’t receive the email? Please check your spam folder or try
                  to resend the email.
                </small>
              </div>
            }
            bordered
          >
            <Form
              key="email-verify-form"
              fluid
              onChange={(v: object) =>
                this.onFormValueChanged(v, 'resendEmailVerify')
              }
              ref={this.formEmailVerifyRef}
              model={this.formEmailVerifySchemaModel}
            >
              <p className="email-verify-section">
                <Form.Group>
                  <Form.ControlLabel>
                    {t(constants.translation.general.formEmailKey)}
                  </Form.ControlLabel>
                  <Form.Control
                    name="email"
                    placeholder="Enter your email address"
                  />
                </Form.Group>
                <Button
                  className="btn-tg-primary"
                  onClick={this.resendEmailVerification}
                  block
                  appearance="primary"
                >
                  Resend Email
                </Button>
              </p>
              <br />
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(EmailVerifyScreen));
