import moment from 'moment';
import _ from 'lodash';
import React, { ReactNode } from 'react';
import { withTranslation } from 'react-i18next';
import { Col, FlexboxGrid, Row, Stack } from 'rsuite';

import textLogo from '@assets/logo-white.png';
import initialLogo from '@assets/logo-initial.png';
import { constant, util } from '@helper';

import footerMenusJson from './footer-menus.json';
import socialMenusJson from './footer-social-menus.json';
import './styles.scss';

const { withRouter } = util;

class Footer extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      socialMenus: socialMenusJson,
      footerMenus: footerMenusJson,
    };
  }

  render(): ReactNode | null {
    const { socialMenus, footerMenus } = this.state;
    const { t, location } = this.props;
    const pathname = _.get(location, 'pathname');

    const isHomepage = _.includes(
      [
        '/',
        '/hiring-partner',
        '/candidate',
        '/privacy-policy',
        '/terms-condition',
      ],
      pathname,
    );

    if (!isHomepage) {
      return null;
    }

    return (
      <FlexboxGrid justify="space-around" className="footer">
        {/* Left Side */}
        <FlexboxGrid.Item as={Col} xs={24} sm={24} md={14} lg={14}>
          <Row>
            {/* TalentGrowth Logo */}
            <Col xs={24} sm={24} md={24} lg={24}>
              <img className="logo" alt="" src={initialLogo} />
              <img className="logo-text" alt="" src={textLogo} />
            </Col>

            {/* Desc Text */}
            <Col xs={24} sm={24} md={24} lg={24}>
              <p className="description-text">
                Democratize internship and full-time opportunities <br /> for
                pre-screened skilled talents
              </p>
            </Col>

            {/* Social Medias */}
            <Col xs={24} sm={24} md={24} lg={24}>
              <Stack spacing={14}>
                {_.map(socialMenus || [], (menu) => (
                  <Stack.Item key={_.get(menu, 'url')}>
                    <a
                      href={_.get(menu, 'url')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        className="social-media-logo"
                        alt={_.get(menu, 'alt')}
                        src={_.get(menu, 'imageUrl')}
                      />
                    </a>
                  </Stack.Item>
                ))}
              </Stack>
            </Col>

            {/* Copyright */}
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{
                width: '100vw',
                marginLeft: '-3rem',
                paddingLeft: '3rem',
              }}
            >
              <Row>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <p className="description-text copyright-text">
                    &copy; {moment().format('YYYY')} PT Talenta Tumbuh Nusantara
                  </p>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                  <p className="description-text privacy-policy-text">
                    <a href="/privacy-policy">Privacy Policy</a>{' '}
                    &nbsp;|&nbsp;&nbsp;
                    <a href="/terms-condition">Terms & Condition</a>
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </FlexboxGrid.Item>

        {/* Right Side */}
        <FlexboxGrid.Item as={Col} xs={24} sm={24} md={10} lg={10}>
          <Row>
            {_.map(footerMenus, (menu: any, idx: number) => (
              <Col
                xs={24}
                sm={12}
                md={24}
                lg={12}
                key={menu.keyTitle}
                style={{
                  marginTop:
                    window.innerWidth <= 425 && idx > 0 ? '2rem' : '0px',
                }}
              >
                <h5>{t(_.get(constant, menu.keyTitle))}</h5>

                {/* Children menu */}
                {_.map(menu.children || [], (childMenu: any) => (
                  <a
                    href={childMenu.url}
                    key={childMenu.key}
                    style={{
                      display: 'block',
                      marginBottom: menu.children.length > 1 ? '8px' : '0px',
                    }}
                    // target="_blank"
                    rel="noreferrer"
                  >
                    {t(_.get(constant, childMenu.key))}
                  </a>
                ))}
              </Col>
            ))}
            <Col xs={24} sm={24}>
              <p className="description-text privacy-policy-mobile-text">
                <a href="/privacy-policy">Privacy Policy</a> &nbsp;|&nbsp;&nbsp;
                <a href="/terms-condition">Terms & Condition</a>
              </p>
            </Col>
          </Row>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(Footer));
