// LeavePrompt.tsx
import { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';

type LeavePromptParam = {
  hasUnsavedChanges: boolean;
  backHandler: () => void;
};

function LeavePrompt({
  hasUnsavedChanges,
  backHandler,
}: LeavePromptParam): any {
  const [isLeaveModalShow, showLeaveModal] = useState<boolean>(false);

  const renderLeaveModal = (): JSX.Element => (
    <Modal
      backdrop="static"
      open={isLeaveModalShow}
      onClose={() => {
        showLeaveModal(false);
      }}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <p style={{ marginTop: '2em', textAlign: 'justify' }}>
          Are you sure you want to exit? Leaving this page now may result in
          failing the test and prevent you from retaking it.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            showLeaveModal(false);
          }}
          appearance="subtle"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            showLeaveModal(false);
            backHandler();
          }}
          appearance="primary"
        >
          Yes, exit
        </Button>
      </Modal.Footer>
    </Modal>
  );

  useEffect(() => {
    const onBeforeUnload = (e: any): any => {
      if (hasUnsavedChanges) {
        e?.preventDefault();
        e.returnValue = '';

        window.history.pushState(null, '', window.location.pathname);

        showLeaveModal(true);
      }
    };

    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener('popstate', onBeforeUnload);
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('popstate', onBeforeUnload);
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  return isLeaveModalShow ? renderLeaveModal() : null;
}

export default LeavePrompt;
