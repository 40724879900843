import { withTranslation } from 'react-i18next';
import { util } from '@helper';
import React from 'react';
import _ from 'lodash';
import {
  FaHome,
  FaBuilding,
  FaSuitcase,
  FaFileAlt,
  FaUser,
  FaClock,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import {
  Col,
  Affix,
  Stack,
  IconButton,
  Grid,
  Row,
  Nav,
  Table,
  Avatar,
  Badge,
  Button,
  FlexboxGrid,
  Panel,
  toaster,
  Pagination,
  Whisper,
  Tooltip,
} from 'rsuite';
import { Promise } from 'bluebird';
import { JobApplicationAPI } from '@services/api';
import InfiniteScroll from 'react-infinite-scroller';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';
import constants from '@helper/constants';

const { withRouter } = util;

type State = {
  tabKey: string;

  inprogressList: Array<any>;
  inprogressOpts: undefined | object;
  inprogressPage: number;
  totalInprogress: number;

  savedList: Array<any>;

  historyList: Array<any>;
  historyOpts: undefined | object;
  historyPage: number;
  totalHistory: number;

  // Saved property
  loading: boolean;
  savedHasMorePage: boolean;
  loadingCards: boolean;
};

class CandidateMyApplicationScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      tabKey: 'inprogress',

      inprogressPage: 1,
      inprogressList: [],
      inprogressOpts: {},
      totalInprogress: 0,

      savedList: [],

      // eslint-disable-next-line react/no-unused-state
      historyPage: 1,
      historyList: [],
      historyOpts: {},
      totalHistory: 0,

      loadingCards: true,
      loading: true,
      savedHasMorePage: false,
    };
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getInprogressApplications();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getHistoryApplications();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getHistoryApplications = async (): Promise<void> => {
    const response = await JobApplicationAPI.history(1);
    const data = _.get(response, 'data') || [];
    const total = Number(_.get(response, 'meta.total') || 0);
    const historyOpts = _.get(response, 'meta.options');

    this.setState({
      historyOpts,
      loading: false,
      totalHistory: total,
      historyList: data,
    });
  };

  private getSavedApplications = async (page = 1): Promise<void> => {
    try {
      const response = await JobApplicationAPI.saved(page <= 1 ? 1 : page);
      const data = _.get(response, 'data') || [];
      const totalPage = Number(_.get(response, 'meta.totalPage') || 0);
      const total = Number(_.get(response, 'meta.total') || 0);

      this.setState({
        savedList: data,
        loadingCards: false,
        savedHasMorePage: page < totalPage && total > 0,
      });
    } catch (e) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private getInprogressApplications = async (
    { inprogressPage } = this.state,
  ): Promise<void> => {
    const response = await JobApplicationAPI.inprogress(inprogressPage);
    const data = _.get(response, 'data') || [];
    const inprogressOpts = _.get(response, 'meta.options');
    const totalInprogress = _.get(response, 'meta.total') || 0;

    this.setState({
      inprogressOpts,
      totalInprogress,
      loading: false,
      inprogressList: data,
    });
  };

  private onSaveOrRemoveApplication = async (
    jobId: number,
    isSaved: boolean,
  ): Promise<void> => {
    try {
      let message;

      if (isSaved) {
        await JobApplicationAPI.remove(jobId);
        message = 'Job posting has been removed from your bookmark list!';
      } else {
        await JobApplicationAPI.save(jobId);
        message = 'Job posting has been added to your bookmark list!';
      }

      toaster.push(util.buildMessage(message));

      this.getSavedApplications(1);
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={6} md={6} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/search-companies',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/my-application',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderTab = (key: string): Array<JSX.Element> | JSX.Element | null => {
    switch (key) {
      case 'inprogress':
        return this.renderInProgressApplication();
      case 'saved':
        return this.renderSavedApplication();
      case 'history':
        return this.renderHistoryApplication();
      default:
        return null;
    }
  };

  private renderInProgressApplication = (): JSX.Element | null => {
    const {
      inprogressList,
      inprogressPage,
      inprogressOpts,
      totalInprogress,
      loading,
    } = this.state;

    return (
      <>
        <Table
          autoHeight
          loading={loading}
          rowHeight={(rowData: any) => {
            const readablestatus = _.get(inprogressOpts, rowData?.lastStatus);

            if (
              String(readablestatus).length <= 29 &&
              String(readablestatus).length >= 17
            ) {
              return 100;
            }

            if (String(readablestatus).length >= 29) {
              return 120;
            }
            return 80;
          }}
          data={inprogressList}
          bordered
          headerHeight={80}
          onRowClick={(rowData) => {
            const { navigate } = this.props;
            navigate(`/dashboard/candidate/my-application/${rowData.id}`);
          }}
        >
          <Table.Column flexGrow={1} align="center">
            <Table.HeaderCell>Date Applied</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return String(rowData.appliedDate).substring(0, 12);
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.Cell fullText>
              {(rowData) => {
                return (
                  <Row style={{ marginTop: '-12px' }}>
                    <Col xs={8}>
                      <Avatar
                        circle
                        key={_.get(rowData, 'company.logo.url')}
                        size="md"
                        src={_.get(rowData, 'company.logo.url')}
                        alt="logo-startup"
                      />
                    </Col>
                    <Col
                      xs={16}
                      style={{ paddingTop: '12px', textAlign: 'left' }}
                    >
                      {_.get(rowData, 'company.name')}
                    </Col>
                  </Row>
                );
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.Cell fullText dataKey="jobPosting.title" />
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell fullText dataKey="jobPosting.employmentStatus.0.name" />
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return typeof inprogressOpts === 'object' &&
                  !_.isNull(inprogressOpts) ? (
                  <div
                    className={`btn-tg-pill-shaped ${
                      String(rowData.lastStatus).includes('ACCEPT') ||
                      String(rowData.lastStatus).includes('OFFER')
                        ? 'success'
                        : ''
                    } ${
                      rowData.lastStatus ===
                        'WAITING_FOR_TRANSFER_JOB_CONFIRMATION' && 'waiting'
                    }`}
                  >
                    {_.get(inprogressOpts, rowData.lastStatus)}
                  </div>
                ) : (
                  ''
                );
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', '|', 'pager']}
          total={totalInprogress}
          limit={10}
          activePage={inprogressPage}
          onChangePage={(page: number) =>
            this.setState({ inprogressPage: page }, () =>
              this.getInprogressApplications(),
            )
          }
        />
      </>
    );
  };

  private renderHistoryApplication = (): JSX.Element | null => {
    const { historyList, historyOpts, totalHistory, historyPage, loading } =
      this.state;

    return (
      <>
        <Table
          autoHeight
          rowHeight={(rowData: any) => {
            const readablestatus = _.get(historyOpts, rowData?.lastStatus);

            if (
              String(readablestatus).length <= 29 &&
              String(readablestatus).length >= 17
            ) {
              return 100;
            }

            if (String(readablestatus).length >= 29) {
              return 120;
            }
            return 80;
          }}
          loading={loading}
          data={historyList}
          bordered
          headerHeight={80}
          onRowClick={(rowData) => {
            const { navigate } = this.props;
            navigate(`/dashboard/candidate/my-application/${rowData.id}`);
          }}
        >
          <Table.Column flexGrow={1} align="center">
            <Table.HeaderCell>Date Applied</Table.HeaderCell>
            <Table.Cell>
              {(rowData) => {
                return String(rowData.appliedDate).substring(0, 12);
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.Cell fullText>
              {(rowData) => {
                return (
                  <Row style={{ marginTop: '-12px' }}>
                    <Col xs={8}>
                      <Avatar
                        circle
                        key={_.get(rowData, 'company.logo.url')}
                        size="md"
                        src={_.get(rowData, 'company.logo.url')}
                        alt="logo-startup"
                      />
                    </Col>
                    <Col
                      xs={16}
                      style={{ paddingTop: '12px', textAlign: 'left' }}
                    >
                      {_.get(rowData, 'company.name')}
                    </Col>
                  </Row>
                );
              }}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Position</Table.HeaderCell>
            <Table.Cell fullText dataKey="jobPosting.title" />
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell fullText dataKey="jobPosting.employmentStatus.0.name" />
          </Table.Column>

          <Table.Column flexGrow={1}>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.Cell>
              {(h) => {
                return String(h.lastStatus).includes('REJECT') ||
                  String(h.lastStatus).includes('EXPIRE') ? (
                  <div className="btn-tg-pill-shaped failed">
                    {historyOpts ? _.get(historyOpts, h.lastStatus, '') : ''}
                  </div>
                ) : (
                  <div className="btn-tg-pill-shaped success">
                    {historyOpts ? _.get(historyOpts, h.lastStatus, '') : ''}
                  </div>
                );
              }}
            </Table.Cell>
          </Table.Column>
        </Table>
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={5}
          size="xs"
          layout={['total', '-', '|', 'pager']}
          total={totalHistory}
          limit={10}
          activePage={historyPage}
          onChangePage={(page: number) => {
            this.setState({ historyPage: page }, () => {
              this.getHistoryApplications();
            });
          }}
        />
      </>
    );
  };

  private renderSavedApplication = ():
    | Array<JSX.Element>
    | JSX.Element
    | null => {
    const {
      savedList,
      savedHasMorePage: hasMorePage,
      loadingCards,
    } = this.state;
    const { t } = this.props;
    const width = window.innerWidth;

    let size = 3;
    let threshold = 1 * window.innerHeight;
    if (width <= 425) {
      size = 1;
    } else if (width <= 1280) {
      size = 2;
    } else {
      threshold = 300;
      size = 3;
    }
    const chunked = _.chunk(savedList, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={threshold}
        loadMore={(page) => this.getSavedApplications(page)}
        hasMore={hasMorePage}
        className="saved-job-main-content"
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loadingCards && (
          <Row className="loader-container">
            {_.range(0, 6).map((i: number) => (
              <Col key={`loader-${i}`} xs={24} sm={12} md={12} lg={8}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loadingCards && savedList.length === 0 && (
          <p className="no-results-text">No results</p>
        )}

        {_.map(chunked, (data: Array<any>, index: number) => (
          <Row
            className="search-results-row"
            key={`row-searchresults-${index}`}
          >
            {_.map(data, (o: any, idx: number) => {
              const obj = _.get(o, 'jobPosting');
              const employmentStatus = _.get(obj, 'employmentStatus.0.name');
              const company = _.get(obj, 'company');
              const companyLogo = _.get(obj, 'company.logo.url');
              const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
              const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
              const duration = _.get(obj, 'duration');
              const jobId = _.get(obj, 'id');
              const isSaved = _.get(obj, 'isSaved');
              const isHot = _.get(obj, 'isHot');
              const workingTypes: Array<any> = _.get(obj, 'workingTypes') || [];

              return (
                <Col
                  key={`col-searchresults-${idx}`}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                >
                  <Panel className="search-result-card">
                    <Stack
                      direction="column"
                      spacing={20}
                      style={{ alignItems: 'flex-start' }}
                    >
                      {/* Badge */}
                      <Stack spacing={8}>
                        {isHot ? <Badge content="New" /> : <span />}
                        <Badge
                          className={
                            employmentStatus === 'Full Time'
                              ? 'rs-badge-fulltime'
                              : ''
                          }
                          content={employmentStatus}
                        />
                        {workingTypes.map((val: any) => (
                          <Badge
                            key={`workingTypes-${val.name}`}
                            content={val.name}
                          />
                        ))}
                      </Stack>

                      {/* Company Information */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={7}>
                            <Avatar
                              circle
                              size="lg"
                              src={companyLogo}
                              alt="logo-startup"
                            />
                          </Col>
                          <Col xs={17} className="company-info">
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'name')}</b>
                                </Tooltip>
                              }
                            >
                              <p>{_.get(company, 'name')}</p>
                            </Whisper>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(obj, 'title')}</b>
                                </Tooltip>
                              }
                            >
                              <b>{_.get(obj, 'title')}</b>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Job Details */}
                      <Stack direction="column" spacing={6}>
                        <Row>
                          <Col xs={3}>
                            <FaMapMarkerAlt />
                          </Col>
                          <Col xs={21}>
                            <p>{_.get(company, 'region')}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaClock />
                          </Col>
                          <Col xs={21}>
                            {duration > 0 && <p>{duration} month(s)</p>}
                            {duration === 0 && <p>Full Time</p>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaBuilding />
                          </Col>
                          <Col xs={21}>
                            <p>{_.get(company, 'industry.name')}</p>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Salary range */}
                      <Stack spacing={6}>
                        <p className="salary-range">
                          {util.formatSalaryRange(salaryLow, salaryHigh)}
                        </p>
                      </Stack>

                      {/* Job Card Buttons */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={19}>
                            <Button
                              className="btn-tg-primary"
                              block
                              disabled={!obj.active}
                              appearance="primary"
                              onClick={() => {
                                // eslint-disable-next-line max-len
                                const url = `/dashboard/candidate/jobs/${jobId}`;
                                window.open(url);
                              }}
                            >
                              {_.isEqual(obj.isApplied, true)
                                ? 'Applied'
                                : t(
                                    constants.translation.searchJob
                                      .formApplyButtonKey,
                                  )}
                            </Button>
                          </Col>
                          <Col xs={5}>
                            <IconButton
                              onClick={() =>
                                this.onSaveOrRemoveApplication(jobId, isSaved)
                              }
                              appearance="default"
                              size="lg"
                              disabled={!_.get(obj, 'active')}
                              icon={
                                isSaved ? (
                                  <img
                                    // eslint-disable-next-line max-len
                                    src={`${process.env.PUBLIC_URL}/bookmark-logo-filled.png`}
                                    alt="bookmark"
                                    width={18}
                                    height={24}
                                  />
                                ) : (
                                  <img
                                    // eslint-disable-next-line max-len
                                    src={`${process.env.PUBLIC_URL}/bookmark-logo.png`}
                                    alt="bookmark"
                                    width={18}
                                    height={24}
                                  />
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Stack>
                    </Stack>
                  </Panel>
                </Col>
              );
            })}
          </Row>
        ))}
      </InfiniteScroll>
    );
  };

  private onTabChanged = (tabKey: string): void => {
    this.setState({ tabKey });

    const { inprogressList, historyList } = this.state;

    if (tabKey === 'saved') {
      this.getSavedApplications();
    } else if (tabKey === 'history') {
      if (historyList.length === 0) {
        this.getHistoryApplications();
      }
    } else if (inprogressList.length === 0) {
      this.getInprogressApplications();
    }
  };

  renderMainContent = (): JSX.Element | null => {
    const { tabKey } = this.state;

    return (
      <Col md={24} sm={24} xs={24} lg={18}>
        <Col xs={24}>
          <div className="myapplication-container">
            <Nav
              activeKey={tabKey}
              onSelect={this.onTabChanged}
              style={{ marginBottom: 50 }}
            >
              <Nav.Item eventKey="inprogress">In Progress</Nav.Item>
              <Nav.Item eventKey="history">History</Nav.Item>
              <Nav.Item eventKey="saved">Saved</Nav.Item>
            </Nav>

            {this.renderTab(tabKey)}
          </div>
        </Col>
      </Col>
    );
  };

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CandidateMyApplicationScreen));
