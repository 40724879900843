import React from 'react';
import { withTranslation } from 'react-i18next';
import { constant, util } from '@helper';
import InfiniteScroll from 'react-infinite-scroller';
import {
  Affix,
  Avatar,
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Drawer,
  FlexboxGrid,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Loader,
  Panel,
  RangeSlider,
  Row,
  SelectPicker,
  Stack,
  TagPicker,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite';
import _ from 'lodash';
import {
  FaHome,
  FaBuilding,
  FaSuitcase,
  FaFileAlt,
  FaUser,
  FaArrowRight,
  FaSearch,
  FaMapMarkerAlt,
  FaClock,
  FaTimes,
} from 'react-icons/fa';
import { CompanyAPI, JobApplicationAPI, MiscAPI } from '@services/api';
import { Promise } from 'bluebird';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';
import { ValueType } from 'rsuite/esm/Checkbox';

const { withRouter } = util;

type State = {
  roleCategories: Array<object>;
  companyIndustries: Array<object>;
  location: Array<object>;

  hasMorePage: boolean;
  loading: boolean;
  loadingCards: boolean;

  filterByIndustries: Array<number>;
  filterByRoleCategory: number;
  results: Array<object>;

  query: string | null;
  showFilterDrawer: boolean;

  workingTypes: Array<object>;
  jobTypes: Array<object>;

  selectedJobTypes: ValueType[];
  selectedSalary: Array<number>;
  selectedExpLevels: ValueType[];
  selectedWorkingTypes: ValueType[];

  selectedLocationId: number;

  isFilterOn: boolean;
};

class CandidateSearchJobScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      companyIndustries: [],
      roleCategories: [],
      filterByIndustries: [],
      filterByRoleCategory: 0,
      location: [],
      results: [],
      loading: true,
      loadingCards: true,
      query: null,
      hasMorePage: false,

      isFilterOn: false,

      workingTypes: [],
      jobTypes: [],

      selectedJobTypes: [],
      selectedExpLevels: [],
      selectedWorkingTypes: [],
      selectedSalary: [500000, 500000],
      selectedLocationId: -1,

      showFilterDrawer: false,
    };
  }

  async componentDidMount(): Promise<void> {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', '#F6F6F6');
    }

    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getRoleCategories();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getLocation();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.searchJobs();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getJobTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getJobTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      jobTypes: data,
    });
  };

  private getWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('COMPANY_INDUSTRY');
    const data = _.get(response, 'data') || [];
    this.setState({
      companyIndustries: data,
      loading: false,
    });
  };

  private getRoleCategories = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('ROLE_CATEGORY');
    const data = _.get(response, 'data') || [];
    this.setState({
      roleCategories: data,
      loading: false,
    });
  };

  private getLocation = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ location: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private searchJobs = async (page = 1): Promise<void> => {
    try {
      const {
        query,
        isFilterOn,
        selectedJobTypes,
        selectedWorkingTypes,
        selectedSalary,
        selectedExpLevels,
        selectedLocationId,
      } = this.state;
      const res = await CompanyAPI.searchJobs(
        query,
        isFilterOn
          ? {
              jobTypes: selectedJobTypes,
              salary: selectedSalary,
              workingTypes: selectedWorkingTypes,
              expLevels: selectedExpLevels,
              locationId: selectedLocationId,
            }
          : {},
        page,
      );
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const total = Number(_.get(res, 'meta.total') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loadingCards: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage && total > 0,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private searchByIndustry = async (page = 1): Promise<void> => {
    try {
      const {
        query,
        filterByIndustries: industryId,
        filterByRoleCategory: categoryId,
        isFilterOn,
        selectedJobTypes,
        selectedSalary,
        selectedWorkingTypes,
        selectedExpLevels,
        selectedLocationId: locationId,
      } = this.state;
      const res = await CompanyAPI.jobListByIndustry(
        industryId,
        categoryId,
        isFilterOn
          ? {
              jobTypes: selectedJobTypes,
              salary: selectedSalary,
              locationId,
              workingTypes: selectedWorkingTypes,
              expLevels: selectedExpLevels,
            }
          : {},
        page,
        query,
      );
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const total = Number(_.get(res, 'meta.total') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loadingCards: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage && total > 0,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private onSaveOrRemoveApplication = async (
    jobId: number,
    isSaved: boolean,
  ): Promise<void> => {
    try {
      let message;

      if (isSaved) {
        await JobApplicationAPI.remove(jobId);
        message = 'Job posting has been removed from your bookmark list!';

        const { results } = this.state;
        const idx = _.findIndex(results, { id: jobId });
        _.set(results, `[${idx}].isSaved`, false);

        this.setState({ results });
      } else {
        await JobApplicationAPI.save(jobId);
        message = 'Job posting has been added to your bookmark list!';

        const { results } = this.state;
        const idx = _.findIndex(results, { id: jobId });
        _.set(results, `[${idx}].isSaved`, true);

        this.setState({ results });
      }

      toaster.push(util.buildMessage(message));
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private redirectTo = (url: string): void => {
    const { navigate } = this.props;
    navigate(url, { replace: true });
  };

  renderSideTabs = ({ location } = this.props): JSX.Element => (
    <Col lg={5} md={5} sm={0} xs={0}>
      <Affix className="tg-affix-side-panel" top={window.innerHeight * 0.15}>
        <Stack spacing={6} direction="column">
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/home')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/home')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaHome />}
          >
            Home
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/search-companies')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/search-companies',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaBuilding />}
          >
            Company
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/search-jobs')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/search-jobs')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaSuitcase />}
          >
            Search Job
          </IconButton>
          <IconButton
            onClick={() =>
              this.redirectTo('/dashboard/candidate/my-application')
            }
            appearance="subtle"
            className={
              _.isEqual(
                location.pathname,
                '/dashboard/candidate/my-application',
              )
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaFileAlt />}
          >
            My Application
          </IconButton>
          <IconButton
            onClick={() => this.redirectTo('/dashboard/candidate/profile')}
            appearance="subtle"
            className={
              _.isEqual(location.pathname, '/dashboard/candidate/profile')
                ? 'tg-btn-active'
                : ''
            }
            icon={<FaUser />}
          >
            Profile
          </IconButton>
        </Stack>
      </Affix>
    </Col>
  );

  renderSearchResults = (): React.ReactNode => {
    const { results, hasMorePage, loadingCards } = this.state;
    const { t } = this.props;
    const width = window.innerWidth;

    let size = 3;
    let threshold = 1 * window.innerHeight;
    if (width <= 425) {
      size = 1;
    } else if (width <= 1280) {
      size = 2;
    } else {
      threshold = 300;
      size = 3;
    }
    const chunked = _.chunk(results, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={threshold}
        loadMore={(page) => {
          const { filterByIndustries, filterByRoleCategory } = this.state;

          if (filterByIndustries instanceof Array<number>) {
            if (filterByIndustries.length > 0 || filterByRoleCategory > 0) {
              this.searchByIndustry(page);
            } else {
              this.searchJobs(page);
            }
          }
        }}
        hasMore={hasMorePage}
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loadingCards && (
          <Row className="loader-container">
            {_.range(0, 6).map((i: number) => (
              <Col key={`loader-${i}`} xs={24} sm={12} md={12} lg={8}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loadingCards && results.length === 0 && (
          <p className="no-results-text">No results</p>
        )}

        {_.map(chunked, (data: Array<any>, index: number) => (
          <Row
            className="search-results-row"
            key={`row-searchresults-${index}`}
          >
            {_.map(data, (obj: any, idx: number) => {
              const employmentStatus = _.get(obj, 'employmentStatus.0.name');
              const company = _.get(obj, 'company');
              const companyLogo = _.get(obj, 'company.logo.url');
              const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
              const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
              const duration = _.get(obj, 'duration');
              const jobId = _.get(obj, 'id');
              const isSaved = _.get(obj, 'isSaved');
              const isHot = _.get(obj, 'isHot');
              const workingTypes: Array<any> = _.get(obj, 'workingTypes') || [];

              return (
                <Col
                  key={`col-searchresults-${idx}`}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                >
                  <Panel className="search-result-card">
                    <Stack
                      direction="column"
                      spacing={20}
                      style={{ alignItems: 'flex-start' }}
                    >
                      {/* Badge */}
                      <Stack spacing={8}>
                        {isHot ? <Badge content="New" /> : <span />}
                        <Badge
                          className={
                            employmentStatus === 'Full Time'
                              ? 'rs-badge-fulltime'
                              : ''
                          }
                          content={employmentStatus}
                        />
                        {workingTypes.map((val: any) => (
                          <Badge
                            key={`workingTypes-${val.name}`}
                            content={val.name}
                          />
                        ))}
                      </Stack>

                      {/* Company Information */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={8}>
                            <Avatar
                              circle
                              size="lg"
                              src={companyLogo}
                              alt="logo-startup"
                            />
                          </Col>
                          <Col xs={16} className="company-info">
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'name')}</b>
                                </Tooltip>
                              }
                            >
                              <p>{_.get(company, 'name')}</p>
                            </Whisper>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(obj, 'title')}</b>
                                </Tooltip>
                              }
                            >
                              <b>{_.get(obj, 'title')}</b>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Job Details */}
                      <Stack direction="column" spacing={6}>
                        <Row>
                          <Col xs={3}>
                            <FaMapMarkerAlt />
                          </Col>
                          <Col xs={21}>
                            <p>{_.get(company, 'region')}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaClock />
                          </Col>
                          <Col xs={21}>
                            {duration > 0 && <p>{duration} month(s)</p>}
                            {duration === 0 && <p>Full Time</p>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaBuilding />
                          </Col>
                          <Col xs={21}>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'industry.name')}</b>
                                </Tooltip>
                              }
                            >
                              <p className="industry-name">
                                {_.get(company, 'industry.name')}
                              </p>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Salary range */}
                      <Stack spacing={6}>
                        <p className="salary-range">
                          {util.formatSalaryRange(salaryLow, salaryHigh)}
                        </p>
                      </Stack>

                      {/* Job Card Buttons */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={19}>
                            <Button
                              className="btn-tg-primary"
                              block
                              appearance="primary"
                              onClick={() => {
                                // eslint-disable-next-line max-len
                                const url = `/dashboard/candidate/jobs/${jobId}`;
                                window.open(url);
                              }}
                              disabled={!obj.active}
                            >
                              {obj.active
                                ? t(
                                    constant.translation.searchJob
                                      .formApplyButtonKey,
                                  )
                                : 'Closed'}
                            </Button>
                          </Col>
                          <Col xs={5}>
                            <IconButton
                              onClick={() =>
                                this.onSaveOrRemoveApplication(jobId, isSaved)
                              }
                              appearance="default"
                              size="lg"
                              disabled={!_.get(obj, 'active')}
                              icon={
                                isSaved ? (
                                  <img
                                    // eslint-disable-next-line max-len
                                    src={`${process.env.PUBLIC_URL}/bookmark-logo-filled.png`}
                                    alt="bookmark"
                                    width={18}
                                    height={24}
                                  />
                                ) : (
                                  <img
                                    // eslint-disable-next-line max-len
                                    src={`${process.env.PUBLIC_URL}/bookmark-logo.png`}
                                    alt="bookmark"
                                    width={18}
                                    height={24}
                                  />
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Stack>
                    </Stack>
                  </Panel>
                </Col>
              );
            })}
          </Row>
        ))}
      </InfiniteScroll>
    );
  };

  renderJobCategories = (): React.ReactNode => {
    const { roleCategories, loading, filterByRoleCategory } = this.state;

    let filtered: _.List<any> | null | undefined = roleCategories;
    if (filterByRoleCategory !== 0) {
      filtered = _.filter(
        roleCategories,
        (c: any) => c.id === filterByRoleCategory,
      );
    }

    return (
      <Grid fluid className="search-job-categories-container">
        <Row className="search-job-categories-title">
          <h1>Explore the Most Popular Job Industries</h1>
        </Row>
        {!loading && filtered.length === 1 && (
          <Row>
            <Button
              appearance="primary"
              className="btn-tg-pill-shaped"
              style={{ marginBottom: '8px' }}
              onClick={() => {
                this.setState({ filterByRoleCategory: 0 });

                const { filterByIndustries } = this.state;
                if (filterByIndustries.length > 0) {
                  this.searchByIndustry(1);
                } else {
                  this.searchJobs(1);
                }
              }}
            >
              <FaTimes /> Clear
            </Button>
          </Row>
        )}
        <Row id="search-job-categories">
          {loading &&
            _.range(0, 5).map((i) => (
              <Col key={`loader-${i}`} md={8} sm={24} xs={24} lg={4}>
                <PlaceholderGraph className="loader-category" active />
              </Col>
            ))}
          {!loading &&
            _.chain(filtered)
              .take(5)
              .map((c: any) => (
                <Col key={`jobcategory-${c.id}`} md={8} sm={24} xs={24} lg={4}>
                  <Panel
                    {...(!_.isNull(c.logo)
                      ? {
                          style: {
                            boxShadow: 'inset 0 0 0 1000px rgba(0,0,0,.35)',
                            whiteSpace: 'break-spaces',
                            backgroundImage: `url(${_.get(c, 'logo.url')})`,
                          },
                        }
                      : null)}
                    onClick={() => {
                      this.setState(
                        {
                          loadingCards: true,
                          filterByRoleCategory: c.id,
                        },
                        () => this.searchByIndustry(),
                      );
                    }}
                  >
                    <h5>{_.get(c, 'name')}</h5>
                    <IconButton appearance="subtle" icon={<FaArrowRight />} />
                  </Panel>
                </Col>
              ))
              .valueOf()}
        </Row>
      </Grid>
    );
  };

  renderMainContent = (): JSX.Element | null => {
    const { location } = this.state;
    const { companyIndustries, filterByIndustries, filterByRoleCategory } =
      this.state;

    return (
      <Col md={24} sm={24} xs={24} lg={19} className="search-job-main-content">
        <Col xs={24}>{this.renderJobCategories()}</Col>

        <Col xs={24}>
          <Col xs={20} sm={8}>
            <InputGroup inside>
              <InputGroup.Button>
                <FaSearch />
              </InputGroup.Button>
              <Input
                name="search"
                placeholder="Job Role"
                onChange={(query: string) => {
                  this.setState({
                    query,
                    loadingCards: true,
                  });
                  const roleCategory = _.get(
                    this.state,
                    'filterByRoleCategory',
                  );
                  const industries = _.get(this.state, 'filterByIndustries');

                  if (industries.length > 0 || roleCategory > 0) {
                    _.debounce(this.searchByIndustry, 1000)();
                  } else {
                    _.debounce(this.searchJobs, 1000)();
                  }
                }}
              />
            </InputGroup>
          </Col>
          <Col xsHidden sm={7}>
            <SelectPicker
              data={_.map(location, (m) => ({
                label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
                value: _.get(m, 'id'),
              }))}
              onOpen={() => {
                if (location.length === 0) {
                  this.getLocation();
                }
              }}
              onSearch={(keyword: string) => {
                if (keyword !== '') {
                  this.getLocation(keyword);
                }
              }}
              onClean={() => {
                this.setState(
                  {
                    selectedLocationId: -1,
                    loadingCards: true,
                    isFilterOn: false,
                  },
                  () => {
                    if (filterByIndustries instanceof Array<number>) {
                      if (
                        filterByIndustries.length > 0 ||
                        filterByRoleCategory > 0
                      ) {
                        this.searchByIndustry(1);
                      } else {
                        this.searchJobs(1);
                      }
                    }
                  },
                );
              }}
              onSelect={(v) => {
                this.setState(
                  {
                    selectedLocationId: Number(v),
                    loadingCards: true,
                    isFilterOn: true,
                  },
                  () => {
                    if (filterByIndustries instanceof Array<number>) {
                      if (
                        filterByIndustries.length > 0 ||
                        filterByRoleCategory > 0
                      ) {
                        this.searchByIndustry(1);
                      } else {
                        this.searchJobs(1);
                      }
                    }
                  },
                );
              }}
              renderMenu={(menu) => {
                if (location.length === 0) {
                  return (
                    <p
                      style={{
                        padding: 4,
                        color: '#999',
                        textAlign: 'center',
                      }}
                    >
                      <Loader /> Loading...
                    </p>
                  );
                }
                return menu;
              }}
              placeholder="Choose Location"
            />
          </Col>
          <Col xsHidden sm={7}>
            <TagPicker
              data={_.map(companyIndustries, (c: any) => ({
                label: c.name,
                value: c.id,
              }))}
              onChange={(val) => {
                this.setState(
                  {
                    filterByIndustries: val,
                  },
                  () => {
                    if (val instanceof Array<number>) {
                      if (val.length > 0) {
                        this.searchByIndustry(1);
                      } else {
                        this.searchJobs(1);
                      }
                    }

                    if (val === null) {
                      this.searchJobs(1);
                    }

                    if (filterByRoleCategory > 0) {
                      this.searchByIndustry(1);
                    }
                  },
                );
              }}
              value={filterByIndustries}
              placeholder="Select Industry"
            />
          </Col>
          <Col xs={3} sm={2} style={{ textAlign: 'center' }}>
            <IconButton
              icon={
                <img
                  // eslint-disable-next-line max-len
                  src={`${process.env.PUBLIC_URL}/icon-btn-filter.png`}
                  alt="filter"
                  width={33}
                  height={22}
                />
              }
              onClick={() => this.setState({ showFilterDrawer: true })}
              className="filter-btn"
              circle
              size="lg"
            />
          </Col>
        </Col>

        <Col xs={24}>{this.renderSearchResults()}</Col>
      </Col>
    );
  };

  renderDrawerFilter = (
    {
      showFilterDrawer,
      jobTypes,
      workingTypes,
      selectedWorkingTypes,
      selectedJobTypes,
      selectedExpLevels,
      selectedSalary,
    } = this.state,
    { t } = this.props,
  ): JSX.Element => (
    <Drawer
      backdrop
      size="xs"
      open={showFilterDrawer}
      onClose={() => {
        if (
          selectedSalary[0] === 500000 &&
          selectedSalary[1] === 500000 &&
          selectedJobTypes.length === 0 &&
          selectedWorkingTypes.length === 0 &&
          selectedExpLevels.length === 0
        ) {
          this.setState({ isFilterOn: false }, () => {
            const { filterByRoleCategory, filterByIndustries } = this.state;

            if (filterByRoleCategory > 0 || filterByIndustries.length > 0) {
              this.searchByIndustry(1);
            } else {
              this.searchJobs(1);
            }
          });
        }

        this.setState({ showFilterDrawer: false });
      }}
    >
      <Drawer.Body>
        <Row>
          <Col xs={12}>
            <b className="filter-title">Filter</b>
          </Col>
          <Col xs={12}>
            <Button
              className="clear-all"
              appearance="link"
              onClick={() =>
                this.setState({
                  selectedJobTypes: [],
                  selectedWorkingTypes: [],
                  selectedSalary: [500000, 500000],
                  selectedExpLevels: [],
                })
              }
            >
              Clear All
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Panel>
            <Row>
              <Col xs={15}>
                <b>Job Type</b>
              </Col>
              <Col xs={9}>
                <Button
                  className="clear-all"
                  appearance="link"
                  onClick={() => this.setState({ selectedJobTypes: [] })}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <CheckboxGroup
              name="jobTypeCb"
              value={selectedJobTypes}
              onChange={(value) => {
                this.setState({ selectedJobTypes: value });
              }}
            >
              {_.map(jobTypes, (val: any) => (
                <Checkbox key={val.id} value={val.id}>
                  {val.name}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Panel>
        </Row>
        <br />
        <Row>
          <Panel>
            <Row>
              <Col xs={15}>
                <b>Location</b>
              </Col>
              <Col xs={9}>
                <Button
                  className="clear-all"
                  appearance="link"
                  onClick={() => this.setState({ selectedWorkingTypes: [] })}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <CheckboxGroup
              name="locationCb"
              value={selectedWorkingTypes}
              onChange={(value) => {
                this.setState({ selectedWorkingTypes: value });
              }}
            >
              {_.map(workingTypes, (val: any) => (
                <Checkbox key={val.id} value={val.id}>
                  {val.name}
                </Checkbox>
              ))}
            </CheckboxGroup>
          </Panel>
        </Row>
        <br />
        <Row>
          <Panel>
            <Row>
              <Col xs={15}>
                <b>Experience Level</b>
              </Col>
              <Col xs={9}>
                <Button
                  className="clear-all"
                  appearance="link"
                  onClick={() => this.setState({ selectedExpLevels: [] })}
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <CheckboxGroup
              name="expLevelCb"
              value={selectedExpLevels}
              onChange={(value) => {
                this.setState({ selectedExpLevels: value });
              }}
            >
              <Checkbox value="noexp">
                No experience (Student/Freshgrad)
              </Checkbox>
              <Checkbox value="junior">Junior (1-3 YoE)</Checkbox>
              <Checkbox value="mid">Mid (4-5 YoE)</Checkbox>
              <Checkbox value="senior">Senior ({'>'}5 YoE)</Checkbox>
            </CheckboxGroup>
          </Panel>
        </Row>
        <br />
        <Row>
          <Panel>
            <Row>
              <Col xs={15}>
                <b>Salary</b>
              </Col>
              <Col xs={9}>
                <Button
                  className="clear-all"
                  appearance="link"
                  onClick={() =>
                    this.setState({ selectedSalary: [500000, 500000] })
                  }
                >
                  Clear
                </Button>
              </Col>
            </Row>
            <div style={{ paddingLeft: '6%', paddingRight: '6%' }}>
              <RangeSlider
                name="salaryRange"
                value={[selectedSalary[0], selectedSalary[1]]}
                min={500000}
                step={500000}
                max={50500000}
                renderTooltip={(value: number | undefined) =>
                  util.formatCurrency(value)
                }
                onChange={(value) => {
                  this.setState({
                    selectedSalary: [value[0].valueOf(), value[1].valueOf()],
                  });
                }}
              />
            </div>
          </Panel>
        </Row>
        <br />
        <br />
        <Button
          className="btn-tg-primary"
          block
          appearance="primary"
          disabled={
            selectedSalary[0] === 500000 &&
            selectedSalary[1] === 500000 &&
            selectedJobTypes.length === 0 &&
            selectedWorkingTypes.length === 0 &&
            selectedExpLevels.length === 0
          }
          onClick={() => {
            this.setState(
              { isFilterOn: true, showFilterDrawer: false, loadingCards: true },
              () => {
                const { filterByIndustries, filterByRoleCategory } = this.state;
                if (filterByIndustries instanceof Array<number>) {
                  if (
                    filterByIndustries.length > 0 ||
                    filterByRoleCategory > 0
                  ) {
                    this.searchByIndustry(1);
                  } else {
                    this.searchJobs(1);
                  }
                }
              },
            );
          }}
        >
          {t(constant.translation.searchJob.formApplyOnlyButtonKey)}
        </Button>
      </Drawer.Body>
    </Drawer>
  );

  render(): JSX.Element | null {
    return (
      <Grid className="home-static-authorized-container" fluid>
        <Row>
          {this.renderSideTabs()}
          {this.renderMainContent()}
          {this.renderDrawerFilter()}
        </Row>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CandidateSearchJobScreen));
