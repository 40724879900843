import Http from '@services/httpClient';

export default {
  getChildAttributes(type: string) {
    return new Promise((resolve, reject) => {
      Http.get({
        url: `/api/v1/companies/attributes?type=${type}&isParent=false`,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getAttributes(type: string) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/companies/attributes?type=${type}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getTemplates(type: string) {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/companies/templates?key=${type}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getUniversities(query = '') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/misc/universities?query=${query}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getMajors(query = '') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/misc/majors?query=${query}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getSoftSkills(query = '') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/misc/soft-skills?query=${query}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getDreamJobs(query = '') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/misc/dream-jobs?query=${query}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getJobLocations(query = '') {
    return new Promise((resolve, reject) => {
      Http.get({ url: `/api/v1/misc/job-locations?query=${query}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getInterviewSteps() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/v1/my-applications/interview-steps' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getAllNotifications() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/v1/my-notifications/all' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  getStarredNotifications() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/v1/my-notifications/starred' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  markAsStarred(id: any) {
    return new Promise((resolve, reject) => {
      Http.post({ url: `/api/v1/my-notifications/starred/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  markAllAsRead() {
    return new Promise((resolve, reject) => {
      Http.post({ url: '/api/v1/my-notifications/all/read' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  markAsRead(id: any) {
    return new Promise((resolve, reject) => {
      Http.post({ url: `/api/v1/my-notifications/all/read/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  markAsUnStarred(id: any) {
    return new Promise((resolve, reject) => {
      Http.post({ url: `/api/v1/my-notifications/unstarred/${id}` })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  addDataTemplate(payload: object) {
    return new Promise((resolve, reject) => {
      Http.post({ url: '/api/v1/misc/templates', data: payload })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
