import { Navigate, Outlet } from 'react-router-dom';
import AuthStorage from '@services/authStorage';
import _ from 'lodash';

function PublicRoute(): JSX.Element {
  const storage = AuthStorage.getInstance();
  const auth = storage.isAuthenticated();

  const user = storage.getUser();
  const profile = _.get(user, 'profile');

  if (user) {
    if (_.isEmpty(profile)) {
      return <Navigate to="/basic-info" replace />;
    }
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return !auth ? <Outlet /> : <Navigate to="/" replace />;
}

export default PublicRoute;
