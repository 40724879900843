import _ from 'lodash';
import axios, { AxiosResponse } from 'axios';

import AuthStorage from '@services/authStorage';
import { ClientRequest } from '@common/ApiTypes';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default {
  async init() {
    axios.defaults.baseURL = BASE_URL;

    // Get the auth token
    const authStorage = AuthStorage.getInstance();
    const token = authStorage.getAccessToken();

    // Intercept the request to make sure the token is injected into
    // the request Header.
    axios.interceptors.request.use((config) => {
      if (!_.isEmpty(token)) {
        _.set(config.headers as object, 'Authorization', `${token}`);
      }

      return config;
    });

    // Intercept the response to store the token into AuthStorage (if exists)
    axios.interceptors.response.use(
      async (response) => {
        const tok: string | null =
          _.get(response, 'headers.Authorization', null) ||
          _.get(response, 'data.data.token', '');

        const ttype = _.get(response, 'data.data.type', 'Bearer');
        let accessToken = '';

        if (!_.isEmpty(tok)) {
          const flag = tok?.startsWith(ttype);
          if (flag) {
            accessToken = tok || '';
          } else {
            accessToken = `${ttype} ${tok}`;
          }

          authStorage.setIsAuthenticated(true);
          authStorage.setAccessToken(accessToken);
        }

        const user = authStorage.getUser();
        const profile = _.get(user, 'profile');

        if (user) {
          const { href } = window.location;
          const isUser = _.get(user, 'appRole') === 'USER';
          if (
            _.isEmpty(profile) &&
            !_.includes(href, '/basic-info') &&
            isUser
          ) {
            window.location.href = '/basic-info';
          }
        }

        return response;
      },
      // eslint-disable-next-line consistent-return
      async (error) => {
        // Also, if we receive Unauthorized error
        const statusCode = _.get(error, 'response.data.statusCode');

        if (statusCode === 401) {
          // revoke all data from AuthStorage.
          authStorage.clearAll();
          authStorage.setIsAuthenticated(false);

          const { pathname } = window.location;
          if (!pathname.includes('/login')) {
            window.location.reload();
          }
        } else {
          // if we receive another error
          // pop-up / redirect to page
        }

        return Promise.reject(error);
      },
    );
  },

  async request(r: ClientRequest, method: string) {
    await this.init();

    const response = axios
      .request({
        url: r.url,
        data: r.data,
        method,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...r.headers,
        },
        withCredentials: true,
      })
      .then(r.successCb)
      .catch(r.errorCb);

    return response;
  },

  get(r: ClientRequest): Promise<AxiosResponse<any, any>> {
    return this.request(r, 'GET');
  },

  post(r: ClientRequest): Promise<AxiosResponse<any, any>> {
    return this.request(r, 'POST');
  },

  postUpload(r: ClientRequest): Promise<AxiosResponse<any, any>> {
    _.set(r, 'headers', {
      'Content-Type': 'multipart/form-data',
      Accept: 'application/json',
    });

    return this.request(r, 'POST');
  },

  put(r: ClientRequest): Promise<AxiosResponse<any, any>> {
    return this.request(r, 'PUT');
  },

  delete(r: ClientRequest): Promise<AxiosResponse<any, any>> {
    return this.request(r, 'DELETE');
  },
};
