import Storage from '@services/storage';

enum Locals {
  USER = 'talentgrowth_user',
  IS_AUTHENTICATED = 'talentgrowth_isauthenticated',
  ACCESS_TOKEN = 'talentgrowth_access_token',
  REFRESH_TOKEN = 'talentgrowth_refresh_token',
}

export default class AuthStorage extends Storage<Locals> {
  private static instance?: AuthStorage;

  // eslint-disable-next-line no-useless-constructor
  private constructor() {
    super();
  }

  public static getInstance(): AuthStorage {
    if (!this.instance) {
      this.instance = new AuthStorage();
    }

    return this.instance;
  }

  public getAccessToken(): string | null {
    return this.get(Locals.ACCESS_TOKEN);
  }

  public getRefreshToken(): string | null {
    return this.get(Locals.REFRESH_TOKEN);
  }

  public getUser(): object | null {
    const jsonStr = this.get(Locals.USER);

    if (!jsonStr || jsonStr === 'undefined') {
      return null;
    }

    return JSON.parse(jsonStr);
  }

  public isAuthenticated(): boolean {
    const jsonStr = this.get(Locals.IS_AUTHENTICATED);
    return jsonStr === 'true';
  }

  public setUser(user: object): void {
    const jsonStr = JSON.stringify(user);
    this.set(Locals.USER, jsonStr);
  }

  public setIsAuthenticated(c: boolean): void {
    this.set(Locals.IS_AUTHENTICATED, JSON.stringify(c));
  }

  public setAccessToken(token: string): void {
    this.set(Locals.ACCESS_TOKEN, token);
  }

  public setRefreshToken(token: string): void {
    this.set(Locals.REFRESH_TOKEN, token);
  }

  public clearAll(): void {
    this.clearItems([
      Locals.ACCESS_TOKEN,
      Locals.REFRESH_TOKEN,
      Locals.USER,
      Locals.IS_AUTHENTICATED,
    ]);
  }
}
