import { util } from '@helper';
import { AssessmentAPI } from '@services/api';
import { ReactElement, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Grid, Modal, Panel, Row, toaster } from 'rsuite';

const { withRouter } = util;

function GeneralTestResultScreen(): ReactElement {
  const navigate = useNavigate();
  const { token } = useParams();
  const [result, setResult] = useState<any>({});

  const init = async (): Promise<void> => {
    const data = await AssessmentAPI.get(token ?? '')
      .then((val) => val.data)
      .catch((err) => {
        toaster.push(util.buildErrorMessage(err.message));
      });

    if (data.status === 'ACTIVE') {
      navigate(`/dashboard/general-test/${token}`);
      return;
    }

    setResult(data);
  };

  const onClose = (): void => {
    navigate(`/dashboard/candidate/my-application/${result.jobApplicationId}`);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Grid
      className="home-static-authorized-container"
      style={{ color: '#212121' }}
    >
      <Row>
        <Panel style={{ backgroundColor: 'white' }} bordered>
          <Modal.Body>
            <h4 style={{ marginBottom: '16px' }}>Assessment Result</h4>
            {result.isPass ? (
              <PassTest score={result.score} />
            ) : (
              <FailedTest score={result.score} />
            )}
          </Modal.Body>

          <Modal.Footer>
            <Col xs={24} sm={16}>
              <div></div>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                block
                className="btn-tg-primary job-details-header-btn"
                appearance="primary"
                id="myapplication-submit-test-button"
                onClick={onClose}
                size="lg"
              >
                Ok
              </Button>
            </Col>
          </Modal.Footer>
        </Panel>
      </Row>
    </Grid>
  );
}

function PassTest(props: { score: number }): ReactElement {
  const { score } = props;

  return (
    <div>
      <p>
        Thank you for taking the time to complete our online assessment. We
        appreciate your effort and commitment to the application process.
      </p>
      <p>
        We are pleased to inform you that you have{' '}
        <b style={{ color: 'green' }}>Successfully Passed</b> the initial
        assessment. Based on your performance, you have achieved a rating.
      </p>
      <p
        style={{
          textAlign: 'center',
          fontSize: '32px',
          marginTop: '32px',
          marginBottom: '32px',
        }}
      >
        {calculateStar(score)}
      </p>
      <p>
        The next stage in our selection process is the <b>Technical Test</b>.
        You will receive an email shortly with detailed instructions on how to
        proceed. This technical test will further evaluate your skills and
        qualifications for the role. Please watch your inbox for an email from
        us with all the information you need to excel in the technical test. Be
        sure to check your spam folder just in case it ends up there.
      </p>
      <p>
        We look forward to seeing you succeed in the next stage of our
        application process.
      </p>
    </div>
  );
}

function FailedTest(props: { score: number }): ReactElement {
  const { score } = props;

  return (
    <div>
      <p>
        Thank you for taking the time to complete our online assessment. We
        appreciate your effort and commitment to the application process.
      </p>
      <p>
        We regret to inform you that, based on your performance,{' '}
        <b style={{ color: 'red' }}>you did not pass</b> the initial assessment.
        Based on your performance, you have achieved a rating.
      </p>
      <p>{calculateStar(score)}</p>
      <p>
        We encourage you not to be disheartened by this result. You are still
        welcome to <b>apply for other role</b>. Please keep an eye on our job
        listings for future opportunities
      </p>
    </div>
  );
}

function calculateStar(score: number): ReactElement {
  let numStar = 1;
  if (score >= 21 && score <= 49) {
    numStar = 2;
  } else if (score >= 50 && score <= 69) {
    numStar = 3;
  } else if (score >= 70 && score <= 79) {
    numStar = 4;
  } else if (score >= 80) {
    numStar = 5;
  }

  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '32px',
        marginBottom: '32px',
      }}
    >
      <span
        style={{
          fontSize: '32px',
        }}
      >
        {'⭐'.repeat(numStar)}
      </span>
      <span
        style={{
          fontSize: '32px',
          opacity: 0.4,
        }}
      >
        {'★'.repeat(5 - numStar)}
      </span>
    </div>
  );
}

export default withRouter(withTranslation()(GeneralTestResultScreen));
