import _ from 'lodash';
import { Icon } from '@rsuite/icons';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Avatar,
  AvatarGroup,
  Button,
  Carousel,
  Col,
  Grid,
  Panel,
  Row,
} from 'rsuite';
import { FaArrowRight } from 'react-icons/fa';

import '../styles.scss';

import { util } from '@helper';

import hiringLogo from '../hiring-logo.json';
import dummy from './dummy.json';

const { withRouter } = util;

class HiringPartnerHomeScreen extends React.Component<any, any> {
  testimonyTimer: NodeJS.Timer;

  constructor(props: any) {
    super(props);

    this.state = {
      testimonyData: dummy,
      testimonyPageIndex: 0,
    };

    this.testimonyTimer = setInterval(
      () => this.onTestimonyNextBtnClick(null),
      5000,
    );
  }

  componentDidMount(): void {
    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'white');
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.testimonyTimer);
  }

  onTestimonyPrevBtnClick = (
    e: any,
    { testimonyPageIndex, testimonyData } = this.state,
  ): void => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }

    let pageIndex = 0;

    if (testimonyPageIndex > 0) {
      pageIndex = testimonyPageIndex - 1;
    } else {
      pageIndex = testimonyData.length - 1;

      if (window.innerWidth > 425) {
        pageIndex = Math.ceil(testimonyData.length / 2) - 1;
      }
    }

    this.setState({
      testimonyPageIndex: pageIndex,
    });
  };

  onTestimonyNextBtnClick = (
    e: any,
    { testimonyPageIndex, testimonyData } = this.state,
  ): void => {
    if (e !== undefined && e !== null) {
      e.preventDefault();
    }

    let pageIndex = 0;

    if (window.innerWidth <= 425) {
      if (testimonyData.length !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    } else if (window.innerWidth <= 768) {
      if (Math.ceil(testimonyData.length / 2) !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    } else if (window.innerWidth > 768) {
      if (Math.ceil(testimonyData.length / 2) !== testimonyPageIndex + 1) {
        pageIndex = testimonyPageIndex + 1;
      }
    }

    this.setState({
      testimonyPageIndex: pageIndex,
    });
  };

  renderTop = (): React.ReactNode => (
    <Grid fluid className="home-static-container" id="hiring-home">
      <Row>
        <Col
          lg={14}
          md={14}
          sm={24}
          xs={24}
          className="home-static-item-container"
        >
          <div>
            <h1>
              Only <div>The Finest</div>
              <br />
              Candidates For
              <br />
              Your Hiring Needs!
              <br />
            </h1>
            <p>
              We adopt skill based hiring and offer only pre-screened
              candidates. Say goodbye to long hassle screening process!
            </p>
            <Button
              appearance="default"
              style={{ zIndex: 99 }}
              onClick={() => window.open('/login/hiring-partner', '_self')}
            >
              Hire Now!&nbsp;&nbsp;
              <Icon as={FaArrowRight} />
            </Button>

            <img src="arrow_yellow.png" alt="arrow-yellow-icon" />
          </div>
        </Col>

        <Col
          lg={10}
          md={10}
          sm={0}
          xs={0}
          className="home-static-item-container top-home"
        >
          <br />
          <br />
          <br />
          <Col xs={24}>
            <img src="bg-hiring-partner-home-person-1.png" alt="person1" />
          </Col>
        </Col>
      </Row>
    </Grid>
  );

  renderMeetOurHiringPartner = (): React.ReactNode => (
    <Grid
      className="home-static-container meeting-hiring-partners-container"
      fluid
    >
      <Row>
        <Col
          xs={24}
          className="home-static-item-container"
          style={{ backgroundColor: '#C3DCFF' }}
        >
          <div id="meeting-hiring-partners">
            <img
              src="/white-star.png"
              className="white-star"
              alt="white-star-decor"
            />
            <img
              src="/yellow-paint.png"
              className="yellow-paint"
              alt="yellow-paint-decor"
            />
            <h1>Meet Our Hiring Partners</h1>
            <p className="title-desc">
              More than 80 companies from Indonesia, Malaysia,
              <br /> Singapore, France and many more!
            </p>
            <br />

            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(0, 9), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(0, 5), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(9, 16), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(5, 10), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            <AvatarGroup
              style={{ gap: '.75em' }}
              stack
              spacing={window.innerWidth < 768 ? 6 : 24}
            >
              {window.innerWidth > 768
                ? _.map(hiringLogo.slice(16, 22), (logo) => (
                    <Avatar
                      circle
                      key={logo.imageUrl}
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))
                : _.map(hiringLogo.slice(10, 15), (logo) => (
                    <Avatar
                      key={logo.imageUrl}
                      circle
                      size="lg"
                      src={logo.imageUrl}
                      alt="logo-startup"
                    />
                  ))}
            </AvatarGroup>
            {window.innerWidth <= 768 && (
              <AvatarGroup stack spacing={window.innerWidth < 768 ? 6 : 24}>
                {_.map(hiringLogo.slice(15, 20), (logo) => (
                  <Avatar
                    key={logo.imageUrl}
                    circle
                    size="lg"
                    src={logo.imageUrl}
                    alt="logo-startup"
                  />
                ))}
              </AvatarGroup>
            )}
            {window.innerWidth <= 768 && (
              <AvatarGroup stack spacing={window.innerWidth < 768 ? 6 : 24}>
                {_.map(hiringLogo.slice(20, 22), (logo) => (
                  <Avatar
                    key={logo.imageUrl}
                    circle
                    size="lg"
                    src={logo.imageUrl}
                    alt="logo-startup"
                  />
                ))}
              </AvatarGroup>
            )}
            <br />
          </div>
        </Col>
      </Row>
    </Grid>
  );

  renderJoinUs = (): React.ReactNode => (
    <Grid className="home-static-container" style={{ marginTop: '-15vh' }}>
      <Row>
        <Col xs={24} className="home-static-item-container" id="hiring-partner">
          <div>
            <p>So, what are you waiting for?</p>
            <h5>
              Register NOW AND start building your <br />
              amazing team!
            </h5>
            <br />
            <span>
              There is no need to wait any longer because hundreds of
              individuals are waiting for your
            </span>
            <span style={{ display: 'block' }}>job opportunities!</span>
            <Button
              appearance="default"
              onClick={() => {
                window.open(
                  // eslint-disable-next-line max-len
                  'https://api.whatsapp.com/send?phone=6281282093396&text=Hi%20Talent%20Growth,%20I%20would%20love%20to%20recruit%20great%20interns%20from%20Talent%20Growth.%20May%20I%20know%20how%20is%20the%20procedure?',
                );
              }}
            >
              Contact Us Now&nbsp;&nbsp;
              <Icon as={FaArrowRight} />
            </Button>
          </div>
        </Col>
      </Row>
    </Grid>
  );

  renderTestimonyCarousel = (): React.ReactNode => {
    const { testimonyData: data } = this.state;
    let size = 3;

    if (window.innerWidth < 768 && window.innerWidth > 0) {
      size = 1;
    } else {
      size = 2;
    }
    const chunks = _.chunk(data, size);

    return (
      <Grid className="home-static-container" style={{ position: 'relative' }}>
        <h1>Hear What Our Clients Say</h1>
        <p className="title-desc">
          Quality over quantity. Hire fast and say goodbye to
          <br /> long hassle screening process.
        </p>

        <a href="#" onClick={this.onTestimonyPrevBtnClick}>
          <img src="/prev-btn.png" className="prev-btn" alt="prev-btn-decor" />
        </a>
        <a href="#" onClick={this.onTestimonyNextBtnClick}>
          <img src="/next-btn.png" className="next-btn" alt="next-btn-decor" />
        </a>

        <Carousel
          className="slider"
          autoplay
          autoplayInterval={8000}
          activeIndex={_.get(this.state, 'testimonyPageIndex') || 0}
        >
          {_.map(chunks, (c: Array<object>, carouselIdx: number) => (
            <Grid fluid key={`carousel-item-${carouselIdx}`}>
              <Row id="hiring-partner">
                {_.map(c, (testimony: object, index: number) => (
                  <>
                    <Col
                      md={11}
                      sm={24}
                      xs={24}
                      lg={11}
                      key={`${_.get(testimony, 'imageUrl')}-${index}`}
                      style={{ marginLeft: '4px' }}
                    >
                      <img
                        className="slider-item-avatar"
                        style={{ right: '33.33%' }}
                        src={_.get(testimony, 'imageUrl')}
                        alt={`avatar-${_.get(testimony, 'alt') || ''}`}
                      />
                      <Panel bordered id="hiring-partner">
                        <p style={{ textAlign: 'center' }}>
                          {_.get(testimony, 'paragraph')}
                        </p>
                        <h5 style={{ textAlign: 'center' }}>
                          {_.get(testimony, 'name')}
                        </h5>
                        <p style={{ textAlign: 'center' }}>
                          {_.get(testimony, 'jobTitle')}
                        </p>
                      </Panel>
                    </Col>
                    {index % 2 === 0 && (
                      <Col
                        md={1}
                        lg={1}
                        style={{
                          display: window.innerWidth <= 425 ? 'none' : 'block',
                        }}
                      />
                    )}
                  </>
                ))}
              </Row>
            </Grid>
          ))}
        </Carousel>
      </Grid>
    );
  };

  renderReasonJoinUs = (): React.ReactNode => (
    <Grid
      fluid
      className="home-static-container reason-hiring-partner-container"
    >
      <h1>
        Why you should hire
        <br />
        from us
      </h1>
      <p className="title-desc">
        Take a look at some of the advantages of using Talent Growth to look for
        <br /> amazing candidates in order to build an amazing team.
      </p>

      <div className="slider">
        <Grid fluid>
          <Row id="reason-hiring-partner" style={{ paddingTop: '8px' }}>
            <Col md={8} sm={24} xs={24} lg={8}>
              <Panel bordered id="why-should-hire-us">
                <img
                  src="icon-hiring-partner-pink.png"
                  alt="icon-hiring-partner-pink"
                />
                <h5>Employer Branding</h5>
                <br />
                <p>
                  Start your Employer Branding from day one. Through our network
                  to students, we help you to do this.
                </p>
                <br />
              </Panel>
            </Col>
            <Col md={8} sm={24} xs={24} lg={8}>
              <Panel bordered id="why-should-hire-us">
                <img
                  src="icon-hiring-partner-blue.png"
                  alt="icon-hiring-partner-blue"
                />
                <h5>Quality over Quantity</h5>
                <br />
                <p>
                  Forget about number of applicants. We help you to streamline
                  only qualified candidates!
                </p>
              </Panel>
            </Col>
            <Col md={8} sm={24} xs={24} lg={8}>
              <Panel bordered id="why-should-hire-us">
                <img
                  src="icon-hiring-partner-yellow.png"
                  alt="icon-hiring-partner-yellow"
                />
                <h5>Pre-screened Candidate</h5>
                <br />
                <p>
                  Pre-screened shortlisted candidates for you to spot the
                  potential candidate with skills that match your preferences!
                </p>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    </Grid>
  );

  renderHiringStep = (): React.ReactNode => (
    <Grid className="home-static-container">
      <h1>
        start hiring with <br />4 simple steps
      </h1>

      <div className="slider">
        <Grid fluid>
          <Row id="hiring-steps">
            <Col md={11} sm={24} xs={24} lg={11}>
              <Panel bordered>
                <img src="img-hiring-step-1.png" alt="hiring-step-1" />
                <h5>Start Listing</h5>
                <br />
                <p>
                  Begin listing the positions needed by your company and provide
                  detailed information about the job and company.
                </p>
                <br />
              </Panel>
            </Col>
            <Col md={1} lg={1} />
            <Col md={11} sm={24} xs={24} lg={11}>
              <Panel bordered>
                <img src="img-hiring-step-2.png" alt="hiring-step-2" />
                <h5>Pick The Best Candidate</h5>
                <br />
                <p>
                  Get a list of shortlisted applicants who passed our
                  pre-screening assessment.
                </p>
              </Panel>
            </Col>
          </Row>

          <Row id="hiring-steps">
            <Col md={11} sm={24} xs={24} lg={11}>
              <Panel bordered>
                <img src="img-hiring-step-3.png" alt="hiring-step-3" />
                <h5>Interview</h5>
                <br />
                <p>
                  You will either interview the best of the best shortlisted
                  candidates after making your selection, or you will skip the
                  interview stage and proceed directly to the offering stage.
                </p>
              </Panel>
            </Col>
            <Col md={1} lg={1} />
            <Col md={11} sm={24} xs={24} lg={11}>
              <Panel bordered>
                <img src="img-hiring-step-4.png" alt="hiring-step-4" />
                <h5>Offering and Onboarding</h5>
                <br />
                <p>
                  Congratulations! You&lsquo;ve now scouted the finest talent to
                  your team! The best part is you have saved a significant
                  effort, and money.
                </p>
              </Panel>
            </Col>
          </Row>
        </Grid>
      </div>
    </Grid>
  );

  render(): React.ReactNode {
    return (
      <div>
        {/* Render Banner (Top component) */}
        {this.renderTop()}

        {/* Meet our hiring partner */}
        {this.renderMeetOurHiringPartner()}

        {/* Testimony section */}
        {this.renderTestimonyCarousel()}

        {/* Hiring Steps */}
        {this.renderHiringStep()}

        {/* Reason Why Join Us section */}
        {this.renderReasonJoinUs()}

        {/* Join us card */}
        {this.renderJoinUs()}
      </div>
    );
  }
}

export default withRouter(withTranslation()(HiringPartnerHomeScreen));
