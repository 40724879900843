import { util } from '@helper';
import { AssessmentAPI } from '@services/api';
import { ReactElement } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Grid, Modal, Panel, Row, toaster } from 'rsuite';

const { withRouter } = util;

function GeneralTestApplyScreen(): ReactElement {
  const navigate = useNavigate();
  const { token } = useParams();

  const onTestCase = (): void => {
    AssessmentAPI.takeTest(token ?? '')
      .then(() => {
        navigate(`/dashboard/general-test/${token}`);
      })
      .catch((e) => {
        toaster.push(util.buildErrorMessage(e.message));
      });
  };

  return (
    <Grid
      className="home-static-authorized-container"
      style={{ color: '#212121' }}
    >
      <Row>
        <Panel bordered style={{ backgroundColor: 'white' }}>
          <Modal.Body>
            <h4 style={{ marginBottom: '16px' }}>Assessment Rules</h4>
            <p>
              Welcome to our online assessment! Please take a moment to read
              through these instructions carefully before taking the test.
            </p>
            <p>Instructions:</p>
            <ol>
              <li>
                <p>
                  There will be total 30 questions. The assessment will take
                  approximately 25 minutes to complete. Your time will begin as
                  soon as you click "Take Test"
                </p>
              </li>
              <li>
                <p>
                  We value honesty. Answer all questions truthfully and to the
                  best of your ability.
                </p>
              </li>
              <li>
                <p>
                  Ensure a stable internet connection before starting the test.
                </p>
              </li>
              <li>
                <p>
                  For the optimal experience, we recommend using Google Chrome
                  as your web browser.
                </p>
              </li>
              <li>
                <p>The assessment will be conducted in English.</p>
              </li>
              <li>
                <p>
                  If you do not pass this test, please note that you will be
                  ineligible to apply for this specific role for the next 6
                  months. However, you are still welcome to apply for other
                  roles.
                </p>
              </li>
              <li>
                <p>
                  Scoring System: Your performance will be evaluated using a
                  star rating system based on your test score. To pass, aim for
                  a 5-star rating! Here's how the ratings correspond to your
                  test score:
                </p>
                <ul>
                  <li>
                    <p>
                      0-20 points : <span>⭐</span>{' '}
                      <span style={{ opacity: 0.4 }}>★ ★ ★ ★</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      21-49 points : <span>⭐ ⭐</span>{' '}
                      <span style={{ opacity: 0.4 }}>★ ★ ★</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      50-69 points : <span>⭐ ⭐ ⭐</span>{' '}
                      <span style={{ opacity: 0.4 }}>★ ★</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      70-79 points : <span>⭐ ⭐ ⭐ ⭐</span>{' '}
                      <span style={{ opacity: 0.4 }}>★</span>
                    </p>
                  </li>
                  <li>
                    <p>
                      80-100 points : <span>⭐ ⭐ ⭐ ⭐ ⭐</span>
                    </p>
                  </li>
                </ul>
              </li>
            </ol>
            <p>Thank you for participating in our assessment. Good luck!</p>
          </Modal.Body>

          <Modal.Footer>
            <Col xs={24} sm={16}>
              <div></div>
            </Col>
            <Col xs={24} sm={8}>
              <Button
                block
                className="btn-tg-primary job-details-header-btn"
                appearance="primary"
                id="myapplication-submit-test-button"
                onClick={onTestCase}
                size="lg"
              >
                Take Test
              </Button>
            </Col>
          </Modal.Footer>
        </Panel>
      </Row>
    </Grid>
  );
}

export default withRouter(withTranslation()(GeneralTestApplyScreen));
