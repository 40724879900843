import { Navigate, Outlet, useLocation } from 'react-router-dom';
import AuthStorage from '@services/authStorage';
import _ from 'lodash';

function PrivateRoute(): JSX.Element {
  const storage = AuthStorage.getInstance();
  const auth = storage.isAuthenticated();

  const location = useLocation();

  const user = storage.getUser();
  const profile = _.get(user, 'profile');

  if (user) {
    const { pathname } = location;
    const isBasicInfo = pathname === '/basic-info';
    const isUser = _.get(user, 'appRole') === 'USER';
    const isHiringPartner = _.get(user, 'appRole') === 'HIRING_PARTNER';

    if (_.isEmpty(profile) && !isBasicInfo && isUser) {
      return <Navigate to="/basic-info" replace />;
    }

    const isHiringPartnerPath = pathname.includes('dashboard/hiring-partner');
    const isUserPath = pathname.includes('dashboard/candidate');

    if (isUserPath && isHiringPartner) {
      return <Navigate to="/dashboard/hiring-partner/home" />;
    }

    if (isHiringPartnerPath && isUser) {
      return <Navigate to="/dashboard/candidate/home" />;
    }
  }

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return auth ? <Outlet /> : <Navigate to="/login" replace />;
}

export default PrivateRoute;
