import { constant, util } from '@helper';
import { withTranslation } from 'react-i18next';
import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthStorage from '@common/services/authStorage';
import {
  Avatar,
  Badge,
  Button,
  Checkbox,
  CheckboxGroup,
  Col,
  Container,
  Content,
  FlexboxGrid,
  Grid,
  Input,
  InputGroup,
  Loader,
  Panel,
  RangeSlider,
  Row,
  SelectPicker,
  Sidebar,
  Stack,
  TagPicker,
  Tooltip,
  Whisper,
  toaster,
} from 'rsuite';
import { ValueType } from 'rsuite/esm/Checkbox';
import _ from 'lodash';
import { Promise } from 'bluebird';
import { CompanyAPI, MiscAPI } from '@services/api';
import { FaBuilding, FaClock, FaMapMarkerAlt, FaSearch } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroller';
import FlexboxGridItem from 'rsuite/esm/FlexboxGrid/FlexboxGridItem';
import PlaceholderGraph from 'rsuite/esm/Placeholder/PlaceholderGraph';

import './styles.scss';

type State = {
  selectedJobTypes: ValueType[];
  selectedSalary: Array<number>;
  selectedExpLevels: ValueType[];
  selectedWorkingTypes: ValueType[];

  results: Array<object>;

  selectedLocationId: number;

  workingTypes: Array<object>;
  jobTypes: Array<object>;
  location: Array<object>;
  companyIndustries: Array<object>;

  query: string | null;
  isFilterOn: boolean;
  loading: boolean;
  hasMorePage: boolean;

  filterByRoleCategory: number;
  filterByIndustries: Array<number>;
};

const { withRouter } = util;

class CandidateJobCategoriesScreen extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedJobTypes: [],
      selectedExpLevels: [],
      selectedWorkingTypes: [],
      selectedSalary: [500000, 500000],
      selectedLocationId: -1,

      results: [],

      location: [],
      workingTypes: [],
      companyIndustries: [],
      jobTypes: [],

      loading: true,
      query: null,
      isFilterOn: false,
      hasMorePage: false,

      filterByIndustries: [],
      filterByRoleCategory: 0,
    };
  }

  async componentDidMount(): Promise<void> {
    await Promise.allSettled([
      new Promise((resolve, reject) => {
        try {
          this.getJobTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.searchJobs();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getWorkingTypes();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getCompanyIndustries();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),

      new Promise((resolve, reject) => {
        try {
          this.getLocation();
          resolve();
        } catch (err) {
          reject(err);
        }
      }),
    ]);
  }

  private getCompanyIndustries = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('COMPANY_INDUSTRY');
    const data = _.get(response, 'data') || [];
    this.setState({
      companyIndustries: data,
    });
  };

  private getLocation = async (query = ''): Promise<void> => {
    try {
      const response = await MiscAPI.getJobLocations(query);
      const data = _.get(response, 'data') || [];
      this.setState({ location: data });
    } catch (e: any) {
      toaster.push(util.buildErrorMessage(e.message));
    }
  };

  private getJobTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('EMPLOYMENT_STATUS');
    const data = _.get(response, 'data') || [];
    this.setState({
      jobTypes: data,
    });
  };

  private getWorkingTypes = async (): Promise<void> => {
    const response = await MiscAPI.getAttributes('WORKING_TYPE');
    const data = _.get(response, 'data') || [];
    this.setState({
      workingTypes: data,
    });
  };

  private searchJobs = async (page = 1): Promise<void> => {
    try {
      const {
        query,
        isFilterOn,
        selectedJobTypes,
        selectedWorkingTypes,
        selectedSalary,
        selectedExpLevels,
        selectedLocationId,
      } = this.state;
      const res = await CompanyAPI.searchJobs(
        query,
        isFilterOn
          ? {
              jobTypes: selectedJobTypes,
              salary: selectedSalary,
              workingTypes: selectedWorkingTypes,
              expLevels: selectedExpLevels,
              locationId: selectedLocationId,
            }
          : {},
        page,
      );
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const total = Number(_.get(res, 'meta.total') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loading: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage && total > 0,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  private searchByIndustry = async (page = 1): Promise<void> => {
    try {
      const {
        query,
        filterByIndustries: industryId,
        filterByRoleCategory: categoryId,
        isFilterOn,
        selectedJobTypes,
        selectedSalary,
        selectedWorkingTypes,
        selectedExpLevels,
        selectedLocationId,
      } = this.state;
      const res = await CompanyAPI.jobListByIndustry(
        industryId,
        categoryId,
        isFilterOn
          ? {
              jobTypes: selectedJobTypes,
              salary: selectedSalary,
              workingTypes: selectedWorkingTypes,
              expLevels: selectedExpLevels,
              locationId: selectedLocationId,
            }
          : {},
        page,
        query,
      );
      const totalPage = Number(_.get(res, 'meta.totalPage') || 0);
      const total = Number(_.get(res, 'meta.total') || 0);
      const data: Array<any> = _.get(res, 'data') || [];

      let prevResults: Array<any> = _.get(this, 'state.results') || [];
      if (page === 1) {
        prevResults = [];
      }

      this.setState({
        loading: false,
        results: prevResults.concat(data),
        hasMorePage: page < totalPage && total > 0,
      });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderSidebar = (
    { t } = this.props,
    {
      selectedSalary,
      selectedWorkingTypes,
      selectedJobTypes,
      selectedExpLevels,
    } = this.state,
  ): JSX.Element => (
    <div className="job-category-sidebar">
      <Row>
        <Col xs={12}>
          <b className="filter-title">Filter</b>
        </Col>
        <Col xs={12}>
          <Button
            className="clear-all"
            appearance="link"
            onClick={() =>
              this.setState({
                selectedJobTypes: [],
                selectedWorkingTypes: [],
                selectedSalary: [500000, 500000],
                selectedExpLevels: [],
              })
            }
          >
            Clear All
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Panel>
          <Row>
            <Col xs={15}>
              <b>Job Type</b>
            </Col>
            <Col xs={9}>
              <Button
                className="clear-all"
                appearance="link"
                onClick={() => this.setState({ selectedJobTypes: [] })}
              >
                Clear
              </Button>
            </Col>
          </Row>
          <CheckboxGroup
            name="jobTypeCb"
            value={selectedJobTypes}
            onChange={(value) => {
              this.setState({ selectedJobTypes: value });
            }}
          >
            {_.map(_.get(this.state, 'jobTypes'), (val: any) => (
              <Checkbox key={val.id} value={val.id}>
                {val.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Panel>
      </Row>
      <br />
      <Row>
        <Panel>
          <Row>
            <Col xs={15}>
              <b>Location</b>
            </Col>
            <Col xs={9}>
              <Button
                className="clear-all"
                appearance="link"
                onClick={() => this.setState({ selectedWorkingTypes: [] })}
              >
                Clear
              </Button>
            </Col>
          </Row>
          <CheckboxGroup
            name="locationCb"
            value={selectedWorkingTypes}
            onChange={(value) => {
              this.setState({ selectedWorkingTypes: value });
            }}
          >
            {_.map(_.get(this.state, 'workingTypes'), (val: any) => (
              <Checkbox key={val.id} value={val.id}>
                {val.name}
              </Checkbox>
            ))}
          </CheckboxGroup>
        </Panel>
      </Row>
      <br />
      <Row>
        <Panel>
          <Row>
            <Col xs={15}>
              <b>Experience Level</b>
            </Col>
            <Col xs={9}>
              <Button
                className="clear-all"
                appearance="link"
                onClick={() => this.setState({ selectedExpLevels: [] })}
              >
                Clear
              </Button>
            </Col>
          </Row>
          <CheckboxGroup
            name="expLevelCb"
            value={selectedExpLevels}
            onChange={(value) => {
              this.setState({ selectedExpLevels: value });
            }}
          >
            <Checkbox value="noexp">No experience (Student/Freshgrad)</Checkbox>
            <Checkbox value="junior">Junior (1-3 YoE)</Checkbox>
            <Checkbox value="mid">Mid (4-5 YoE)</Checkbox>
            <Checkbox value="senior">Senior ({'>'}5 YoE)</Checkbox>
          </CheckboxGroup>
        </Panel>
      </Row>
      <br />
      <Row>
        <Panel>
          <Row>
            <Col xs={15}>
              <b>Salary</b>
            </Col>
            <Col xs={9}>
              <Button
                className="clear-all"
                appearance="link"
                onClick={() =>
                  this.setState({ selectedSalary: [500000, 500000] })
                }
              >
                Clear
              </Button>
            </Col>
          </Row>
          <div style={{ paddingLeft: '6%', paddingRight: '6%' }}>
            <RangeSlider
              name="salaryRange"
              value={[selectedSalary[0], selectedSalary[1]]}
              min={500000}
              step={500000}
              max={50500000}
              renderTooltip={(value: number | undefined) =>
                util.formatCurrency(value)
              }
              onChange={(value) => {
                this.setState({
                  selectedSalary: [value[0].valueOf(), value[1].valueOf()],
                });
              }}
            />
          </div>
        </Panel>
      </Row>
      <br />
      <br />
      <Button
        className="btn-tg-primary"
        block
        appearance="primary"
        disabled={
          selectedSalary[0] === 500000 &&
          selectedSalary[1] === 500000 &&
          selectedJobTypes.length === 0 &&
          selectedWorkingTypes.length === 0 &&
          selectedExpLevels.length === 0
        }
        onClick={() => {
          this.setState(
            {
              isFilterOn: true,
              loading: true,
            },
            () => {
              const { filterByIndustries } = this.state;
              if (filterByIndustries instanceof Array<number>) {
                if (filterByIndustries.length > 0) {
                  this.searchByIndustry(1);
                } else {
                  this.searchJobs(1);
                }
              }
            },
          );
        }}
      >
        {t(constant.translation.searchJob.formApplyOnlyButtonKey)}
      </Button>
    </div>
  );

  renderSearchResults = (): React.ReactNode => {
    const { results, hasMorePage, loading } = this.state;
    const { t } = this.props;
    const width = window.innerWidth;

    let size = 3;
    let threshold = 1 * window.innerHeight;
    if (width <= 425) {
      size = 1;
    } else if (width <= 1280) {
      size = 2;
    } else {
      threshold = 300;
      size = 3;
    }
    const chunked = _.chunk(results, size);

    return (
      <InfiniteScroll
        pageStart={0}
        threshold={threshold}
        loadMore={(page) => {
          const { filterByIndustries } = this.state;

          if (filterByIndustries instanceof Array<number>) {
            if (filterByIndustries.length > 0) {
              this.searchByIndustry(page);
            } else {
              this.searchJobs(page);
            }
          }
        }}
        hasMore={hasMorePage}
        loader={
          <FlexboxGrid key="load-more">
            <FlexboxGridItem
              key="load-more-item-1"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-2"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
            <FlexboxGridItem
              key="load-more-item-3"
              as={Col}
              xs={24}
              sm={12}
              md={12}
              lg={8}
            >
              <PlaceholderGraph active />
            </FlexboxGridItem>
          </FlexboxGrid>
        }
      >
        {loading && (
          <Row className="loader-container">
            {_.range(0, 6).map((i: number) => (
              <Col key={`loader-${i}`} xs={24} sm={12} md={12} lg={8}>
                <PlaceholderGraph active />
              </Col>
            ))}
          </Row>
        )}

        {!loading && results.length === 0 && (
          <p className="no-results-text">No results</p>
        )}

        {_.map(chunked, (data: Array<any>, index: number) => (
          <Row
            className="search-results-row"
            key={`row-searchresults-${index}`}
          >
            {_.map(data, (obj: any, idx: number) => {
              const employmentStatus = _.get(obj, 'employmentStatus.0.name');
              const company = _.get(obj, 'company');
              const companyLogo = _.get(obj, 'company.logo.url');
              const salaryHigh: number = _.get(obj, 'maxSalaryRange') || 0;
              const salaryLow: number = _.get(obj, 'minSalaryRange') || 0;
              const duration = _.get(obj, 'duration');
              const jobId = _.get(obj, 'id');
              const isHot = _.get(obj, 'isHot');
              const workingTypes: Array<any> = _.get(obj, 'workingTypes') || [];

              return (
                <Col
                  key={`col-searchresults-${idx}`}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={8}
                >
                  <Panel className="search-result-card">
                    <Stack
                      direction="column"
                      spacing={20}
                      style={{ alignItems: 'flex-start' }}
                    >
                      {/* Badge */}
                      <Stack spacing={8}>
                        {isHot ? <Badge content="New" /> : <span />}
                        <Badge
                          className={
                            employmentStatus === 'Full Time'
                              ? 'rs-badge-fulltime'
                              : ''
                          }
                          content={employmentStatus}
                        />
                        {workingTypes.map((val: any) => (
                          <Badge
                            key={`workingTypes-${val.name}`}
                            content={val.name}
                          />
                        ))}
                      </Stack>

                      {/* Company Information */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={8}>
                            <Avatar
                              circle
                              size="lg"
                              src={companyLogo}
                              alt="logo-startup"
                            />
                          </Col>
                          <Col xs={16} className="company-info">
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'name')}</b>
                                </Tooltip>
                              }
                            >
                              <p>{_.get(company, 'name')}</p>
                            </Whisper>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(obj, 'title')}</b>
                                </Tooltip>
                              }
                            >
                              <b>{_.get(obj, 'title')}</b>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Job Details */}
                      <Stack direction="column" spacing={6}>
                        <Row>
                          <Col xs={3}>
                            <FaMapMarkerAlt />
                          </Col>
                          <Col xs={21}>
                            <p>{_.get(company, 'region')}</p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaClock />
                          </Col>
                          <Col xs={21}>
                            {duration > 0 && <p>{duration} month(s)</p>}
                            {duration === 0 && <p>Full Time</p>}
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={3}>
                            <FaBuilding />
                          </Col>
                          <Col xs={21}>
                            <Whisper
                              placement="topStart"
                              speaker={
                                <Tooltip>
                                  <b>{_.get(company, 'industry.name')}</b>
                                </Tooltip>
                              }
                            >
                              <p className="industry-name">
                                {_.get(company, 'industry.name')}
                              </p>
                            </Whisper>
                          </Col>
                        </Row>
                      </Stack>

                      {/* Salary range */}
                      <Stack spacing={6}>
                        <p className="salary-range">
                          {util.formatSalaryRange(salaryLow, salaryHigh)}
                        </p>
                      </Stack>

                      {/* Job Card Buttons */}
                      <Stack spacing={6}>
                        <Row>
                          <Col xs={24}>
                            <Button
                              className="btn-tg-primary"
                              block
                              appearance="primary"
                              onClick={() => {
                                // eslint-disable-next-line max-len
                                const url = `/dashboard/candidate/jobs/${jobId}`;
                                window.open(url);
                              }}
                            >
                              {t(
                                constant.translation.searchJob
                                  .formApplyButtonKey,
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Stack>
                    </Stack>
                  </Panel>
                </Col>
              );
            })}
          </Row>
        ))}
      </InfiniteScroll>
    );
  };

  renderContent = (
    { location, filterByIndustries, companyIndustries } = this.state,
  ): JSX.Element => (
    <Grid fluid className="search-results-container">
      <Row>
        <h1>Recommended Jobs</h1>
      </Row>
      <Row>
        <Col xs={20} sm={8}>
          <InputGroup inside>
            <InputGroup.Button>
              <FaSearch />
            </InputGroup.Button>
            <Input
              name="search"
              placeholder="Job Role"
              onChange={(query: string) => {
                this.setState({
                  query,
                  loading: true,
                });
                const industries = _.get(this.state, 'filterByIndustries');

                if (industries.length > 0) {
                  _.debounce(this.searchByIndustry, 1000)();
                } else {
                  _.debounce(this.searchJobs, 1000)();
                }
              }}
            />
          </InputGroup>
        </Col>
        <Col xsHidden sm={8}>
          <SelectPicker
            data={_.map(location, (m) => ({
              label: `${_.get(m, 'cityName')}, ${_.get(m, 'region')}`,
              value: _.get(m, 'id'),
            }))}
            onOpen={() => {
              if (location.length === 0) {
                this.getLocation();
              }
            }}
            onSearch={(keyword: string) => {
              if (keyword !== '') {
                this.getLocation(keyword);
              }
            }}
            onClean={() => {
              this.setState(
                {
                  selectedLocationId: -1,
                  loading: true,
                  isFilterOn: false,
                },
                () => {
                  if (filterByIndustries instanceof Array<number>) {
                    if (filterByIndustries.length > 0) {
                      this.searchByIndustry(1);
                    } else {
                      this.searchJobs(1);
                    }
                  }
                },
              );
            }}
            onSelect={(v) => {
              this.setState(
                {
                  selectedLocationId: Number(v),
                  loading: true,
                  isFilterOn: true,
                },
                () => {
                  if (filterByIndustries instanceof Array<number>) {
                    if (filterByIndustries.length > 0) {
                      this.searchByIndustry(1);
                    } else {
                      this.searchJobs(1);
                    }
                  }
                },
              );
            }}
            renderMenu={(menu) => {
              if (location.length === 0) {
                return (
                  <p
                    style={{
                      padding: 4,
                      color: '#999',
                      textAlign: 'center',
                    }}
                  >
                    <Loader /> Loading...
                  </p>
                );
              }
              return menu;
            }}
            placeholder="Choose Location"
          />
        </Col>
        <Col xsHidden sm={8}>
          <TagPicker
            data={_.map(companyIndustries, (c: any) => ({
              label: c.name,
              value: c.id,
            }))}
            onChange={(val) => {
              this.setState(
                {
                  filterByIndustries: val,
                },
                () => {
                  if (val instanceof Array<number>) {
                    if (val.length > 0) {
                      this.searchByIndustry(1);
                    } else {
                      this.searchJobs(1);
                    }
                  }

                  if (val === null) {
                    this.searchJobs(1);
                  }
                },
              );
            }}
            value={filterByIndustries}
            placeholder="Select Industry"
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={24}>{this.renderSearchResults()}</Col>
      </Row>
    </Grid>
  );

  render(): React.ReactNode {
    const storage = AuthStorage.getInstance();
    const isAuthenticated = storage.isAuthenticated();

    if (isAuthenticated) {
      return <Navigate to="/dashboard/candidate/home" replace />;
    }

    return (
      <Container className="home-static-container job-category-container">
        <Sidebar>{this.renderSidebar()}</Sidebar>
        <Content>{this.renderContent()}</Content>
      </Container>
    );
  }
}

export default withRouter(withTranslation()(CandidateJobCategoriesScreen));
