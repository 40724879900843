import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Col,
  FlexboxGrid,
  Form,
  FormInstance,
  InputGroup,
  Panel,
  Schema,
  toaster,
} from 'rsuite';
import { constant, util } from '@helper';
import _ from 'lodash';
import { ChangePasswordPayload } from '@common/ApiTypes';
import constants from '@helper/constants';
import { AuthAPI } from '@services/api';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const { withRouter } = util;
const { StringType } = Schema.Types;

type State = {
  changePass: ChangePasswordPayload;
  showPassword: boolean;
  showConfirmPassword: boolean;
  token: string | null;
  email: string | null;

  isSuccess: boolean;
};

class ChangePasswordScreen extends React.Component<any, State> {
  formForgotPassRef: React.Ref<
    FormInstance<
      Record<string, any>,
      string,
      {
        [x: string]: string | undefined;
      }
    >
  >;

  formForgotSchemaModel: any;

  constructor(props: any) {
    super(props);

    this.state = {
      changePass: {
        password: '',
        confirmPassword: '',
      },
      email: '',
      token: '',
      showPassword: false,
      showConfirmPassword: false,
      isSuccess: false,
    };

    this.formForgotPassRef = React.createRef();
    this.formForgotSchemaModel = Schema.Model({
      password: StringType().isRequired('Please fill the required field'),
      confirmPassword: StringType()
        .addRule((value, data) => {
          if (!_.isEqual(value, data.password)) {
            return false;
          }

          return true;
        }, 'The confirm password is not matched.')
        .isRequired('Please fill the required field'),
    });
  }

  componentDidMount(): void {
    // eslint-disable-next-line global-require
    require('./styles.scss');

    const t = _.first(document.getElementsByClassName('rs-content'));
    if (t) {
      _.set(t, 'style.backgroundColor', 'transparent');
    }

    const { location } = this.props;
    const search = _.get(location, 'search');
    const query = new URLSearchParams(search);
    const token = query.get('token');
    const email = query.get('email');

    this.setState({ token, email });
  }

  private onFormValueChanged = (value: object, key: string): void =>
    this.setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  private onChangePassword = async (): Promise<void> => {
    const { t, navigate } = this.props;

    if (!_.invoke(this.formForgotPassRef, 'current.check')) {
      toaster.push(
        util.buildErrorMessage(
          t(constant.translation.changePass.formErrorValidationMessageKey),
        ),
      );
      return;
    }

    try {
      const { changePass: payload, token, email } = this.state;
      _.set(payload, 'email', email);

      await AuthAPI.changePassword(token, payload);

      // Show toast message
      toaster.push(
        util.buildMessage(
          t(constant.translation.changePass.formSuccessValidationMessageKey),
        ),
      );

      this.setState({
        isSuccess: true,
      });

      navigate('/login', { replace: true });
    } catch (e: any) {
      // Throw pop-up error
      const response = _.get(e, 'response.data');
      const errors: string | Array<any> | any =
        _.get(response, 'errors') || _.get(response, 'message');
      let message;

      if (_.isArray(errors)) {
        message = errors.join('\n');
      } else {
        message = errors;
      }

      toaster.push(util.buildErrorMessage(message));
    }
  };

  renderSuccess = ({ t, navigate } = this.props): JSX.Element | null => {
    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="change-pass-container"
      >
        <FlexboxGrid.Item as={Col} colspan={8} xs={24}>
          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />

          <FlexboxGrid.Item as={Col} xs={22} sm={16} md={14} lg={8}>
            <img src="bg-reset-password-success.png" alt="bg" />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={8} xs={24} sm={24} md={16} lg={8}>
          <Panel
            className="change-pass-panel"
            header={
              <div>
                <span>
                  {t(
                    constant.translation.resendPassSuccess
                      .pageCandidateTitleKey,
                  )}
                </span>
                <small>
                  {t(
                    constant.translation.resendPassSuccess
                      .pageCandidateSubTitleKey,
                  )}
                </small>
              </div>
            }
            bordered
          >
            <br />
            <Button
              onClick={() => navigate('/login')}
              className="btn-tg-primary"
              block
              appearance="primary"
            >
              {t(constant.translation.general.formContinueButtonKey)}
            </Button>

            <br />
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  };

  render({ t } = this.props): JSX.Element | null {
    const { showPassword, showConfirmPassword, isSuccess } = this.state;
    if (isSuccess) {
      return this.renderSuccess();
    }

    return (
      <FlexboxGrid
        justify="center"
        align="middle"
        className="change-pass-container"
      >
        <FlexboxGrid.Item as={Col} colspan={8} xs={24}>
          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />

          <FlexboxGrid.Item as={Col} xs={22} sm={16} md={14} lg={8}>
            <img src="bg-change-password.png" alt="bg" />
          </FlexboxGrid.Item>

          <FlexboxGrid.Item as={Col} xs={1} sm={4} md={5} lg={8} />
        </FlexboxGrid.Item>

        <FlexboxGrid.Item as={Col} colspan={8} xs={24} sm={24} md={16} lg={8}>
          <Panel
            className="change-pass-panel"
            header={
              <div>
                <span>
                  {t(constant.translation.changePass.pageCandidateTitleKey)}
                </span>
                <small>
                  {t(constant.translation.changePass.pageCandidateSubTitleKey)}
                </small>
              </div>
            }
            bordered
          >
            <Form
              key="change-pass-form"
              ref={this.formForgotPassRef}
              model={this.formForgotSchemaModel}
              onChange={(v: object) => this.onFormValueChanged(v, 'changePass')}
              formValue={_.get(this.state, 'changePass')}
              fluid
            >
              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formNewPasswordKey)}
                </Form.ControlLabel>
                <InputGroup inside>
                  <Form.Control
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your new password here"
                  />

                  <InputGroup.Button
                    onClick={() =>
                      this.setState({ showPassword: !showPassword })
                    }
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>
                  {t(constants.translation.general.formConfirmPasswordKey)}
                </Form.ControlLabel>
                <InputGroup inside>
                  <Form.Control
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    placeholder="Enter your confirm password here"
                  />

                  <InputGroup.Button
                    onClick={() =>
                      this.setState({
                        showConfirmPassword: !showConfirmPassword,
                      })
                    }
                  >
                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Button>
                </InputGroup>
              </Form.Group>

              <Button
                className="btn-tg-primary"
                block
                appearance="primary"
                onClick={this.onChangePassword}
              >
                {t(constant.translation.general.formChangePasswordButtonKey)}
              </Button>
            </Form>
          </Panel>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    );
  }
}

export default withRouter(withTranslation()(ChangePasswordScreen));
