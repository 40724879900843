import ReactGA from 'react-ga4';

const pageViewTracking = (): void => {
  const { pathname } = window.location;
  const pageView = pathname;

  ReactGA.send({
    hitType: 'pageview',
    page: pageView + window.location.search,
  });
};

export default { pageViewTracking };
