import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'rsuite';

import { constant } from '@helper';

import './styles.scss';

function NotFoundScreen(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Row style={{ paddingTop: '8%', paddingBottom: '8%' }}>
      <Col md={12} xsOffset={6}>
        <h1 className="notfound-h1">404 Not Found</h1>
        <p className="notfound-p">
          {t(constant.translation.notFound.backToHomeHeaderKey)}
        </p>
        <Button
          size="lg"
          type="button"
          appearance="primary"
          onClick={() => navigate('/')}
          className="notfound-btn-back"
        >
          {t(constant.translation.notFound.backToHomeKey)}
        </Button>
      </Col>
    </Row>
  );
}

export default NotFoundScreen;
