import Http from '@services/httpClient';
import {
  ForgotPasswordPayload,
  ChangePasswordPayload,
  LoginPayload,
  SignUpPayload,
  HiringPartnerSignUpPayload,
} from '@common/ApiTypes';
import _ from 'lodash';

const prefix = '/api/v1/session';

export default {
  login(payload: LoginPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `${prefix}/create`,
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  loginByGoogle() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/v1/auth/google' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  loginByLinkedIn() {
    return new Promise((resolve, reject) => {
      Http.get({ url: '/api/v1/auth/linkedin' })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  logout() {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `${prefix}/revoke`,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  forgotPassword(payload: ForgotPasswordPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: '/api/v1/user/profile/reset-password/send',
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  changePassword(token: string | null, payload: ChangePasswordPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `/api/v1/user/profile/reset-password/change-password/${token}`,
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  changePasswordByUser(payload: ChangePasswordPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `/api/v1/user/profile/update-password`,
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  verifyEmail(email: string, token: string) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `/api/v1/user/profile/verify/${token}`,
        data: {
          email,
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  resendEmailVerification(payload: object) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `/api/v1/user/profile/resend-verification`,
        data: {
          email: _.get(payload, 'email'),
        },
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  signUp(payload: SignUpPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `${prefix}/sign-up`,
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },

  hiringPartnerSignUp(payload: HiringPartnerSignUpPayload) {
    return new Promise((resolve, reject) => {
      Http.post({
        url: `${prefix}/sign-up/hiring-partner`,
        data: payload,
      })
        .then((res) => resolve(res.data))
        .catch((e) => reject(e));
    });
  },
};
